var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.currentTab,
                    "align-center": "",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", { attrs: { title: "选择拼团商品" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "填写基础信息" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "Modify product详情" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "180px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 0,
                      expression: "currentTab === 0",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择商品：", prop: "image" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          on: { click: _vm.changeGood },
                        },
                        [
                          _vm.formValidate.image
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: { src: _vm.formValidate.image },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 1,
                      expression: "currentTab === 1",
                    },
                  ],
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品主图：", prop: "image" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap("1")
                                    },
                                  },
                                },
                                [
                                  _vm.formValidate.image
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.formValidate.image,
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "商品轮播图：",
                                prop: "imagelist",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _vm._l(
                                    _vm.formValidate.imagelist,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "pictrue",
                                          attrs: { draggable: "true" },
                                          on: {
                                            dragstart: function ($event) {
                                              return _vm.handleDragStart(
                                                $event,
                                                item
                                              )
                                            },
                                            dragover: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleDragOver(
                                                $event,
                                                item
                                              )
                                            },
                                            dragenter: function ($event) {
                                              return _vm.handleDragEnter(
                                                $event,
                                                item
                                              )
                                            },
                                            dragend: function ($event) {
                                              return _vm.handleDragEnd(
                                                $event,
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", { attrs: { src: item } }),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "el-icon-error btndel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRemove(index)
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm.formValidate.imagelist.length < 10
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "upLoadPicBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.modalPicTap("2")
                                            },
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "拼团名称：", prop: "title" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "249",
                                  placeholder: "请输入拼团名称",
                                },
                                model: {
                                  value: _vm.formValidate.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "title", $$v)
                                  },
                                  expression: "formValidate.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "拼团简介：", prop: "info" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "250",
                                  type: "textarea",
                                  rows: 3,
                                  placeholder: "请输入拼团简介",
                                },
                                model: {
                                  value: _vm.formValidate.info,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "info", $$v)
                                  },
                                  expression: "formValidate.info",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "单位：", prop: "unitName" } },
                            [
                              _c("el-input", {
                                staticClass: "selWidthd",
                                attrs: { placeholder: "请输入单位" },
                                model: {
                                  value: _vm.formValidate.unitName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "unitName", $$v)
                                  },
                                  expression: "formValidate.unitName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动日期：", prop: "timeVal" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "mr20",
                                attrs: {
                                  type: "daterange",
                                  "value-format": "yyyy-MM-dd",
                                  format: "yyyy-MM-dd",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "picker-options": _vm.pickerOptions,
                                },
                                on: { change: _vm.onchangeTime },
                                model: {
                                  value: _vm.formValidate.timeVal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "timeVal", $$v)
                                  },
                                  expression: "formValidate.timeVal",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "设置活动开启结束时间，用户可以在设置时间内发起参与拼团"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "拼团时效(单位 小时)：",
                                prop: "effectiveTime",
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticClass: "selWidthd mr20",
                                attrs: {
                                  min: 1,
                                  step: 1,
                                  "step-strictly": "",
                                  placeholder: "请输入拼团人数",
                                },
                                model: {
                                  value: _vm.formValidate.effectiveTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "effectiveTime",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.effectiveTime",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "用户发起拼团后开始计时，需在设置时间内邀请到规定好友人数参团，超过时效时间，则系统判定拼团失败，自动发起退款"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "拼团人数：", prop: "people" } },
                            [
                              _c("el-input-number", {
                                staticClass: "selWidthd mr20",
                                attrs: {
                                  min: 2,
                                  step: 1,
                                  "step-strictly": "",
                                  placeholder: "请输入拼团人数",
                                },
                                model: {
                                  value: _vm.formValidate.people,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "people", $$v)
                                  },
                                  expression: "formValidate.people",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("单次拼团需要参与的用户数")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "购买数量限制：", prop: "num" } },
                            [
                              _c("el-input-number", {
                                staticClass: "selWidthd mr20",
                                attrs: {
                                  min: 1,
                                  step: 1,
                                  "step-strictly": "",
                                  placeholder: "请输入帮砍次数",
                                },
                                model: {
                                  value: _vm.formValidate.num,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "num", $$v)
                                  },
                                  expression: "formValidate.num",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "活动时间内每个用户参与拼团的次数限制。例如设置为4，表示本次活动有效期内，每个用户最多可参与购买4次"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "单次购买数量限制：",
                                prop: "onceNum",
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticClass: "selWidthd mr20",
                                attrs: {
                                  min: 1,
                                  max: _vm.formValidate.num,
                                  step: 1,
                                  "step-strictly": "",
                                  placeholder: "请输入购买数量限制",
                                },
                                model: {
                                  value: _vm.formValidate.onceNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "onceNum", $$v)
                                  },
                                  expression: "formValidate.onceNum",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "用户参与拼团时，一次购买最大数量限制。例如设置为2，表示参与拼团时，用户一次购买数量最大可选择2个"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "补齐人数：",
                                prop: "virtualRation",
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticClass: "selWidthd mr20",
                                attrs: {
                                  min: 0,
                                  max: _vm.formValidate.people - 1,
                                  step: 1,
                                  "step-strictly": "",
                                  placeholder: "请输入补齐人数",
                                },
                                model: {
                                  value: _vm.formValidate.virtualRation,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "virtualRation",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.virtualRation",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "当用户参与拼团后，成团时效内未成团情况下，设置补齐人数可虚拟成团。例如：成团人数为10人，补齐人数为4人，真实用户需要参与6人成团才可以在最后未成团时自动补齐虚拟人员"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "排序：", prop: "sort" } },
                            [
                              _c("el-input-number", {
                                staticClass: "selWidthd",
                                attrs: { max: 9999, placeholder: "请输入排序" },
                                model: {
                                  value: _vm.formValidate.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "sort", $$v)
                                  },
                                  expression: "formValidate.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "运费模板：", prop: "tempId" } },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidthd",
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.formValidate.tempId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "tempId",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.tempId",
                                      },
                                    },
                                    _vm._l(_vm.shippingList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动状态：", required: "" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.formValidate.isShow,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "isShow", $$v)
                                    },
                                    expression: "formValidate.isShow",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "radio",
                                      attrs: { label: false },
                                    },
                                    [_vm._v("关闭")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("开启"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "labeltop",
                              attrs: { label: "商品属性：", required: "" },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "multipleTable",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: _vm.ManyAttrValue,
                                    "tooltip-effect": "dark",
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                  },
                                },
                                [
                                  _vm.formValidate.specType
                                    ? _c("el-table-column", {
                                        key: "1",
                                        attrs: {
                                          type: "selection",
                                          width: "55",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.manyTabDate && _vm.formValidate.specType
                                    ? _vm._l(
                                        _vm.manyTabDate,
                                        function (item, iii) {
                                          return _c("el-table-column", {
                                            key: iii,
                                            attrs: {
                                              align: "center",
                                              label: _vm.manyTabTit[iii].title,
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", {
                                                        staticClass: "priceBox",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            scope.row[iii]
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "图片",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "upLoadPicBox",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.modalPicTap(
                                                      "1",
                                                      "duo",
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                scope.row.image
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pictrue tabPic",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: scope.row
                                                              .image,
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "upLoad tabPic",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "140",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm.formThead[iii].title ===
                                                "拼团价"
                                                  ? _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        size: "small",
                                                        min: 0,
                                                        precision: 2,
                                                        step: 0.1,
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    })
                                                  : _vm.formThead[iii].title ===
                                                    "限量"
                                                  ? _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        size: "small",
                                                        type: "number",
                                                        min: 1,
                                                        max: scope.row.stock,
                                                        step: 1,
                                                        "step-strictly": "",
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    })
                                                  : _c("span", {
                                                      staticClass: "priceBox",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          scope.row[iii]
                                                        ),
                                                      },
                                                    }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 2,
                      expression: "currentTab === 2",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品详情：" } },
                    [
                      _c("Tinymce", {
                        model: {
                          value: _vm.formValidate.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "content", $$v)
                          },
                          expression: "formValidate.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (!_vm.$route.params.id && _vm.currentTab > 0) ||
                            (_vm.$route.params.id && _vm.currentTab === 2),
                          expression:
                            "(!$route.params.id && currentTab > 0) || ($route.params.id && currentTab===2)",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.handleSubmitUp },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab == 0,
                          expression: "currentTab == 0",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest1("formValidate")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab == 1,
                          expression: "currentTab == 1",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest2("formValidate")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab === 2,
                          expression: "currentTab===2",
                        },
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["admin:combination:update"],
                          expression: "['admin:combination:update']",
                        },
                      ],
                      staticClass: "submission",
                      attrs: {
                        loading: _vm.loading,
                        type: "primary",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("CreatTemplates", {
        ref: "addTemplates",
        on: { getList: _vm.getShippingList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { inline: "", model: _vm.artFrom, size: "small" },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "水厂名称：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: { change: _vm.search },
                              model: {
                                value: _vm.artFrom.storeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.artFrom, "storeId", $$v)
                                },
                                expression: "artFrom.storeId",
                              },
                            },
                            _vm._l(_vm.storeSelectList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["admin:system:staff:save"],
                      expression: "['admin:system:staff:save']",
                    },
                  ],
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.add },
                },
                [_vm._v("添加核销员")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                size: "small",
                "header-cell-style": { fontWeight: "bold" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", sortable: "", width: "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "staffName",
                  label: "核销员名称",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "avatar", label: "账号", "min-width": "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号码", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "systemStore.name",
                  label: "所属水厂",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "添加时间",
                  "min-width": "180",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "operation",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var index = ref.index
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["admin:system:staff:info"],
                                expression: "['admin:system:staff:info']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(row.id)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["admin:system:staff:delete"],
                                expression: "['admin:system:staff:delete']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.storeDelete(row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticClass: "mt20",
            attrs: {
              "current-page": _vm.artFrom.page,
              "page-sizes": [20, 40, 60, 100],
              "page-size": _vm.artFrom.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.sizeChange,
              "current-change": _vm.pageChange,
            },
          }),
          _vm._v(" "),
          _c("add-clerk-list", {
            ref: "template",
            attrs: { storeSelectList: _vm.storeSelectList },
            on: { tableList: _vm.tableList },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
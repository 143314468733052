//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as deliveryApi from "@/api/delivery.js";
import shopList from "./shopList";
import * as systemMerchantApi from "@/api/merchant.js";
import edit from "./edit";
export default {
  components: {
    edit: edit,
    shopList: shopList
  },
  props: {
    storeId: {
      type: Number
    }
  },
  data: function data() {
    return {
      areaId: "",
      deliveryVisible: false,
      constants: this.$constants,
      listData: {
        list: []
      },
      listPram: {
        account: null,
        addTime: null,
        lastIp: null,
        lastTime: null,
        level: null,
        loginCount: null,
        realName: null,
        roles: null,
        isShow: null,
        phone: null,
        name: null,
        address: null,
        description: null,
        deliveryDays: null,
        image: null,
        page: 1,
        limit: this.$constants.page.limit[0]
      },
      roleList: [],
      menuList: [],
      storeData: [],
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        // 0=创建，1=编辑
        editData: {}
      }
    };
  },
  mounted: function mounted() {
    this.handleList();
    // this.storeList()
  },
  methods: {
    storeList: function storeList() {
      var _this = this;
      systemMerchantApi.merchantList(this.listPram).then(function (data) {
        _this.storeData = data.list;
      });
    },
    getWeekDay: function getWeekDay(w) {
      var obj = {
        1: "周一",
        2: "周二",
        3: "周三",
        4: "周四",
        5: "周五",
        6: "周六",
        7: "周日"
      };
      return obj[w];
    },
    // checkPermi,
    // onchangeIsShow(row) {
    //   systemMerchantApi.updateStatusApi({id: row.id, status: row.isShow})
    //     .then(async () => {
    //       this.$message.success('修改成功');
    //       this.handleList()
    //     }).catch(()=>{
    //     row.isShow = !row.isShow
    //   })
    // },
    handleSearch: function handleSearch() {
      this.listPram.page = 1;
      this.handleList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handleList();
      this.handleGetRoleList(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handleList();
      this.handleGetRoleList(this.listPram);
    },
    // handleGetRoleList() {
    //   const _pram = {
    //     page: 1,
    //     limit: this.constants.page.limit[4]
    //   }
    //   roleApi.getRoleList(_pram).then(data => {
    //     this.roleList = data
    //   })
    // },
    handlerOpenDel: function handlerOpenDel(rowData) {
      var _this2 = this;
      this.$confirm("确认删除当前数据").then(function () {
        var _pram = {
          id: rowData.id
        };
        deliveryApi.delDelivery(_pram).then(function (data) {
          _this2.$message.success("删除数据成功");
          _this2.handleList();
        });
      });
    },
    handleList: function handleList() {
      var _this3 = this;
      this.listPram.storeId = this.storeId;
      deliveryApi.deliveryList(this.listPram).then(function (data) {
        _this3.listData = data;
      });
    },
    handlerOpenEdit: function handlerOpenEdit(isCreate, editData) {
      this.areaId = editData.id;
      this.editDialogConfig.editData = editData;
      this.editDialogConfig.isCreate = isCreate;
      this.editDialogConfig.visible = true;
    },
    handlerShopList: function handlerShopList(record) {
      this.areaId = record.id;
      this.deliveryVisible = true;
    },
    hideEditDialog: function hideEditDialog() {
      this.editDialogConfig.visible = false;
      this.handleList();
    },
    resetQuery: function resetQuery() {
      this.listPram.storeName = "";
      this.handleList();
    }
  }
};
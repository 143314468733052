function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as carApi from "@/api/car.js";
import * as driverApi from "@/api/driver.js";
import { mapGetters } from "vuex";
import { Debounce } from "@/utils/validate";
import * as systemMerchantApi from "@/api/merchant.js";
import productManage from "./productManage.vue";
import { deepClone } from "@/utils";
export default {
  // name: "edit"
  components: {
    productManage: productManage
  },
  props: {
    editData: {
      type: Object,
      default: function _default() {
        return {
          rules: []
        };
      }
    }
  },
  data: function data() {
    return {
      active: 0,
      constants: this.$constants,
      visible: false,
      pram: {
        productList: []
      },
      driverList: [],
      rules: {
        carName: [{
          required: true,
          message: "vehicle name",
          trigger: ["blur", "change"]
        }],
        driverId: [{
          required: true,
          message: "Please select",
          trigger: ["blur", "change"]
        }],
        carNum: [{
          required: true,
          message: "license plate",
          trigger: ["blur", "change"]
        }],
        storeId: [{
          required: true,
          message: "plant",
          trigger: ["blur", "change"]
        }],
        takeNum: [{
          required: true,
          message: "number of bottles",
          trigger: ["blur", "change"]
        }],
        carType: [{
          required: true,
          message: "type",
          trigger: ["blur", "change"]
        }],
        number: [{
          required: true,
          message: "Please enter number",
          trigger: ["blur", "change"]
        }]
        // driverId:[
        //     { required: true, message: "Please select a driver", trigger: ["blur", "change"] }
        // ],
      },
      listData: [],
      driverData: [],
      loading: false,
      minLoad: 0,
      isEditable: true,
      title: "Loading vehicle"
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["storeId"])), {}, {
    totalBottles: function totalBottles() {
      var productList = deepClone(this.pram.productList || []);
      var total = 0;
      if (productList.length) {
        productList.map(function (item) {
          total += item.number ? item.number : 0;
        });
      }
      return total;
    }
  }),
  methods: {
    open: function open(carId) {
      var _this = this;
      this.visible = true;
      this.loading = true;
      carApi.carInfo({
        id: carId
      }).then(function (data) {
        _this.pram = Object.assign({}, data, {
          productList: data.productList ? data.productList : []
        });
      }).finally(function () {
        _this.loading = false;
      });
    },
    close: function close() {
      this.visible = false;
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "div",
        { staticClass: "page-wrapper", attrs: { bordered: false } },
        [
          _c(
            "el-tabs",
            {
              staticClass: "archive",
              staticStyle: { "background-color": "white" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "CUSTOMER SCHEDULING", name: "second" } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "40px" } } },
                    [
                      _c("div", { staticClass: "header-wrapper" }, [
                        _c("div", { staticClass: "flex-row-bet" }, [
                          _c("img", {
                            staticClass: "avatar",
                            attrs: { src: require("@/assets/imgs/user.svg") },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "username" }, [
                            _vm._v(
                              _vm._s(_vm.userInfo.firstName) +
                                " " +
                                _vm._s(_vm.userInfo.lastName)
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "90px" } },
                            [
                              _c("span", { staticClass: "form-label" }, [
                                _vm._v("Address space:"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticClass: "address_select",
                                  staticStyle: { width: "360px" },
                                  attrs: { size: "mini", clearable: false },
                                  on: { change: _vm.handleChangeAddress },
                                  model: {
                                    value: _vm.addressId,
                                    callback: function ($$v) {
                                      _vm.addressId = $$v
                                    },
                                    expression: "addressId",
                                  },
                                },
                                _vm._l(_vm.addressList, function (item, i) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: i,
                                      attrs: {
                                        value: item.id,
                                        label: item.address,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.realName) +
                                              "," +
                                              _vm._s(item.phone) +
                                              "," +
                                              _vm._s(item.address) +
                                              "\n                      "
                                          ),
                                          _c(
                                            "el-tag",
                                            {
                                              key: item.groupName,
                                              attrs: {
                                                type: "primary",
                                                effect: "dark",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(item.groupName) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.subscribeDetail.tmpProductList
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "flex",
                                staticStyle: {
                                  "flex-wrap": "wrap",
                                  "margin-left": "75px",
                                },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "period_box flex-row-bet",
                                      staticStyle: {
                                        "margin-top": "20px",
                                        "margin-right": "20px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Subscription cycle：\n                  "
                                      ),
                                      _c(
                                        "el-input",
                                        {
                                          staticStyle: { width: "205px" },
                                          attrs: {
                                            type: "number",
                                            min: _vm.inputMin,
                                          },
                                          on: { blur: _vm.checkPeriod },
                                          model: {
                                            value: _vm.subscribeDetail.period,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.subscribeDetail,
                                                "period",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "subscribeDetail.period",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "line-height": "36px",
                                              },
                                              attrs: { slot: "suffix" },
                                              slot: "suffix",
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(_vm.periodTypeText) +
                                                  "/times\n                      " +
                                                  _vm._s(
                                                    _vm._f("timeGetWeek")(
                                                      _vm.subscribeDetail
                                                        .nextOrderDate
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.isChange,
                                              expression: "isChange",
                                            },
                                          ],
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleChangeSubDetail(
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Edit")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#000000",
                                        "margin-top": "6px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { opacity: "0" } },
                                        [_vm._v("Subscription cycle：")]
                                      ),
                                      _vm._v(
                                        "\n                  You can only enter multiples of\n                  " +
                                          _vm._s(
                                            _vm.subscribeDetail.areaPeriod
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "product_list",
                                    staticStyle: { "margin-top": "20px" },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v("Subscription products:"),
                                    ]),
                                    _vm._v(" "),
                                    _vm.subscribeDetail.tmpProductList &&
                                    _vm.subscribeDetail.tmpProductList.length >
                                      0
                                      ? _c(
                                          "div",
                                          { staticClass: "order_product_list" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "flex",
                                                staticStyle: {
                                                  "min-width": "460px",
                                                  "margin-top": "10px",
                                                },
                                              },
                                              _vm._l(
                                                _vm.subscribeDetail
                                                  .tmpProductList,
                                                function (product, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticClass:
                                                        "product_item",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "flex" },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "product_img",
                                                            attrs: {
                                                              src: _vm.getImageSrc(
                                                                product.image
                                                              ),
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "product_desc",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "product_name",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        product.productName
                                                                      ) +
                                                                      "\n                          "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "product_price",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            $" +
                                                                      _vm._s(
                                                                        product.price
                                                                      ) +
                                                                      "\n                            "
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          padding:
                                                                            "0 6px",
                                                                          display:
                                                                            "inline-block",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "*"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        product.payNum
                                                                      ) +
                                                                      "\n                          "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "product_total" },
                                              [
                                                _c("div", [
                                                  _c("span", [
                                                    _vm._v("Total:"),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#1890FF",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "$" +
                                                          _vm._s(
                                                            _vm.subscribeDetail
                                                              .totalPrice
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "12px",
                                                    },
                                                    attrs: {
                                                      size: "mini",
                                                      type: "primary",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleEditSubscribe,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Modify subscription"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-left": "75px",
                                  "margin-top": "20px",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: { click: _vm.cancelSubscribe },
                                  },
                                  [_vm._v("unsubscribe")]
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticClass: "flex",
                                staticStyle: {
                                  "flex-wrap": "wrap",
                                  "margin-left": "75px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "period_box flex-row-bet",
                                    staticStyle: {
                                      "margin-top": "20px",
                                      width: "500px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Subscription cycle:--\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "period_box flex-row-bet",
                                    staticStyle: {
                                      "margin-top": "20px",
                                      width: "500px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Subscription products:"
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "rgba(0,0,0,.4)",
                                        },
                                      },
                                      [_vm._v("No subscription yet")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-left": "75px",
                                  "margin-top": "20px",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: { click: _vm.handleAddSubscribe },
                                  },
                                  [_vm._v("I want to subscribe")]
                                ),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "CUSTOMER PAYMENT", name: "third" } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "40px" } } },
                    [
                      _c("div", [
                        _c("div", { staticClass: "header-wrapper" }, [
                          _c("img", {
                            staticClass: "avatar",
                            attrs: { src: require("@/assets/imgs/user.svg") },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "username" }, [
                            _vm._v(
                              _vm._s(_vm.userInfo.firstName) +
                                " " +
                                _vm._s(_vm.userInfo.lastName)
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex",
                            staticStyle: {
                              "align-items": "center",
                              "margin-left": "75px",
                              "margin-top": "30px",
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n                Accumulated consumption:" +
                                  _vm._s(_vm.userInfo.cumulativePrice) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "margin-left": "20px" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: { click: _vm.handleRouter },
                                  },
                                  [_vm._v("Check records>")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "margin-left": "128px" } },
                              [
                                _vm._v(
                                  "\n                Credits:" +
                                    _vm._s(_vm.userInfo.lineCredit) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "flex",
                                staticStyle: {
                                  "margin-left": "128px",
                                  "align-items": "center",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                Balance:" +
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        _vm.userInfo.billBalance
                                      )
                                    ) +
                                    "\n                "
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-left": "20px" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: { click: _vm.handleOpenBanlance },
                                      },
                                      [_vm._v("Details>")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "ORDER HISTORY", name: "fourth" } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "40px" } } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex",
                          staticStyle: { "align-items": "center" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "header-wrapper",
                              staticStyle: { "margin-right": "100px" },
                            },
                            [
                              _c("img", {
                                staticClass: "avatar",
                                attrs: {
                                  src: require("@/assets/imgs/user.svg"),
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "username" }, [
                                _vm._v(
                                  _vm._s(_vm.userInfo.firstName) +
                                    " " +
                                    _vm._s(_vm.userInfo.lastName)
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("span", { staticClass: "form-label" }, [
                                _vm._v("Address space:"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticClass: "address_select",
                                  staticStyle: { width: "360px" },
                                  attrs: { size: "mini", clearable: false },
                                  on: { change: _vm.handleChangeAddress },
                                  model: {
                                    value: _vm.addressId,
                                    callback: function ($$v) {
                                      _vm.addressId = $$v
                                    },
                                    expression: "addressId",
                                  },
                                },
                                _vm._l(_vm.addressList, function (item, i) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: i,
                                      attrs: {
                                        value: item.id,
                                        label: item.address,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(item.realName) +
                                              "," +
                                              _vm._s(item.phone) +
                                              "," +
                                              _vm._s(item.address) +
                                              "\n                    "
                                          ),
                                          _c(
                                            "el-tag",
                                            {
                                              key: item.groupName,
                                              attrs: {
                                                type: "primary",
                                                effect: "dark",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(item.groupName) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form-item",
                              staticStyle: { "margin-left": "100px" },
                            },
                            [
                              _c("div", { staticClass: "form-label" }, [
                                _vm._v("Total number of orders:"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-content" }, [
                                _vm._v(_vm._s(_vm.orderData.total) + "items"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "PERSONAL INFORMATION", name: "first" } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "main",
                      attrs: { "body-style": { padding: "0" } },
                    },
                    [
                      _c("div", { staticClass: "header-wrapper" }, [
                        _c("img", {
                          staticClass: "avatar",
                          attrs: { src: require("@/assets/imgs/user.svg") },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "username" }, [
                          _vm._v(
                            _vm._s(_vm.userInfo.firstName) +
                              " " +
                              _vm._s(_vm.userInfo.lastName)
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { padding: "0 0 0 75px" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex",
                            staticStyle: {
                              "margin-top": "40px",
                              "flex-wrap": "wrap",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "half-item",
                                staticStyle: {
                                  width: "570px",
                                  "margin-right": "100px",
                                },
                              },
                              [
                                _c("div", { staticClass: "form-item" }, [
                                  _c("span", { staticClass: "form-label" }, [
                                    _vm._v("User email:"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "form-content grid" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("filterEmpty")(
                                            _vm.userInfo.account
                                          )
                                        ) + "\n                    "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#1890ff",
                                            cursor: "pointer",
                                          },
                                          on: { click: _vm.handleOpenEmail },
                                        },
                                        [_vm._v("Email management>")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "form-item" },
                                  [
                                    _c("span", { staticClass: "form-label" }, [
                                      _vm._v("First Name:"),
                                    ]),
                                    _vm._v(" "),
                                    _c("editable-cell", {
                                      attrs: { text: _vm.userInfo.firstName },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleCellChange(
                                            _vm.userInfo,
                                            "firstName",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "form-item" },
                                  [
                                    _c("span", { staticClass: "form-label" }, [
                                      _vm._v("Last Name:"),
                                    ]),
                                    _vm._v(" "),
                                    _c("editable-cell", {
                                      attrs: { text: _vm.userInfo.lastName },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleCellChange(
                                            _vm.userInfo,
                                            "lastName",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "form-item" },
                                  [
                                    _c("span", { staticClass: "form-label" }, [
                                      _vm._v("Phone:"),
                                    ]),
                                    _vm._v(" "),
                                    _c("editable-cell", {
                                      attrs: { text: _vm.userInfo.phone },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleCellChange(
                                            _vm.userInfo,
                                            "phone",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-item" }, [
                                  _c("span", { staticClass: "form-label" }, [
                                    _vm._v("Type:"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { margin: "8px 0" } },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          staticClass: "subType_box",
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleCellChange(
                                                _vm.orginUserInfo,
                                                "isTradition",
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.userInfo.isTradition,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.userInfo,
                                                "isTradition",
                                                $$v
                                              )
                                            },
                                            expression: "userInfo.isTradition",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: false } },
                                            [_vm._v("card")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: true } },
                                            [_vm._v("invoice")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-item",
                                    staticStyle: {
                                      "align-items": "flex-start",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "form-label" }, [
                                      _vm._v("Address:"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "form-content grid" },
                                      [
                                        _vm.address.id
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-top": "8px",
                                                  "margin-bottom": "8px",
                                                  "line-height": "17px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.address.realName
                                                    ) +
                                                    "," +
                                                    _vm._s(_vm.address.phone) +
                                                    "," +
                                                    _vm._s(
                                                      _vm._f("filterEmpty")(
                                                        _vm.address.address
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          : _c("div", [_vm._v("-")]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#1890ff",
                                              cursor: "pointer",
                                            },
                                            on: {
                                              click: _vm.handleOpenAddress,
                                            },
                                          },
                                          [_vm._v("Address management>")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "half-item",
                                staticStyle: { width: "570px" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-item" },
                                  [
                                    _c("span", { staticClass: "form-label" }, [
                                      _vm._v("Credits:"),
                                    ]),
                                    _vm._v(" "),
                                    _c("editable-cell", {
                                      attrs: { text: _vm.userInfo.lineCredit },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleCellChange(
                                            _vm.userInfo,
                                            "lineCredit",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-item" }, [
                                  _c("span", { staticClass: "form-label" }, [
                                    _vm._v("Accumulated consumption:"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "form-content grid" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("filterEmpty")(
                                            _vm.userInfo.cumulativePrice
                                          )
                                        ) + "\n                    "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#1890ff",
                                            cursor: "pointer",
                                          },
                                          on: { click: _vm.handleTabClick },
                                        },
                                        [_vm._v("Invoice records>")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-item" }, [
                                  _c("span", { staticClass: "form-label" }, [
                                    _vm._v("Balance:"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "form-content grid" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("filterEmpty")(
                                            _vm.userInfo.billBalance
                                          )
                                        ) + "\n                    "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#1890ff",
                                            cursor: "pointer",
                                          },
                                          on: { click: _vm.handleOpenBanlance },
                                        },
                                        [_vm._v("Details>")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "form-item" },
                                  [
                                    _c("span", { staticClass: "form-label" }, [
                                      _vm._v("repayment period:"),
                                    ]),
                                    _vm._v(" "),
                                    _c("editable-cell", {
                                      attrs: {
                                        text: _vm.userInfo.repaymentPeriod,
                                        unit: " Day",
                                        inputType: "number",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleCellChange(
                                            _vm.userInfo,
                                            "repaymentPeriod",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "form-item" },
                                  [
                                    _c("span", { staticClass: "form-label" }, [
                                      _vm._v("Sale:"),
                                    ]),
                                    _vm._v(" "),
                                    _c("editable-cell", {
                                      attrs: { text: _vm.userInfo.referrer },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleCellChange(
                                            _vm.userInfo,
                                            "referrer",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-item" }, [
                                  _c("span", { staticClass: "form-label" }, [
                                    _vm._v("Subscription cycle:"),
                                  ]),
                                  _vm._v(" "),
                                  _vm.subscribeDetail.tmpProductList
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "form-content grid",
                                          staticStyle: {
                                            "grid-template-columns": "100%",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.subscribeDetail.period
                                                ) +
                                                _vm._s(
                                                  _vm._f("subType")(
                                                    _vm.subscribeDetail.subType
                                                  )
                                                ) +
                                                "/times\n                      " +
                                                _vm._s(
                                                  _vm._f("timeGetWeek")(
                                                    _vm.subscribeDetail
                                                      .nextOrderDate
                                                  )
                                                ) +
                                                "\n                      "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "time-label" },
                                              [
                                                _vm._v(
                                                  "\n                        Subscription time:" +
                                                    _vm._s(
                                                      _vm._f("time")(
                                                        _vm.subscribeDetail
                                                          .nextOrderDate
                                                      )
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "form-content grid" },
                                        [
                                          _vm._v(
                                            "\n                    --\n                  "
                                          ),
                                        ]
                                      ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "NOTICE", name: "five" } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "40px" } } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex",
                          staticStyle: { "align-items": "center" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "header-wrapper",
                              staticStyle: { "margin-right": "100px" },
                            },
                            [
                              _c("img", {
                                staticClass: "avatar",
                                attrs: {
                                  src: require("@/assets/imgs/user.svg"),
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "username" }, [
                                _vm._v(
                                  _vm._s(_vm.userInfo.firstName) +
                                    " " +
                                    _vm._s(_vm.userInfo.lastName)
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form-item",
                              staticStyle: { "margin-left": "100px" },
                            },
                            [
                              _c("div", { staticClass: "form-label" }, [
                                _vm._v("Number of messages:"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-content" }, [
                                _vm._v(_vm._s(_vm.messageTotal)),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.activeName === "first"
            ? _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-card",
                    {
                      attrs: { "body-style": { padding: "0 75px 24px 115px" } },
                    },
                    [
                      _c("div", { staticClass: "flex wrap" }, [
                        _c("div", { staticStyle: { "margin-right": "40px" } }, [
                          _c("div", { staticClass: "remark_text" }, [
                            _vm._v("User Comments:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "user_remark",
                              style: _vm.userInfo.isSystem
                                ? "background: rgba(238, 80, 47, 0.4)"
                                : "",
                            },
                            [
                              _c("editable-cell", {
                                attrs: {
                                  text: _vm.userInfo.mark,
                                  type: "textarea",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleCellChange(
                                      _vm.userInfo,
                                      "mark",
                                      $event,
                                      _vm.address
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  staticStyle: { display: "inline-flex" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap(
                                        "2",
                                        "sliderUserImage"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.sliderUserImage.length
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.sliderUserImage,
                                          function (item, imageIndex) {
                                            return _c(
                                              "div",
                                              {
                                                key: imageIndex,
                                                staticClass: "pictrue",
                                              },
                                              [
                                                _c("img", {
                                                  attrs: { src: item },
                                                }),
                                                _vm._v(" "),
                                                _c("i", {
                                                  staticClass: "el-icon-error",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.handleDeleteUserImg(
                                                        imageIndex
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "remark_text",
                              staticStyle: { "margin-top": "40px" },
                            },
                            [
                              _vm._v(
                                "\n              Address description:\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "user_remark sys" },
                            [
                              _c("editable-cell", {
                                attrs: {
                                  text: _vm.userInfo.systemRemark,
                                  type: "textarea",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleCellChange(
                                      _vm.userInfo,
                                      "systemRemark",
                                      $event,
                                      _vm.address
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  staticStyle: { display: "inline-flex" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap("2", "sliderImage")
                                    },
                                  },
                                },
                                [
                                  _vm.sliderImage.length
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.sliderImage,
                                          function (item, imageIndex) {
                                            return _c(
                                              "div",
                                              {
                                                key: imageIndex,
                                                staticClass: "pictrue",
                                              },
                                              [
                                                _c("img", {
                                                  attrs: { src: item },
                                                }),
                                                _vm._v(" "),
                                                _c("i", {
                                                  staticClass: "el-icon-error",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.handleDeleteImg(
                                                        imageIndex
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sys_remark" }, [
                          _c(
                            "div",
                            {
                              staticClass: "remark_text flex",
                              staticStyle: {
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c("div", [_vm._v("System notes:")]),
                              _vm._v(" "),
                              _vm.checkRemarkList.length
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { color: "rgba(0,0,0,.6)" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Selected"),
                                        _c("a", [
                                          _vm._v(
                                            _vm._s(_vm.checkRemarkList.length)
                                          ),
                                        ]),
                                        _vm._v("items\n                "),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-link",
                                        {
                                          staticStyle: {
                                            "margin-left": "24px",
                                          },
                                          attrs: {
                                            type: "primary",
                                            underline: false,
                                          },
                                          on: { click: _vm.handleClearRemark },
                                        },
                                        [_vm._v("Clear")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-link",
                                        {
                                          staticStyle: {
                                            "margin-left": "12px",
                                          },
                                          attrs: {
                                            type: "primary",
                                            underline: false,
                                          },
                                          on: { click: _vm.handleDeleteRemark },
                                        },
                                        [_vm._v("Delete")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "sys_list" }, [
                            _c(
                              "ul",
                              {
                                directives: [
                                  {
                                    name: "infinite-scroll",
                                    rawName: "v-infinite-scroll",
                                    value: _vm.loadMore,
                                    expression: "loadMore",
                                  },
                                ],
                                staticClass: "-list ",
                                staticStyle: {
                                  overflow: "auto",
                                  height: "480px",
                                  padding: "20px",
                                },
                                attrs: {
                                  "infinite-scroll-disabled": _vm.disabled,
                                  "infinite-scroll-distance": 20,
                                },
                              },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    model: {
                                      value: _vm.checkRemarkList,
                                      callback: function ($$v) {
                                        _vm.checkRemarkList = $$v
                                      },
                                      expression: "checkRemarkList",
                                    },
                                  },
                                  _vm._l(
                                    _vm.userInfo.userRemarkList,
                                    function (itm, itmIndex) {
                                      return _c(
                                        "li",
                                        {
                                          key: itmIndex,
                                          staticClass:
                                            "infinite-list-item sys_list_item",
                                        },
                                        [
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: itm.id } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "time" },
                                                [_vm._v(_vm._s(itm.createTime))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "text" },
                                                [_vm._v(_vm._s(itm.content))]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "send_box" },
                            [
                              _c("el-input", {
                                staticClass: "textarea-input",
                                attrs: {
                                  type: "textarea",
                                  placeholder: "Please enter",
                                  autosize: { minRows: 3 },
                                },
                                model: {
                                  value: _vm.text,
                                  callback: function ($$v) {
                                    _vm.text = $$v
                                  },
                                  expression: "text",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleSend },
                                },
                                [_vm._v("Send")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeName === "second"
            ? _c("div", [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.subscribeData.loading,
                        expression: "subscribeData.loading",
                      },
                    ],
                  },
                  [
                    _vm.subscribeDetail.tmpList &&
                    _vm.subscribeDetail.tmpList.length
                      ? _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c("orderList", {
                              attrs: {
                                subscribeDetail: _vm.subscribeDetail,
                                address: _vm.address,
                              },
                              on: {
                                ok: _vm.getUserSubList,
                                edit: _vm.handleEditGoods,
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "calc(100% - 512px)",
                                  "margin-left": "12px",
                                },
                              },
                              [
                                _c("el-card", [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "682px" } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          key: "subscribe",
                                          ref: "multipleOrderTable",
                                          staticStyle: {
                                            "min-height": "200px",
                                          },
                                          attrs: {
                                            data: _vm.subscribeData.data,
                                            "row-key": "id",
                                            size: "mini",
                                            border: "",
                                            "header-cell-style": {
                                              background: "#F5F5F5",
                                              color: "#515a6e",
                                              height: "40px",
                                            },
                                          },
                                          on: {
                                            "selection-change":
                                              _vm.handleOrderSelectionChange,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              type: "selection",
                                              width: "55",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Sort",
                                              prop: "orderId",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var row = ref.row
                                                    var $index = ref.$index
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "flex",
                                                          staticStyle: {
                                                            "justify-content":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          row.isNew
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "parallelogram",
                                                                },
                                                                [_vm._v("New")]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n                        Order " +
                                                              _vm._s(
                                                                $index + 1
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1392468712
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "subscription time",
                                              prop: "nextOrderDate",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm._f("time")(
                                                              row.nextOrderDate
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2171623502
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "operation",
                                              fixed: "right",
                                              width: "220px",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var row = ref.row
                                                    var $index = ref.$index
                                                    return [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticClass:
                                                            "primary-border",
                                                          attrs: {
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleDelete(
                                                                row.id,
                                                                $index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Delete")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "primary",
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleDateEdit(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Reschedule")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3373550752
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row-bet table-bottom",
                                          staticStyle: {
                                            "justify-content": "space-between",
                                            border: "1px solid #dfe6ec",
                                            "border-top": "none",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm.multipleOrderSelection.length
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                      Selected"
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#1890ff",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .multipleOrderSelection
                                                            .length
                                                        ) + "个"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "items\n                    "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm
                                                          .multipleOrderSelection
                                                          .length,
                                                      expression:
                                                        "multipleOrderSelection.length",
                                                    },
                                                  ],
                                                  staticClass: "primary-border",
                                                  on: {
                                                    click:
                                                      _vm.clearOrderSelection,
                                                  },
                                                },
                                                [_vm._v("Cancel")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm
                                                          .multipleOrderSelection
                                                          .length,
                                                      expression:
                                                        "multipleOrderSelection.length",
                                                    },
                                                  ],
                                                  staticClass: "primary-border",
                                                  on: {
                                                    click:
                                                      _vm.handleBatchDelete,
                                                  },
                                                },
                                                [_vm._v("Batch deletion")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleOrderEdit()
                                                    },
                                                  },
                                                },
                                                [_vm._v("Add new order")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "empty-data" }, [
                          _vm._v("\n          No subscription yet\n        "),
                        ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.activeName === "third"
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "title space-between" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-row-bet" },
                      [
                        _vm.multipleSelection.length
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: { click: _vm.clearSelection },
                              },
                              [_vm._v("Deselect all")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "60px" } },
                          [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Bill type:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "Please choose",
                                  clearable: "",
                                },
                                on: { change: _vm.getUserInvoice },
                                model: {
                                  value: _vm.invoiceQueryParams.billType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoiceQueryParams,
                                      "billType",
                                      $$v
                                    )
                                  },
                                  expression: "invoiceQueryParams.billType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { value: 0, label: "Invoice" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { value: 1, label: "Cheque" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { value: "", label: "All" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-card", [
                    _c(
                      "div",
                      {
                        staticStyle: { border: "1px solid rgb(223, 230, 236)" },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.invoiceData.loading,
                                expression: "invoiceData.loading",
                              },
                            ],
                            key: "invoice",
                            ref: "multipleTable",
                            staticStyle: { "min-height": "200px" },
                            attrs: {
                              data: _vm.invoiceData.data,
                              size: "mini",
                              "header-cell-style": {
                                background: "#F5F5F5",
                                color: "#515a6e",
                                height: "40px",
                              },
                              "header-cell-class-name": _vm.cellClass,
                              "row-key": "id",
                              "row-class-name": _vm.tableRowClassName,
                            },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "55",
                                selectable: _vm.selectable,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "Invoice date",
                                prop: "createTime",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("time")(row.createTime)
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1238260546
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "Invoice number",
                                prop: "invoiceNo",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "unpaid", prop: "unpaid" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              row.unpaid
                                                ? row.unpaid
                                                : row.totalPrice
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3125086965
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "Invoice amount",
                                prop: "totalPrice",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "operation", width: "220px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        row.status == 0
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "primary-border",
                                                attrs: { size: "mini" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDetail(row)
                                                  },
                                                },
                                              },
                                              [_vm._v("Invoice details")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        row.status == 1
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "primary-border",
                                                attrs: { size: "mini" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDetail(row)
                                                  },
                                                },
                                              },
                                              [_vm._v("Details")]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2365075302
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "block" },
                          [
                            _c("el-pagination", {
                              attrs: {
                                "page-sizes": [10, 20, 40],
                                "page-size": _vm.pagination.limit,
                                "current-page": _vm.pagination.page,
                                layout: "total, prev, pager, next, jumper",
                                total: _vm.invoiceData.total,
                              },
                              on: {
                                "size-change": _vm.handleSizeChange,
                                "current-change": _vm.handlePageChange,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex space-between table-bottom",
                            staticStyle: {
                              padding: "0 20px 0 22px",
                              "border-top": "1px solid #dfe6ec",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex",
                                staticStyle: { "align-items": "center" },
                              },
                              [
                                _vm._v("\n              Total:"),
                                _c("span", { staticClass: "total" }, [
                                  _vm._v("$" + _vm._s(_vm.total)),
                                ]),
                                _vm._v("， Selected"),
                                _c(
                                  "span",
                                  { staticStyle: { color: "#1890ff" } },
                                  [_vm._v(_vm._s(_vm.multipleSelection.length))]
                                ),
                                _vm._v("items\n            "),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "primary-border",
                                    on: { click: _vm.handleBatchSend },
                                  },
                                  [_vm._v("Resend")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.handleBatchPay },
                                  },
                                  [_vm._v("Confirm payment")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeName === "fourth"
            ? _c(
                "div",
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "20px" } } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticClass: "table-head-wrapper",
                              staticStyle: { padding: "10px 0 20px" },
                            },
                            [
                              _c(
                                "el-form",
                                { attrs: { size: "small", inline: true } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "order #",
                                        "label-width": "66px",
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.handleSearch($event)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticStyle: { width: "220px" },
                                          attrs: {
                                            placeholder: "order #",
                                            size: "small",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.queryParams.orderNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryParams,
                                                "orderNo",
                                                $$v
                                              )
                                            },
                                            expression: "queryParams.orderNo",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              icon: "el-icon-search",
                                              size: "small",
                                            },
                                            on: { click: _vm.handleSearch },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Date",
                                        "label-width": "154px",
                                      },
                                    },
                                    [
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "220px" },
                                          attrs: {
                                            "value-format": "yyyy-MM-dd",
                                            format: "yyyy-MM-dd",
                                            type: "date",
                                            placeholder: "Please choose",
                                          },
                                          on: { change: _vm.handleDateChange },
                                          model: {
                                            value: _vm.timeVal,
                                            callback: function ($$v) {
                                              _vm.timeVal = $$v
                                            },
                                            expression: "timeVal",
                                          },
                                        }),
                                      ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.orderData.loading,
                                  expression: "orderData.loading",
                                },
                              ],
                              key: "order",
                              attrs: {
                                data: _vm.orderData.data,
                                size: "mini",
                                "row-key": "id",
                                "header-cell-style": {
                                  background: "#F5F5F5",
                                  color: "#515a6e",
                                  height: "40px",
                                },
                                "row-class-name": _vm.tableRowClassName,
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "Order number",
                                  prop: "orderId",
                                  width: "260px",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c(
                                            "el-link",
                                            {
                                              attrs: {
                                                underline: false,
                                                type: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onDetails(
                                                    row.orderId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(row.orderId))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1309742493
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Order date",
                                  prop: "createTime",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("time")(row.createTime)
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1238260546
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Delivery date",
                                  prop: "deliveryDate",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("time")(row.deliveryDate)
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1755906715
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "Delivery driver" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("filterEmpty")(
                                                  row.deliveryName
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2064233224
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "Delivery car" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(row.carNum) +
                                              "\n              "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3581121081
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Return bottles(fee)",
                                  prop: "freightRefundPrice",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("filterEmpty")(
                                                  row.freightRefundPrice
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1397057459
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "Amount", prop: "payPrice" },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "operation", width: "120px" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "primary-border",
                                              attrs: { size: "mini" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onDetails(
                                                    row.orderId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Details")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2939975921
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "pagination" },
                            [
                              _c("el-pagination", {
                                attrs: {
                                  "page-sizes": [10, 20, 40],
                                  "page-size": _vm.pagination.limit,
                                  "current-page": _vm.pagination.page,
                                  layout: "total, prev, pager, next, jumper",
                                  total: _vm.orderData.total,
                                },
                                on: {
                                  "size-change": _vm.handleSizeChange,
                                  "current-change": _vm.handlePageChange,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeName === "five"
            ? _c(
                "div",
                [
                  _c("message-list", {
                    attrs: { uid: _vm.uid },
                    on: {
                      updateTotal: function ($event) {
                        _vm.messageTotal = $event
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("dateEdit", { ref: "dateEdit", on: { ok: _vm.getUserSubList } }),
      _vm._v(" "),
      _c("addressData", {
        ref: "addressList",
        on: { ok: _vm.handleAddressEditOk },
      }),
      _vm._v(" "),
      _c("orderEdit", { ref: "orderEdit", on: { ok: _vm.getUserSubList } }),
      _vm._v(" "),
      _c("order-details", {
        ref: "orderDetail",
        attrs: { orderId: _vm.orderId },
        on: { update: _vm.getUserOrderList },
      }),
      _vm._v(" "),
      _c("invoice-detail", { ref: "invoiceModal" }),
      _vm._v(" "),
      _c("pay-dialog", {
        ref: "payDialog",
        attrs: { uid: _vm.uid, balancePrice: _vm.userInfo.billBalance },
        on: { ok: _vm.invoicePayOk },
      }),
      _vm._v(" "),
      _c("subscribe-edit", {
        ref: "subscribeEdit",
        attrs: { uid: _vm.uid },
        on: { ok: _vm.getUserSubList },
      }),
      _vm._v(" "),
      _c("check-detail", { ref: "checkDetail", on: { ok: _vm.invoicePayOk } }),
      _vm._v(" "),
      _c("historyInvoice", {
        ref: "historyInvoice",
        attrs: { uid: _vm.uid },
        on: { ok: _vm.invoicePayOk },
      }),
      _vm._v(" "),
      _c("balance-record", { ref: "balanceRecord", attrs: { uid: _vm.uid } }),
      _vm._v(" "),
      _c("email-manage", {
        ref: "emailManage",
        attrs: { userInfo: _vm.userInfo },
        on: { ok: _vm.getUserDetail },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "line" }),
      _vm._v(" "),
      _c("div", { staticClass: "title_text" }, [_vm._v("Subscription order")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "line" }),
      _vm._v(" "),
      _c("div", { staticClass: "title_text" }, [_vm._v("Billing records")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "line" }),
      _vm._v(" "),
      _c("div", { staticClass: "title_text" }, [_vm._v("Order records")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var orderApi = _interopRequireWildcard(require("@/api/order.js"));
var _utils = require("@/utils");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "delivery",
  props: {},
  data: function data() {
    return {
      visible: false,
      loading: false,
      form: {},
      rules: {
        driverId: [{
          required: true,
          message: "Please choose",
          trigger: ["blur", "change"]
        }]
      },
      col: 12,
      columns: [{
        label: "Billing Email",
        prop: "billingEmail",
        minWidth: "150px"
      }, {
        label: "Contact",
        prop: "contact",
        minWidth: "150px"
      }, {
        label: "Customer Name",
        prop: "customerName",
        minWidth: "150px"
      }, {
        label: "BillAddressCityStateZip",
        prop: "billAddressCityStateZip",
        minWidth: "200px"
      }, {
        label: "BillAddress 2",
        prop: "billAddress2",
        minWidth: "150px"
      }, {
        label: "BillAddress 3",
        prop: "billAddress3",
        minWidth: "150px"
      }, {
        label: "DispenserCount",
        prop: "dispenserCount",
        minWidth: "150px"
      }, {
        label: "Error Remark",
        prop: "errorRemark",
        minWidth: "150px"
      }, {
        label: "FuelSurcharge",
        prop: "fuelSurcharge",
        minWidth: "150px"
      }, {
        label: "Item",
        prop: "item",
        minWidth: "150px"
      }, {
        label: "Rate",
        prop: "rate",
        minWidth: "150px"
      }, {
        label: "Item 2",
        prop: "item2",
        minWidth: "150px"
      }, {
        label: "Rate 2",
        prop: "rate2",
        minWidth: "150px"
      }, {
        label: "Item 3",
        prop: "item3",
        minWidth: "150px"
      }, {
        label: "Rate 3",
        prop: "rate3",
        minWidth: "150px"
      }, {
        label: "Phone",
        prop: "phone",
        minWidth: "150px"
      }, {
        label: "Recurring Description",
        prop: "recurringDescription",
        minWidth: "150px"
      }, {
        label: "ScheduleDate",
        prop: "scheduleDate",
        minWidth: "150px"
      }, {
        label: "SpecialInstruction",
        prop: "specialInstruction",
        minWidth: "150px",
        type: "textarea",
        col: 24
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    open: function open(item) {
      this.form = (0, _utils.deepClone)(item);
      this.visible = true;
    },
    // 提交
    handlerSubmit: function handlerSubmit(name) {
      var _this = this;
      this.$refs[name].validate(function (valid) {
        if (!valid) return;
        _this.loading = true;
        var data = _this.form;
        orderApi.orderErrorUpdate(data).then(function (res) {
          _this.$message.success("Update success");
          _this.visible = false;
          _this.$refs[name].resetFields();
          _this.$emit("ok");
          _this.loading = false;
        }).catch(function () {
          _this.loading = false;
        });
      });
    },
    handleClose: function handleClose() {
      this.handleCancel("form");
    },
    handleCancel: function handleCancel(name) {
      this.visible = false;
      this.$refs[name].resetFields();
    }
  }
};
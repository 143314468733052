function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import orderDetail from "@/views/order/orderDetail.vue";
import dateEdit from "./dateEdit.vue";
import * as subscribeApi from "@/api/subscribe.js";
export default {
  components: {
    orderDetail: orderDetail,
    dateEdit: dateEdit
  },
  props: {
    subscribeDetail: {
      type: Object,
      default: function _default() {}
    },
    address: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      orderId: 0,
      orderInfo: {},
      //最近一单
      lastOrderInfo: {} //上一单
    };
  },
  watch: {
    subscribeDetail: {
      immediate: true,
      handler: function handler(val) {
        if (val.tmpList && val.tmpList.length) {
          var orderInfo = val.tmpList[0];
          if (!orderInfo.productInfo) {
            orderInfo.productInfo = val.tmpProductList;
          } else {
            orderInfo.productInfo = typeof orderInfo.productInfo === "string" ? JSON.parse(orderInfo.productInfo) : orderInfo.productInfo;
          }
          this.orderInfo = Object.assign({}, _objectSpread(_objectSpread({}, orderInfo), {}, {
            isFirst: orderInfo.isNew ? false : true
          }));
        }
        if (val.lastOrderInfo) {
          var lastOrderInfo = val.lastOrderInfo;
          if (!lastOrderInfo.productInfo) {
            lastOrderInfo.productInfo = val.tmpProductList;
          } else {
            lastOrderInfo.productInfo = typeof lastOrderInfo.productInfo === "string" ? JSON.parse(lastOrderInfo.productInfo) : lastOrderInfo.productInfo;
          }
          this.lastOrderInfo = lastOrderInfo;
        }
      }
    }
  },
  methods: {
    getImageSrc: function getImageSrc(avatar) {
      var subStr = "http";
      if (avatar && avatar.startsWith(subStr)) {
        return avatar;
      } else {
        return "https://img.h2owaterco.com/" + avatar;
      }
    },
    handleDateEdit: function handleDateEdit(row) {
      this.$refs.dateEdit.open(row);
    },
    onDetails: function onDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.getDetail(id);
    },
    handleDelete: function handleDelete(tmpId, idx) {
      var that = this;
      this.$confirm("Are you sure to delete it?", "Tips", {
        confirmButtonText: "delete",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        subscribeApi.deleteChildTmp({
          id: tmpId
        }).then(function () {
          that.$message.success("Delete successfully");
          that.handleOk();
        });
      });
    },
    handleGoodsEdit: function handleGoodsEdit(info) {
      this.$emit("edit", info);
    },
    handleOk: function handleOk() {
      this.$emit("ok");
    }
  }
};
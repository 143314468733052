var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        visible: _vm.visible,
        title: "Address management",
        "before-close": _vm.handleClose,
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "order_box",
        },
        [
          _vm.addressList.length
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "address_title" }, [
                    _vm._v("Current address"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "address_item flex-row-bet primary" },
                    [
                      _c("div", [
                        _c("div", { staticClass: "address_user" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.address.realName) +
                              "," +
                              _vm._s(_vm.address.phone) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "address_address" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.address.address) +
                              "\n            "
                          ),
                          _c("span", { staticClass: "group_name" }, [
                            _vm._v(_vm._s(_vm.address.groupName)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "is_default" }, [
                            _vm._v("default address"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.handleEditAddress(_vm.address)
                            },
                          },
                        },
                        [_vm._v("Edit")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "address_title" }, [
                    _vm._v("Other address"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.addressList, function (address) {
                    return _c(
                      "div",
                      {
                        key: address.id,
                        staticClass: "address_item flex-row-bet",
                      },
                      [
                        _c("div", [
                          _c("div", { staticClass: "address_user" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(address.realName) +
                                "," +
                                _vm._s(address.phone) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "address_address" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(address.address) +
                                "\n            "
                            ),
                            _c("span", { staticClass: "group_name" }, [
                              _vm._v(_vm._s(address.groupName)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#1890FF",
                                "line-height": "18px",
                                "margin-top": "6px",
                              },
                            },
                            [
                              _c("el-radio", {
                                on: {
                                  change: function ($event) {
                                    return _vm.setDefaultAddress(address.id)
                                  },
                                },
                                model: {
                                  value: address.isDefault,
                                  callback: function ($$v) {
                                    _vm.$set(address, "isDefault", $$v)
                                  },
                                  expression: "address.isDefault",
                                },
                              }),
                              _vm._v(
                                "\n            set as the default address\n          "
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDeleteAddress(address.id)
                                  },
                                },
                              },
                              [_vm._v("Delete")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEditAddress(address)
                                  },
                                },
                              },
                              [_vm._v("Edit")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                "line-height": "100px",
                "text-align": "center",
                "font-weight": "600",
              },
            },
            [_vm._v("No address yet")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleAddAddress()
                },
              },
            },
            [_vm._v("Add address")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("editAddress", {
        ref: "editAddress",
        attrs: { uid: _vm.uid },
        on: { update: _vm.handleOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'GoodList',
  props: {
    handleNum: {
      type: String,
      default: ''
    },
    checked: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      templateRadio: 0,
      merCateList: [],
      props: {
        children: 'child',
        label: 'name',
        value: 'id',
        emitPath: false
      },
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 10,
        cateId: '',
        keywords: '',
        type: '1'
      },
      imgList: [],
      multipleSelection: [],
      checkedPage: [],
      isChecked: false,
      isIndex: 0,
      checkBox: [],
      checkedIds: [] // 订单当前页选中的数据
    };
  },
  mounted: function mounted() {
    this.getList();
    if (this.checked.length) {
      var _this$checked = _toArray(this.checked),
        arr2 = _this$checked.slice(0);
      this.checkBox = arr2;
      this.checkedIds = arr2.map(function (item) {
        return item.id;
      });
    }
    this.getCategorySelect();
  },
  methods: {
    onInput: function onInput(e) {
      this.$forceUpdate();
    },
    changeType: function changeType(v) {
      this.isChecked = v;
      var index = this.checkedPage.indexOf(this.tableFrom.page);
      this.isIndex = index;
      this.checkedPage.push(this.tableFrom.page);
      if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId(v);
    },
    changeOne: function changeOne(v, order) {
      if (v) {
        var index = this.checkedIds.indexOf(order.id);
        if (index === -1) {
          this.checkedIds.push(order.id);
          this.checkBox.push(order);
        }
      } else {
        var _index = this.checkedIds.indexOf(order.id);
        if (_index > -1) {
          this.checkedIds.splice(_index, 1);
          this.checkBox.splice(_index, 1);
        }
      }
    },
    syncCheckedId: function syncCheckedId(o) {
      var _this = this;
      var ids = this.tableData.data.map(function (v) {
        return v.id;
      });
      if (o) {
        this.tableData.data.forEach(function (item) {
          var index = _this.checkedIds.indexOf(item.id);
          if (index === -1) {
            _this.checkedIds.push(item.id);
            _this.checkBox.push(item);
          }
        });
      } else {
        this.tableData.data.forEach(function (item) {
          var index = _this.checkedIds.indexOf(item.id);
          if (index > -1) {
            _this.checkedIds.splice(index, 1);
            _this.checkBox.splice(index, 1);
          }
        });
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var tables = [];
      val.map(function (item) {
        tables.push({
          src: item.image,
          id: item.id
        });
      });
      this.multipleSelection = tables;
    },
    ok: function ok() {
      this.$emit('getStoreItem', this.checkBox);
    },
    getTemplateRow: function getTemplateRow(row) {
      this.$emit('getStoreItem', row);
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this2 = this;
      (0, _store.categoryApi)({
        status: -1,
        type: 1
      }).then(function (res) {
        _this2.merCateList = res;
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _store.productLstApi)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.tableData.data.map(function (item) {
          _this3.imgList.push(item.image);
        });
        _this3.tableData.data.forEach(function (item) {
          _this3.checked.forEach(function (element) {
            if (Number(item.id) === Number(element.id)) {
              _this3.$nextTick(function () {
                _this3.$refs.multipleTable.toggleRowSelection(item, true);
              });
            }
          });
        });
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.listLoading = false;
        _this3.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _order = require("@/api/order");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list1: [{
        name: "list1 - 1:John",
        id: 1
      }, {
        name: "list1 - 2:Joao",
        id: 2
      }, {
        name: "list1 - 3:Jean",
        id: 3
      }, {
        name: "list1 - 4:Gerard",
        id: 4
      }],
      list2: [{
        name: "list2 - 1:Juan",
        id: 5
      }, {
        name: "list2 - 2:Edgard",
        id: 6
      }, {
        name: "list2 - 3:Johnson",
        id: 7
      }],
      tableData: {
        total: 0,
        data: []
      },
      tableForm: {
        status: "all",
        dateLimit: "",
        orderNo: "",
        page: 1,
        limit: 7,
        type: 0
      },
      listData: [],
      userList: [{
        name: "Mike",
        mission: [],
        id: 1
      }, {
        name: "Jonse",
        mission: [],
        id: 2
      }, {
        name: "Jackwee",
        mission: [],
        id: 3
      }, {
        name: "Zora",
        mission: [],
        id: 4
      }]
    };
  },
  components: {
    draggable: _vuedraggable.default
  },
  mounted: function mounted() {
    if (this.$route.query) {
      console.log(this.$route.query.startStr);
    }
    this.loadData();
  },
  methods: {
    log: function log(evt) {
      window.console.log(evt);
    },
    loadData: function loadData() {
      var _this = this;
      this.listLoading = true;
      (0, _order.orderCarListApi)(this.tableForm).then(function (res) {
        res.list.map(function (item, index) {
          item.color = "color" + index % 3;
          var num = 0;
          if (item.productList && item.productList.length) {
            item.productList.map(function (pro) {
              num += pro.info.payNum;
            });
          }
          item.num = num;
        });
        _this.tableData.data = res.list || [];
        _this.tableData.total = res.total;
        _this.listLoading = false;
        _this.checkedCities = _this.$cache.local.has("order_stroge") ? _this.$cache.local.getJSON("order_stroge") : _this.checkedCities;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    doPageChange: function doPageChange(page) {
      this.tableForm.page = page;
      this.loadData();
    }
  }
};
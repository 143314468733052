var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("h2", { staticClass: "module-title" }, [
                  _vm._v("pending order"),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "ul",
                    { staticClass: "list" },
                    [
                      _c(
                        "draggable",
                        {
                          staticClass: "list-group",
                          attrs: { list: _vm.tableData.data, group: "people" },
                          on: { change: _vm.log },
                        },
                        _vm._l(_vm.tableData.data, function (item) {
                          return _c(
                            "li",
                            {
                              key: item.id,
                              class: "list-item list-group-item " + item.color,
                            },
                            [
                              _c("div", { staticClass: "item-view" }, [
                                _vm._v(_vm._s(item.orderId)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "item-view" }, [
                                _vm._v("user name" + _vm._s(item.realName)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "item-view" }, [
                                _c("span", [
                                  _vm._v(
                                    "number of bottles：" +
                                      _vm._s(item.orderNum)
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "item-view" }, [
                                _vm._v("order date:" + _vm._s(item.orderDate)),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "page-sizes": [10, 20, 30, 40],
                        "page-size": _vm.tableForm.limit,
                        "current-page": _vm.tableForm.page,
                        layout: "total,  prev, pager, next",
                        total: _vm.tableData.total,
                        small: "",
                      },
                      on: { "current-change": _vm.doPageChange },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    _vm._l(_vm.userList, function (user) {
                      return _c(
                        "el-col",
                        { key: user.id, attrs: { span: 8 } },
                        [
                          _c("h2", { staticClass: "module-title" }, [
                            _vm._v(_vm._s(user.name)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "draggable",
                            {
                              staticClass: "list-group infinite-list-wrapper",
                              staticStyle: { overflow: "auto" },
                              attrs: { list: user.mission, group: "people" },
                              on: { change: _vm.log },
                            },
                            _vm._l(user.mission, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  class:
                                    "list-item list-group-item " + item.color,
                                },
                                [
                                  _c("div", { staticClass: "item-view" }, [
                                    _vm._v(_vm._s(item.orderId)),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "item-view" }, [
                                    _vm._v(
                                      "user name：" + _vm._s(item.realName)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "item-view" }, [
                                    _c("span", [
                                      _vm._v(
                                        "number of bottles：" + _vm._s(item.num)
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "price：" + _vm._s("$" + item.payPrice)
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "item-view" }, [
                                    _vm._v("最迟送达日期：2023-03-06"),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
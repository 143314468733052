function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { driverInfo } from "@/api/driver";
import { deepClone } from "@/utils";
import * as systemMerchantApi from "@/api/merchant.js";
export var infoMixins = {
  data: function data() {
    return {
      activeName: "first",
      driverInfo: {},
      productTotal: 0,
      loading: false,
      scoreAvg: 0,
      driverId: 0,
      orderId: null
    };
  },
  computed: {},
  mounted: function mounted() {
    if (this.$route.query.id) {
      this.driverId = this.$route.query.id;
      this.getInfo();
    }
  },
  methods: {
    setTotal: function setTotal(total) {
      this.productTotal = total;
    },
    handleOpenAddress: function handleOpenAddress() {
      this.$refs.plantAddress.onSearch(this.driverInfo);
    },
    selectAddressOk: function selectAddressOk(addressData) {
      var _this = this;
      var params = Object.assign({}, this.driverInfo, _objectSpread({}, addressData));
      systemMerchantApi.merchantUpdate(params).then(function (data) {
        _this.driverInfo = deepClone(res);
        _this.$refs.areaMap.onSearch(res);
        _this.$message.success("successful");
        _this.loading = false;
      }).finally(function (err) {
        _this.loading = false;
      });
    },
    getInfo: function getInfo() {
      var _this2 = this;
      this.loading = true;
      driverInfo(this.driverId).then(function (res) {
        _this2.driverInfo = deepClone(res);
        _this2.$refs.recordList.getList(res.account);
        _this2.scoreAvg = res.scoreAvg ? Number(parseFloat(res.scoreAvg).toFixed(1)) : 0;
      }).finally(function () {
        _this2.loading = false;
      });
    },
    handleOpenEmail: function handleOpenEmail() {},
    handleTabClick: function handleTabClick() {},
    handleOpenBanlance: function handleOpenBanlance() {},
    handleCellChange: function handleCellChange(record, dataIndex, value) {
      var _this3 = this;
      if (!dataIndex) {
        this.$message.warning("请设置需要更新的列");
        return;
      }
      console.log(record[dataIndex]);
      if (record[dataIndex] == value) return;
      this.driverInfo = Object.assign({}, this.driverInfo, _defineProperty({}, dataIndex, value));
      var params = _objectSpread({}, this.driverInfo);
      driverApi.updateDriver(params).then(function (data) {
        _this3.$message.success('Update successfully');
      });
    }
  }
};
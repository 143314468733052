//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { driverList } from "@/api/driver";
import { driverToCar, dayList, upDeliveryTime } from "@/api/order";
import { carList, alloCarToOrder } from "@/api/car";
import moment from "moment";
export default {
  name: "changeDeliverySingle",
  props: {
    orderId: String
  },
  data: function data() {
    return {
      visible: false,
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      loading: false,
      checkedAll: false,
      selectCarVisible: false,
      driverList: [],
      form: {
        type: 1
      },
      assignCarList: [],
      rules: {
        deliveryTime: [{
          required: true,
          message: "Please choose",
          trigger: ["blur", "change"]
        }],
        type: [{
          required: true,
          message: "",
          trigger: ["blur", "change"]
        }],
        driverId: [{
          required: true,
          message: "Please select a driver",
          trigger: ["blur", "change"]
        }],
        carId: [{
          required: true,
          message: "Please select a car",
          trigger: ["blur", "change"]
        }]
      }
    };
  },
  watch: {
    checkedAll: function checkedAll(val) {
      this.form.orderList.map(function (e) {
        e.checked = val;
      });
    }
  },
  mounted: function mounted() {},
  methods: {
    getDriverList: function getDriverList() {
      var _this = this;
      driverList({
        page: 1,
        limit: 99999
      }).then(function (res) {
        _this.driverList = res.list;
      });
    },
    open: function open(item) {
      this.getDriverList();
      item.type = item.type ? item.type : 1;
      this.form = Object.assign({}, item, {
        carId: item.status == 9 ? null : item.carId ? item.carId : item.car ? item.car.id : null
      });
      this.visible = true;
      this.selectCarVisible = false;
    },
    // 获取日视图订单列表
    getDayList: function getDayList(value) {
      var _this2 = this;
      if (this.form.status == 9) {
        var params = {
          date: moment(value).format("YYYY-MM-DD")
        };
        dayList(params).then(function (res) {
          var dayListData = res.today;
          if (dayListData.car.length) {
            _this2.selectCarVisible = true;
            carList({
              limit: 999,
              page: 1
            }).then(function (data) {
              _this2.assignCarList = data.list;
            });
          }
        }).catch(function () {
          _this2.loading = false;
        });
      }
    },
    // 提交
    handlerSubmit: function handlerSubmit(name) {
      var _this3 = this;
      this.$refs[name].validate(function (valid) {
        if (!valid) return;
        _this3.loading = true;
        var data = {
          num: _this3.form.num,
          orderList: [_this3.form]
        };
        data.orderIds = [_this3.form.id];
        if (!_this3.selectCarVisible) {
          if (_this3.form.type == 1) {
            data.deliveryTime = moment(_this3.form.deliveryTime).format("YYYY-MM-DD");
            upDeliveryTime(data).then(function (async) {
              _this3.$message.success("Delivery time changed successfully");
              _this3.visible = false;
              _this3.$refs[name].resetFields();
              _this3.$emit("update");
              _this3.loading = false;
            }).catch(function () {
              _this3.loading = false;
            });
          }
          if (_this3.form.type == 2) {
            data.driverId = _this3.form.driverId;
            // data.isChangeDriver = true;
            data.carId = _this3.form.carId, driverToCar(data).then(function (async) {
              _this3.$message.success("Driver changed successfully");
              _this3.visible = false;
              _this3.handleClose();
              _this3.$emit("update");
              _this3.loading = false;
            }).catch(function () {
              _this3.loading = false;
            });
          }
        } else {
          var params = {
            orderId: _this3.form.orderId,
            deliveryDate: moment(_this3.form.deliveryTime).format("YYYY-MM-DD"),
            carId: _this3.form.carId
          };
          alloCarToOrder(params).then(function (res) {
            _this3.$message.success("Car changed successfully");
            _this3.visible = false;
            _this3.$refs[name].resetFields();
            _this3.$emit("update");
            _this3.loading = false;
          }).finally(function () {
            _this3.loading = false;
          });
        }
      });
    },
    handleClose: function handleClose() {
      this.cancel("form");
    },
    cancel: function cancel(name) {
      this.visible = false;
      this.form = {};
      this.checkedAll = false;
      this.$refs[name].resetFields();
    }
  }
};
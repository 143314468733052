var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "800px",
        "before-close": _vm.close,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.pram,
                rules: _vm.rules,
                "label-position": "top",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _vm.isEditable
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { "margin-bottom": "24px" },
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.handleAdd },
                            },
                            [_vm._v("Add product")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pram.productList && _vm.pram.productList.length > 0
                        ? [
                            _c(
                              "el-table",
                              {
                                attrs: {
                                  data: _vm.pram.productList,
                                  border: "",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Product name",
                                    prop: "productName",
                                    "min-width": "100",
                                    "show-overflow-tooltip": true,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Number",
                                    "min-width": "100",
                                    "show-overflow-tooltip": true,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          var $index = ref.$index
                                          return [
                                            _vm.isEditable
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "product-number",
                                                      attrs: {
                                                        prop:
                                                          "productList." +
                                                          $index +
                                                          ".number",
                                                        rules: _vm.rules.number,
                                                      },
                                                    },
                                                    [
                                                      _c("el-input-number", {
                                                        model: {
                                                          value: row.number,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              "number",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row.number",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(row.number) + "G"
                                                  ),
                                                ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1833970068
                                  ),
                                }),
                                _vm._v(" "),
                                _vm.isEditable
                                  ? _c("el-table-column", {
                                      attrs: {
                                        label: "operation",
                                        "min-width": "100",
                                        "show-overflow-tooltip": true,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var $index = ref.$index
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "small",
                                                      type: "text",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleDel(
                                                          $index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("delete")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3293185121
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.isEditable
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Vehicle Capacity",
                                prop: "takeNum",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 0,
                                  disabled: "",
                                  placeholder: "number of bottles",
                                },
                                model: {
                                  value: _vm.pram.takeNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pram, "takeNum", $$v)
                                  },
                                  expression: "pram.takeNum",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticStyle: { opacity: "0" } }, [
                                _vm._v(_vm._s(_vm.totalBottles)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "text-align": "right", "margin-top": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("pram")
                },
              },
            },
            [_vm._v("confirm")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("productManage", {
        ref: "productManage",
        attrs: { list: _vm.pram.productList },
        on: { ok: _vm.handleOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
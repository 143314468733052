"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var orderApi = _interopRequireWildcard(require("@/api/order.js"));
var _vuex = require("vuex");
var _auth = require("@/utils/auth");
var _orderErrorModal = _interopRequireDefault(require("./components/orderErrorModal"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    orderErrorModal: _orderErrorModal.default
  },
  data: function data() {
    return {
      constants: this.$constants,
      loading: false,
      listData: {
        list: []
      },
      storeData: [],
      userVisible: false,
      tempKey: "https://admin.h2owaterco.com/static/template.xlsx",
      pram: {
        skipPeriods: ""
      },
      listPram: {
        page: 1,
        limit: this.$constants.page.limit[0],
        searchKey: ""
      },
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        // 0=创建，1=编辑
        editData: {}
      },
      periodList: [],
      obj: {
        billAddress2: "账单地址2",
        billAddress3: "账单地址3",
        billAddressCityStateZip: "账单地址",
        billingEmail: "邮箱",
        contact: "联系人",
        customerName: "客户名称",
        dispenserCount: "饮水机统计",
        errorRemark: "错误原因",
        fuelSurcharge: "配送费",
        item: "商品",
        item2: "商品2",
        item3: "商品3",
        phone: "电话",
        rate: "价格",
        rate2: "价格2",
        rate3: "价格3",
        recurringDescription: "Subscription time",
        scheduleDate: "上个订单时间",
        specialInstruction: "备注",
        storeId: "水厂id"
      },
      columns: [{
        label: "Billing Email",
        prop: "billingEmail",
        minWidth: "150px"
      }, {
        label: "Contact",
        prop: "contact",
        minWidth: "150px"
      }, {
        label: "Customer Name",
        prop: "customerName",
        minWidth: "150px"
      }, {
        label: "BillAddressCityStateZip",
        prop: "billAddressCityStateZip",
        minWidth: "200px"
      }, {
        label: "BillAddress 2",
        prop: "billAddress2",
        minWidth: "150px"
      }, {
        label: "BillAddress 3",
        prop: "billAddress3",
        minWidth: "150px"
      }, {
        label: "DispenserCount",
        prop: "dispenserCount",
        minWidth: "150px"
      }, {
        label: "Error Remark",
        prop: "errorRemark",
        minWidth: "150px"
      }, {
        label: "FuelSurcharge",
        prop: "fuelSurcharge",
        minWidth: "150px"
      }, {
        label: "Item",
        prop: "item",
        minWidth: "150px"
      }, {
        label: "Rate",
        prop: "rate",
        minWidth: "150px"
      }, {
        label: "Item 2",
        prop: "item2",
        minWidth: "150px"
      }, {
        label: "Rate 2",
        prop: "rate2",
        minWidth: "150px"
      }, {
        label: "Item 3",
        prop: "item3",
        minWidth: "150px"
      }, {
        label: "Rate 3",
        prop: "rate3",
        minWidth: "150px"
      }, {
        label: "Phone",
        prop: "phone",
        minWidth: "150px"
      }, {
        label: "Recurring Description",
        prop: "recurringDescription",
        minWidth: "150px"
      }, {
        label: "ScheduleDate",
        prop: "scheduleDate",
        minWidth: "150px"
      }, {
        label: "SpecialInstruction",
        prop: "specialInstruction",
        minWidth: "150px"
      }],
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: "Import",
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: {
          Authorization: "Bearer " + (0, _auth.getToken)()
        },
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + "/system/user/importData"
      },
      multipleSelection: []
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(["storeId"])), {}, {
    action: function action() {
      return process.env.VUE_APP_BASE_API + "/api/admin/user/import";
    },
    headers: function headers() {
      var header = {
        "Authori-zation": (0, _auth.getToken)()
      };
      return header;
    }
  }),
  mounted: function mounted() {
    this.handleList();
    // let array = [];
    // const obj = this.obj;
    // for (var key in obj) {
    //   array.push({
    //     label: obj[key],
    //     prop: key,
    //     minWidth: "150px"
    //   });
    // }
    // console.log(JSON.stringify(array));
  },
  methods: {
    handleEdit: function handleEdit(record) {
      this.$refs.orderErrorModal.open(record);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    seachList: function seachList() {
      this.listPram.page = 1;
      this.handleList();
    },
    close: function close() {
      this.userVisible = false;
      this.handleList();
    },
    handleList: function handleList() {
      var _this = this;
      this.loading = true;
      orderApi.orderErrorList(this.listPram).then(function (data) {
        _this.listData = data;
        _this.loading = false;
      }).finally(function () {
        _this.loading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handleList(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handleList(this.listPram);
    },
    resetQuery: function resetQuery() {
      this.listPram.searchKey = "";
      this.handleList();
    },
    doImport: function doImport() {
      var _this2 = this;
      if (this.multipleSelection.length == 0) {
        this.$message.warning("please select one data");
        return;
      }
      this.loading = true;
      var formData = this.multipleSelection;
      orderApi.orderErrorImport(formData).then(function (res) {
        _this2.$message.success("Import success");
        _this2.seachList();
      }).finally(function (e) {
        _this2.loading = false;
      });
      return;
      this.upload.title = "Import";
      this.upload.isUploading = false;
      this.upload.open = true;
    },
    handleFileUploadProgress: function handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    // 文件上传成功处理
    handleFileSuccess: function handleFileSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert(response.message, "Import Result", {
        dangerouslyUseHTMLString: true
      });
      this.getList();
    }
  }
};
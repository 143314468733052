var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "Edit",
        "before-close": _vm.handleClose,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "order_box",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                labelPosition: "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Frequency", prop: "period" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "Please choose" },
                              model: {
                                value: _vm.form.period,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "period", $$v)
                                },
                                expression: "form.period",
                              },
                            },
                            _vm._l(_vm.periodList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Next Order Date",
                            prop: "nextOrderDate",
                          },
                        },
                        [
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "date",
                                placeholder: "Please choose",
                                "value-format": "yyyy-MM-dd",
                                format: "yyyy-MM-dd",
                              },
                              model: {
                                value: _vm.form.nextOrderDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "nextOrderDate", $$v)
                                },
                                expression: "form.nextOrderDate",
                              },
                            }),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _vm.form.orderInfoList &&
                      _vm.form.orderInfoList.length > 0
                        ? [
                            _c(
                              "el-table",
                              {
                                staticStyle: { "margin-top": "24px" },
                                attrs: {
                                  data: _vm.form.orderInfoList,
                                  border: "",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "product name",
                                    prop: "productName",
                                    "min-width": "100",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Number",
                                    "min-width": "100",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          var $index = ref.$index
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "orderInfoList." +
                                                    $index +
                                                    ".cartNum",
                                                  rules: _vm.rules.cartNum,
                                                },
                                              },
                                              [
                                                _c("el-input-number", {
                                                  attrs: { min: 1 },
                                                  model: {
                                                    value: row.cartNum,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "cartNum",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.cartNum",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2715908737
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.handleClose()
                },
              },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("form")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "add Order",
        visible: _vm.orderVisible,
        width: "1010px",
        "before-close": _vm.Close,
      },
      on: {
        "update:visible": function ($event) {
          _vm.orderVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "pram",
          attrs: { model: _vm.pram, rules: _vm.rules },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "User address" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { filterable: "" },
                  on: { change: _vm.change },
                  model: {
                    value: _vm.addressId,
                    callback: function ($$v) {
                      _vm.addressId = $$v
                    },
                    expression: "addressId",
                  },
                },
                _vm._l(_vm.addressData, function (item, i) {
                  return _c("el-option", {
                    key: i,
                    attrs: { value: item.id, label: item.address },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Subscription cycle" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "205px" },
                      attrs: { type: "number", min: 1 },
                      model: {
                        value: _vm.period,
                        callback: function ($$v) {
                          _vm.period = $$v
                        },
                        expression: "period",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { "line-height": "36px" },
                          attrs: { slot: "suffix" },
                          slot: "suffix",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.periodTypeText) +
                              "/times\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { "margin-left": "14px" },
                      model: {
                        value: _vm.subType,
                        callback: function ($$v) {
                          _vm.subType = $$v
                        },
                        expression: "subType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "day" } }, [
                        _vm._v("Day"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "week" } }, [
                        _vm._v("Week"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tabs",
            {
              on: {
                "tab-click": function ($event) {
                  _vm.period = null
                },
              },
              model: {
                value: _vm.selectStore,
                callback: function ($$v) {
                  _vm.selectStore = $$v
                },
                expression: "selectStore",
              },
            },
            _vm._l(_vm.storeList, function (item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: item.name, name: String(index) },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "shop-box" },
            _vm._l(_vm.shopDetail.waterList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "shop-item",
                  style: !item.number
                    ? "background: no-repeat;border: 1px solid #ddd;"
                    : "",
                },
                [
                  _c("div", { staticClass: "left" }, [
                    _c("img", {
                      staticClass: "image",
                      attrs: { src: item.image, alt: "" },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("div", [
                          _vm._v(_vm._s(item.title) + _vm._s(item.unitName)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { display: "flex" } }, [
                          _c("p", [_vm._v("$")]),
                          _vm._v(" "),
                          _c("p", { staticClass: "price" }, [
                            _vm._v(_vm._s(item.price)),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "right" }, [
                    _c("i", {
                      staticClass: "el-icon-remove-outline",
                      style: !item.number ? "color:#c0c4cc" : "color:#1890ff",
                      on: {
                        click: function ($event) {
                          return _vm.update(index)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "min-width": "20px",
                          "text-align": "center",
                          "font-size": "18px",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.number) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-circle-plus-outline",
                      style: !item.number
                        ? "color:#1890ff"
                        : "color:rgba(0, 114, 226, 1)",
                      on: {
                        click: function ($event) {
                          return _vm.update(index, "add")
                        },
                      },
                    }),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.total > 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "end",
                    "flex-direction": "column",
                    "margin-bottom": "10px",
                    "line-height": "30px",
                  },
                },
                [
                  _c("span", { staticStyle: { display: "flex" } }, [
                    _c("p", { staticClass: "total" }, [_vm._v("total:")]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { display: "flex" } }, [
                      _c("p", [_vm._v("$")]),
                      _vm._v(" "),
                      _c("a", { attrs: { href: "#" } }, [
                        _vm._v(_vm._s(_vm.total)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "cash-pledge" }, [
                    _vm._v("\n        Bucket deposit："),
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v(_vm._s(_vm.depositAmt)),
                    ]),
                    _vm._v("元\n      "),
                  ]),
                  _vm._v(" "),
                  _vm.shopDetail.deliveryPrice
                    ? _c("p", { staticClass: "cash-pledge" }, [
                        _vm._v("\n        freight "),
                        _c("a", { attrs: { href: "#" } }, [
                          _vm._v(_vm._s(_vm.shopDetail.deliveryPrice)),
                        ]),
                        _vm._v("元\n      "),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-form-item", [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "end",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handlerSubmit("pram")
                      },
                    },
                  },
                  [_vm._v("confirm")]
                ),
                _vm._v(" "),
                _c("el-button", { on: { click: _vm.Close } }, [
                  _vm._v("cancel"),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("editAddress", {
        ref: "editAddress",
        attrs: { uid: _vm.uid },
        on: { update: _vm.handleUpdateAddress },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
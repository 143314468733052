var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "order-page",
          attrs: {
            title: "order message",
            visible: _vm.dialogVisible,
            width: "700px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "description",
            },
            [
              _vm.orderDatalist.id
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("Notes for user"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "acea-row" }, [
                        _c("div", { staticClass: "description-term" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("nickname："),
                          ]),
                          _vm._v(
                            _vm._s(_vm.orderDatalist.realName) + "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("verified phone number："),
                          ]),
                          _vm._v(
                            _vm._s(_vm.orderDatalist.phone || "--") +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("account："),
                          ]),
                          _vm._v(
                            _vm._s(_vm.orderDatalist.account || "--") +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-divider"),
                      _vm._v(" "),
                      _vm.orderDatalist.car
                        ? [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("Vehicle info"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "acea-row" }, [
                              _c("div", { staticClass: "description-term" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("vehicle name："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.orderDatalist.car.carName) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "description-term" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("License palte："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.orderDatalist.car.carNum) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "description-term" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("vehicle type："),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDatalist.car.carType == 1
                                      ? "Truck"
                                      : "Mini Van"
                                  ) + "\n            "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-divider"),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.orderDatalist.deliveryId
                        ? [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("Delivery message"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "acea-row" }, [
                              _c("div", { staticClass: "description-term" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Delivery name："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.orderDatalist.deliveryName) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "description-term" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Delivery Date："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.orderDatalist.deliveryDate) +
                                    "\n            "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-divider"),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.orderDatalist.statusStr.key ===
                                "toBeWrittenOff"
                                ? "提货信息"
                                : "Notes for receiver"
                            ) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "acea-row" }, [
                        _c("div", { staticClass: "description-term" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.orderDatalist.statusStr.key ===
                                  "toBeWrittenOff"
                                  ? "提货人"
                                  : "Receiver"
                              ) + "："
                            ),
                          ]),
                          _vm._v(
                            _vm._s(_vm.orderDatalist.realName) + "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.orderDatalist.statusStr.key ===
                                  "toBeWrittenOff"
                                  ? "提货电话"
                                  : "receipient phone #"
                              ) + "："
                            ),
                          ]),
                          _vm._v(
                            _vm._s(_vm.orderDatalist.userPhone) + "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.orderDatalist.statusStr.key !== "toBeWrittenOff"
                          ? _c(
                              "div",
                              {
                                staticClass: "description-term",
                                staticStyle: { width: "100%" },
                              },
                              [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.orderDatalist.statusStr.key ===
                                        "toBeWrittenOff"
                                        ? "提货地址"
                                        : "receipient address"
                                    ) + "："
                                  ),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.orderDatalist.userAddress) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("el-divider"),
                      _vm._v(" "),
                      _c("div", { staticClass: "title" }, [
                        _vm._v("Order message"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "acea-row" }, [
                        _c("div", { staticClass: "description-term" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("Order ID："),
                          ]),
                          _vm._v(
                            _vm._s(_vm.orderDatalist.orderId) + "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "description-term",
                            staticStyle: { color: "red" },
                          },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("order status："),
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getOrderStatus(_vm.orderDatalist.status)
                                )
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("payment："),
                          ]),
                          _vm._v(
                            "$" +
                              _vm._s(_vm.orderDatalist.payPrice) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("total："),
                          ]),
                          _vm._v(
                            "$" +
                              _vm._s(_vm.orderDatalist.proTotalPrice) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("deposit："),
                          ]),
                          _vm._v(
                            "$" +
                              _vm._s(_vm.orderDatalist.freightPrice) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("freight charge："),
                          ]),
                          _vm._v(
                            "$" +
                              _vm._s(_vm.orderDatalist.payPostage) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.orderDatalist.refundPrice
                          ? _c(
                              "div",
                              { staticClass: "description-term fontColor3" },
                              [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("refund amount："),
                                ]),
                                _vm._v(
                                  "$" +
                                    _vm._s(_vm.orderDatalist.refundPrice) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.orderDatalist.refundReasonTime
                          ? _c("div", { staticClass: "description-term" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("refund date："),
                              ]),
                              _vm._v(
                                _vm._s(_vm.orderDatalist.refundReasonTime) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("payment method："),
                          ]),
                          _vm._v(
                            _vm._s(_vm.orderDatalist.payTypeStr) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("number of items："),
                          ]),
                          _vm._v(
                            _vm._s(_vm.orderDatalist.totalNum) + "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "description-term column2" },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("item detail："),
                            ]),
                            _vm._v(" "),
                            false
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.orderDatalist.orderInfo,
                                    function (item, index) {
                                      return _c("div", { key: index }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.info.productName) + "："
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "blue" } },
                                          [
                                            _vm._v(
                                              _vm._s(item.info.payNum || 0) +
                                                " bottles"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "blue" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.info.deliveryNum || 0
                                              ) + " bottles"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [
                                            _vm._v(
                                              _vm._s(item.info.price || 0) + "$"
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-table",
                              { attrs: { data: _vm.orderDatalist.orderInfo } },
                              [
                                _c("el-table-column", {
                                  attrs: { label: "Product name" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(row.info.productName) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3151966099
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Price" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [
                                                _vm._v(
                                                  "$" +
                                                    _vm._s(row.info.price || 0)
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3773429147
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Pay num" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "blue" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(row.info.payNum || 0) +
                                                    " bottles"
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1860562870
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Delivery num" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "blue" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    row.info.deliveryNum || 0
                                                  ) + " bottles"
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    530104034
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.orderDatalist.status == 4
                        ? [
                            _c("el-divider"),
                            _vm._v(" "),
                            _c("div", { staticClass: "title" }, [
                              _vm._v("Delivered notes"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "acea-row" }, [
                              _c("div", { staticClass: "description-term" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("return bottles："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.orderDatalist.receiveNum) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "description-term" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Bottle amount for special request："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.orderDatalist.abnormalNum) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "description-term" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("reason for special request："),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDatalist.abnormalReason || "--"
                                  ) + "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "description-term" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("reason for special request："),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDatalist.deliveryNum || "--"
                                  ) + "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.orderDatalist.image
                                ? _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [
                                      _c("span", { staticClass: "label" }, [
                                        _vm._v("send pictures"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        [
                                          _vm.orderDatalist.image
                                            ? _vm._l(
                                                _vm.orderDatalist.image.split(
                                                  ","
                                                ),
                                                function (item, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticClass:
                                                        "demo-image__preview",
                                                      staticStyle: {
                                                        width: "55px",
                                                        height: "55px",
                                                        display: "inline-block",
                                                        "margin-top": "15px",
                                                        "margin-right": "5px",
                                                      },
                                                    },
                                                    [
                                                      _c("el-image", {
                                                        staticStyle: {
                                                          height: "100%",
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          src: item,
                                                          "preview-src-list": [
                                                            item,
                                                          ],
                                                          fit: "cover",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.orderDatalist.deliveryType === "express"
                        ? [
                            _c("el-divider"),
                            _vm._v(" "),
                            _c("div", { staticClass: "title" }, [
                              _vm._v("物流信息"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "acea-row" }, [
                              _c("div", { staticClass: "description-term" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("快递公司："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.orderDatalist.deliveryName) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "description-term" },
                                [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v("快递单号："),
                                  ]),
                                  _vm._v(
                                    _vm._s(_vm.orderDatalist.deliveryId) +
                                      "\n              "
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: ["admin:order:logistics:info"],
                                          expression:
                                            "['admin:order:logistics:info']",
                                        },
                                      ],
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: { type: "primary", size: "mini" },
                                      on: { click: _vm.openLogistics },
                                    },
                                    [_vm._v("物流查询")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.orderDatalist.deliveryType === "send"
                        ? [
                            _c("el-divider"),
                            _vm._v(" "),
                            _c("div", { staticClass: "title" }, [
                              _vm._v("配送信息"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "acea-row" }, [
                              _c("div", { staticClass: "description-term" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("送货人姓名："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.orderDatalist.deliveryName) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "description-term" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("送货人电话："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.orderDatalist.deliveryId) +
                                    "\n            "
                                ),
                              ]),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.orderDatalist.mark
                        ? [
                            _c("el-divider"),
                            _vm._v(" "),
                            _c("div", { staticClass: "title" }, [
                              _vm._v("Notes"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "acea-row" }, [
                              _c("div", { staticClass: "description-term" }, [
                                _vm._v(_vm._s(_vm.orderDatalist.mark)),
                              ]),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.orderDatalist.status !== 3 && _vm.orderDatalist.status !== 4
            ? _c(
                "div",
                {
                  staticStyle: { "text-align": "right", "margin-top": "20px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.completeOrder },
                    },
                    [_vm._v("Complete order")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.orderDatalist
        ? _c(
            "el-dialog",
            {
              attrs: { title: "提示", visible: _vm.modal2, width: "30%" },
              on: {
                "update:visible": function ($event) {
                  _vm.modal2 = $event
                },
              },
            },
            [
              _c("div", { staticClass: "logistics acea-row row-top" }, [
                _c("div", { staticClass: "logistics_img" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/imgs/expressi.jpg") },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "logistics_cent" }, [
                  _c("span", { staticClass: "mb10" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("物流公司："),
                    ]),
                    _vm._v(_vm._s(_vm.orderDatalist.deliveryName)),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("物流单号："),
                    ]),
                    _vm._v(_vm._s(_vm.orderDatalist.deliveryId)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "acea-row row-column-around trees-coadd" },
                [
                  _c(
                    "div",
                    { staticClass: "scollhide" },
                    [
                      _c(
                        "el-timeline",
                        { attrs: { reverse: _vm.reverse } },
                        _vm._l(_vm.result, function (item, i) {
                          return _c("el-timeline-item", { key: i }, [
                            _c("p", {
                              staticClass: "time",
                              domProps: { textContent: _vm._s(item.time) },
                            }),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "content",
                              domProps: { textContent: _vm._s(item.status) },
                            }),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.modal2 = false
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("order-done", {
        ref: "orderDone",
        on: { update: _vm.handleOrderDone },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
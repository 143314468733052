function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { orderListDataApi, orderStatusNumApi, orderCancelApi, finish, writeUpdateApi, orderListApi, updatePriceApi, orderLogApi, orderMarkApi, orderDeleteApi, orderRefuseApi, sendCarOrder, orderPrint } from "@/api/order";
import cardsData from "@/components/cards/index";
import * as systemMerchantApi from "@/api/merchant.js";
import zbParser from "@/components/FormGenerator/components/parser/ZBParser";
import orderDetails from "./orderDetails";
import orderDetail from "./orderDetail";
import orderSend from "./orderSend";
import removOrder from "./removOrder";
import orderVideoSend from "./orderVideoSend";
import { storeStaffListApi } from "@/api/storePoint";
import Cookies from "js-cookie";
import { isWriteOff } from "@/utils";
import { orderExcelApi } from "@/api/store";
import { mapGetters } from "vuex";
import { checkPermi } from "@/utils/permission"; // 权限判断函数
import { Row } from "element-ui";
export default {
  name: "orderlistDetails",
  components: {
    cardsData: cardsData,
    zbParser: zbParser,
    orderDetails: orderDetails,
    orderDetail: orderDetail,
    orderSend: orderSend,
    removOrder: removOrder,
    orderVideoSend: orderVideoSend
  },
  data: function data() {
    return {
      dialogVisibleOrder: false,
      loadings: false,
      RefuseVisible: false,
      RefuseData: {},
      orderId: "",
      refundVisible: false,
      refundData: {},
      dialogVisibleJI: false,
      tableDataLog: {
        data: [],
        total: 0
      },
      tableFromLog: {
        page: 1,
        limit: 10,
        orderNo: 0
      },
      LogLoading: false,
      isCreate: 1,
      editData: null,
      dialogVisible: false,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        status: "all",
        dateLimit: "",
        orderNo: "",
        page: 1,
        limit: 10,
        type: 0
      },
      orderChartType: {},
      timeVal: [],
      fromList: this.$constants.fromList,
      limit: [10, 20, 40, 60, 100],
      fromType: [{
        value: "all",
        text: "全部"
      }, {
        value: "info",
        text: "普通"
      }, {
        value: "pintuan",
        text: "拼团"
      }, {
        value: "bragin",
        text: "砍价"
      }, {
        value: "miaosha",
        text: "秒杀"
      }],
      rules: {
        amount: [{
          required: true,
          message: "refund amount",
          trigger: ["blur", "change"]
        }]
      },
      form: {
        amount: "",
        orderId: ""
      },
      selectionList: [],
      ids: "",
      orderids: "",
      cardLists: [],
      isWriteOff: isWriteOff(),
      proType: 0,
      active: false,
      listData: [],
      orderInfo: {},
      sortType: "",
      dialogVisibleAddOrder: false
    };
  },
  computed: _objectSpread({}, mapGetters(["storeId"])),
  mounted: function mounted() {
    this.getList();
    this.getOrderStatusNum();
    this.handleList();
  },
  methods: {
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (row.status == 9) {
        return "error-row";
      }
    },
    handleSortChange: function handleSortChange(_ref2) {
      var column = _ref2.column,
        prop = _ref2.prop,
        order = _ref2.order;
      if (order == null) {
        this.sortType = "";
      }
      if (prop == "orderType" && order == "descending") {
        this.sortType = orderDesc;
      }
      if (prop == "orderType" && order == "ascending") {
        this.sortType = orderAsc;
      }
      if (prop == "status" && order == "descending") {
        this.sortType = statusDesc;
      }
      if (prop == "status" && order == "ascending") {
        this.sortType = statusAsc;
      }
      this.getList();
    },
    resetQuery: function resetQuery() {
      this.tableFrom = {
        status: "all",
        dateLimit: "",
        orderNo: "",
        page: 1,
        limit: 10,
        type: 0
      }, this.getList();
      this.getOrderStatusNum();
    },
    // 部分退款
    handleNewOrder: function handleNewOrder(row) {
      this.orderInfo = {
        productList: row.productList,
        orderId: row.orderId,
        payPrice: row.payPrice
      };
      this.dialogVisibleOrder = true;
    },
    // 部分退款
    handleAddOrder: function handleAddOrder() {
      this.dialogVisibleAddOrder = true;
    },
    handleList: function handleList() {
      var _this = this;
      systemMerchantApi.merchantList(this.listPram).then(function (data) {
        _this.listData = data.list;
      });
    },
    getOrderStatus: function getOrderStatus(t) {
      var obj = {
        0: "pending",
        1: "pending",
        2: "on route",
        3: "complete",
        4: "complete",
        8: "canceled",
        9: "exception"
      };
      return obj[t];
    },
    onOrderStatus: function onOrderStatus(e) {
      this.form = {
        status: e.statusStr.key,
        orderId: e.orderId
      };
      this.refundVisible = true;
    },
    checkPermi: checkPermi,
    resetFormRefundhandler: function resetFormRefundhandler() {
      this.refundVisible = false;
    },
    resetFormRefusehand: function resetFormRefusehand() {
      this.RefuseVisible = false;
    },
    resetForm: function resetForm(formValue) {
      this.dialogVisible = false;
    },
    // 临时单完成
    handleFinish: function handleFinish(row) {
      var _this2 = this;
      this.$modalSure("Finnish ".concat(row.orderId, " \uFF1F")).then(function () {
        finish({
          orderNo: row.orderId
        }).then(function () {
          _this2.$message.success("successful");
          _this2.tableFrom.page = 1;
          _this2.getList();
        });
      });
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
    },
    // 拒绝退款
    RefusehandleClose: function RefusehandleClose() {
      this.RefuseVisible = false;
    },
    onOrderRefuse: function onOrderRefuse(row) {
      this.orderids = row.orderId;
      this.RefuseData = {
        orderId: row.orderId,
        reason: ""
      };
      this.RefuseVisible = true;
    },
    RefusehandlerSubmit: function RefusehandlerSubmit(formValue) {
      var _this3 = this;
      orderRefuseApi({
        orderNo: this.orderids,
        reason: formValue.reason
      }).then(function (data) {
        _this3.$message.success("successful");
        _this3.RefuseVisible = false;
        _this3.getList();
      });
    },
    // 立即退款
    refundhandleClose: function refundhandleClose() {
      this.refundVisible = false;
    },
    onOrderRefund: function onOrderRefund(row) {
      this.form = {
        orderId: row.orderId,
        amount: row.payPrice
      };
      this.orderids = row.orderId;
      this.refundVisible = true;
    },
    handlerSubmit: function handlerSubmit(form) {
      var _this4 = this;
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        sendCarOrder(_this4.form).then(function (data) {
          _this4.$message.success("successful");
          _this4.refundVisible = false;
          _this4.getList();
        });
      });
    },
    hideEditDialog: function hideEditDialog() {
      this.dialogVisibleOrder = false;
      this.dialogVisibleAddOrder = false;
      // this.getList();
    },
    // 发送
    sendOrder: function sendOrder(row) {
      if (row.type === 0) {
        this.$refs.send.modals = true;
        this.$refs.send.getList();
        this.$refs.send.sheetInfo();
      } else {
        this.$refs.videoSend.modals = true;
        if (!JSON.parse(sessionStorage.getItem("videoExpress"))) this.$refs.videoSend.companyGetList();
      }
      this.orderId = row.orderId;
    },
    // 订单取消/退款
    handleDelete: function handleDelete(row, idx) {
      var _this5 = this;
      this.$prompt("Please enter reason for cancellation", "order cancellation", {
        confirmButtonText: "confirm order cancellation",
        cancelButtonText: "confirm"
      }).then(function (_ref3) {
        var value = _ref3.value;
        _this5.$confirm("onfirm cancellation", {
          confirmButtonText: "confirm order cancellation",
          dangerouslyUseHTMLString: true,
          cancelButtonText: "cancel"
        }).then(function (_ref4) {
          var value = _ref4.value;
          orderCancelApi({
            orderNo: row.orderId,
            text: value
          }).then(function () {
            _this5.$message.success("successful");
            _this5.getList();
          });
        });
      }).catch(function () {});
    },
    // 详情
    onOrderDetails: function onOrderDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.show(id);
      // this.$refs.orderDetailO.getDetail(id);
    },
    // 订单记录
    onOrderLog: function onOrderLog(id) {
      var _this6 = this;
      this.dialogVisibleJI = true;
      this.LogLoading = true;
      this.tableFromLog.orderNo = id;
      orderLogApi(this.tableFromLog).then(function (res) {
        _this6.tableDataLog.data = res.list;
        _this6.tableDataLog.total = res.total;
        _this6.LogLoading = false;
      }).catch(function () {
        _this6.LogLoading = false;
      });
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.onOrderLog();
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.onOrderLog();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 备注
    onOrderMark: function onOrderMark(row) {
      var _this7 = this;
      this.$prompt("order remark", {
        confirmButtonText: "confirm",
        cancelButtonText: "cancel",
        inputErrorMessage: "order remark",
        inputType: "textarea",
        inputValue: row.remark,
        inputPlaceholder: "order remark",
        inputValidator: function inputValidator(value) {
          if (!value) return "输入不能为空";
        }
      }).then(function (_ref5) {
        var value = _ref5.value;
        orderMarkApi({
          mark: value,
          orderNo: row.orderId
        }).then(function () {
          _this7.$message.success("successful");
          _this7.getList();
        });
      }).catch(function () {
        _this7.$message.info("取消输入");
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectionList = val;
      var data = [];
      this.selectionList.map(function (item) {
        data.push(item.orderId);
      });
      this.ids = data.join(",");
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
      // this.getOrderListData();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(",") : "";
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
      // this.getOrderListData();
    },
    // 编辑
    edit: function edit(row) {
      this.orderId = row.orderId;
      this.editData = {
        orderId: row.orderId,
        totalPrice: row.totalPrice,
        totalNum: row.totalNum,
        totalPostage: row.totalPostage,
        payPrice: row.payPrice,
        payPostage: row.payPostage,
        gainIntegral: row.gainIntegral
      };
      this.dialogVisible = true;
    },
    handleArchives: function handleArchives(uid) {
      if (!uid) return;
      this.$router.push({
        path: "/user/archives",
        query: {
          uid: uid
        }
      });
    },
    // handlerSubmit(formValue) {
    //   let data = {
    //     orderNo:formValue.orderId,
    //     receiveNum:formValue.receiveNum,
    //     abnormalNum:formValue.abnormalNum
    //   }
    //   updatePriceApi(data).then(data => {
    //     this.$message.success('核销成功')
    //     this.dialogVisible = false
    //     this.getList()
    //   })
    // },
    // 列表
    getList: function getList() {
      var _this8 = this;
      this.listLoading = true;
      var params = this.tableFrom;
      if (this.sortType) {
        params.sortType = this.sortType;
      }
      orderListApi(params).then(function (res) {
        _this8.tableData.data = res.list || [];
        _this8.tableData.total = res.total;
        _this8.listLoading = false;
        _this8.checkedCities = _this8.$cache.local.has("order_stroge") ? _this8.$cache.local.getJSON("order_stroge") : _this8.checkedCities;
      }).catch(function () {
        _this8.listLoading = false;
      });
    },
    // 数据统计
    getOrderListData: function getOrderListData() {
      var _this9 = this;
      orderListDataApi({
        dateLimit: this.tableFrom.dateLimit
      }).then(function (res) {
        _this9.cardLists = [{
          name: "订单数量",
          count: res.count,
          color: "#1890FF",
          class: "one",
          icon: "icondingdan"
        }, {
          name: "订单金额",
          count: res.amount,
          color: "#A277FF",
          class: "two",
          icon: "icondingdanjine"
        }, {
          name: "微信支付金额",
          count: res.weChatAmount,
          color: "#EF9C20",
          class: "three",
          icon: "iconweixinzhifujine"
        }, {
          name: "余额支付金额",
          count: res.yueAmount,
          color: "#1BBE6B",
          class: "four",
          icon: "iconyuezhifujine2"
        }];
      });
    },
    // 获取各状态数量
    getOrderStatusNum: function getOrderStatusNum() {
      var _this10 = this;
      return;
      orderStatusNumApi(this.tableFrom).then(function (res) {
        _this10.orderChartType = res;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    exports: function exports() {
      var data = {
        dateLimit: this.tableFrom.dateLimit,
        orderNo: this.tableFrom.orderNo,
        status: this.tableFrom.status,
        type: this.tableFrom.type
      };
      orderExcelApi(data).then(function (res) {
        window.open(res.fileName);
      });
    },
    //打印小票
    onOrderPrint: function onOrderPrint(data) {
      var _this11 = this;
      orderPrint(data.orderId).then(function (res) {
        _this11.$modal.msgSuccess("successful");
      }).catch(function (error) {
        _this11.$modal.msgError(error.message);
      });
    }
  }
};
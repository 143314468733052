var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        visible: _vm.visible,
        title: "Adjust sorting",
        "before-close": _vm.handleClose,
        width: "880px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "order_box" },
        [
          _c("el-alert", {
            staticStyle: {
              color: "#000",
              "background-color": "#D1E9FF",
              "margin-bottom": "15px",
            },
            attrs: {
              closable: false,
              title: "Drag the order to adjust the order order",
            },
          }),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: {
                    prop: "type",
                    "label-width": "83%",
                    label: "Order sorting",
                  },
                },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: {
                          display: "inline-flex",
                          height: "24px",
                          opacity: "0",
                          "align-items": "center",
                          cursor: "pointer",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            "margin-right": "2px",
                            color: "#000",
                            width: "24px",
                          },
                          attrs: { src: require("@/assets/imgs/dx.png") },
                        }),
                        _c("a", [_vm._v("reverse order")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: {
                          "margin-right": "10px",
                          display: "inline-flex",
                          height: "24px",
                          "align-items": "center",
                          cursor: "pointer",
                          "min-width": "100px",
                          "justify-content": "flex-end",
                        },
                        on: { click: _vm.chChange },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            "margin-right": "2px",
                            color: "#000",
                            width: "24px",
                          },
                          attrs: { src: require("@/assets/imgs/ch.png") },
                        }),
                        _c("a", [_vm._v("withdraw")]),
                      ]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "bodyRight" }, [
            _c(
              "div",
              { staticClass: "bodyRightLabel" },
              [
                _c(
                  "draggable",
                  {
                    staticClass: "bodyRightdraggable",
                    attrs: {
                      dragClass: "darg",
                      filter: ".noDrag",
                      ghostClass: "ghost",
                      animation: "300",
                      chosenClass: "chosen",
                    },
                    on: { sort: _vm.onDraggableUpdate, onChoose: _vm.onChoose },
                    model: {
                      value: _vm.form.orderList,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "orderList", $$v)
                      },
                      expression: "form.orderList",
                    },
                  },
                  _vm._l(_vm.form.orderList, function (item) {
                    return _c(
                      "transition-group",
                      { key: item.id },
                      [
                        _c(
                          "el-tag",
                          {
                            key: item.id,
                            staticClass: "tabs",
                            class:
                              item.status == 3 || item.status == 4
                                ? "noDrag"
                                : "",
                            staticStyle: { "margin-bottom": "5px" },
                            on: {
                              close: function ($event) {
                                return _vm.deleteTag(item)
                              },
                              dblclick: function ($event) {
                                return _vm.handleDblClick(item)
                              },
                            },
                          },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  width: "350",
                                  "open-delay": 500,
                                  trigger: _vm.isLoading ? "" : "hover",
                                  effect: "light",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "column",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "label",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("ORDER ID:")]
                                        ),
                                        _c(
                                          "el-link",
                                          { attrs: { type: "primary" } },
                                          [_vm._v(_vm._s(item.orderId))]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "label",
                                          attrs: { href: "#" },
                                        },
                                        [_vm._v("USER INFO:")]
                                      ),
                                      _vm._v(" "),
                                      _c("a", { attrs: { href: "#" } }, [
                                        _vm._v(_vm._s(item.userAddress)),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "a-select__tags-text tagname",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _c("div", { staticClass: "flex" }, [
                                      item.status !== 3 && item.status !== 4
                                        ? _c("img", {
                                            staticStyle: {
                                              "margin-right": "2px",
                                              color: "#000",
                                            },
                                            attrs: {
                                              src: require("@/assets/imgs/Vector.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        "\n                    Order " +
                                          _vm._s(item.sort) +
                                          "\n                  "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("form")
                },
              },
            },
            [_vm._v("Confirm")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        visible: _vm.dialogVisible,
        title: _vm.title,
        "destroy-on-close": "",
        "before-close": _vm.close,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.pram,
                rules: _vm.rules,
                "label-position": "top",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              !_vm.storeId
                ? _c(
                    "el-form-item",
                    { attrs: { label: "plant", prop: "storeId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.storeId ? true : false,
                            placeholder: "plant",
                          },
                          model: {
                            value: _vm.pram.storeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.pram, "storeId", $$v)
                            },
                            expression: "pram.storeId",
                          },
                        },
                        _vm._l(_vm.listData, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Vehicle name", prop: "carName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "vehicle name" },
                    model: {
                      value: _vm.pram.carName,
                      callback: function ($$v) {
                        _vm.$set(_vm.pram, "carName", $$v)
                      },
                      expression: "pram.carName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "License palte", prop: "carNum" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "License palte" },
                    model: {
                      value: _vm.pram.carNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.pram, "carNum", $$v)
                      },
                      expression: "pram.carNum",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Vehicle type", prop: "carType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selWidth",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "vehicle type" },
                      model: {
                        value: _vm.pram.carType,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "carType", $$v)
                        },
                        expression: "pram.carType",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "Truck", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "Mini Van", value: 0 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Vehicle Capacity", prop: "takeNum" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, placeholder: "number of bottles" },
                    model: {
                      value: _vm.pram.takeNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.pram, "takeNum", $$v)
                      },
                      expression: "pram.takeNum",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticStyle: { opacity: "0" } }, [
                    _vm._v(_vm._s(_vm.totalBottles)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Vehicle Status" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-text": "active",
                      "inactive-text": "inactive",
                    },
                    model: {
                      value: _vm.pram.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.pram, "status", $$v)
                      },
                      expression: "pram.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("pram")
                },
              },
            },
            [_vm._v(_vm._s(_vm.isCreate ? "confirm" : "update"))]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("cancel")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
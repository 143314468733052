"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var subscribeApi = _interopRequireWildcard(require("@/api/subscribe.js"));
var _utils = require("@/utils");
var _user = require("@/api/user");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "delivery",
  props: {
    balancePrice: {
      type: [Number, String],
      default: 0
    },
    uid: {
      type: [Number, String],
      default: 0
    }
  },
  data: function data() {
    return {
      visible: false,
      loading: false,
      totalPrice: 0,
      form: {
        payType: 0
      },
      isUseBill: false,
      rules: {
        payTime: [{
          required: true,
          message: "payment date required",
          trigger: ["blur", "change"]
        }],
        chequePrice: [{
          required: true,
          message: "payment amount required",
          trigger: ["blur"]
        }],
        chequeNo: [{
          required: true,
          message: "check number required",
          trigger: ["blur"]
        }]
      },
      invoiceNo: [] //Invoice number
    };
  },
  mounted: function mounted() {},
  methods: {
    open: function open(info) {
      this.invoiceNo = info.ids;
      this.totalPrice = info.totalPrice;
      this.form = Object.assign({}, {
        payType: 0,
        payTime: undefined,
        chequePrice: undefined,
        chequeNo: undefined,
        remark: ""
      });
      this.visible = true;
    },
    handleTypeChange: function handleTypeChange(value) {
      var balancePrice = this.balancePrice;
      var totalPrice = this.totalPrice;
      if (value == 3) {
        this.isUseBill = true;
        this.form = Object.assign({}, this.form, {
          chequePrice: totalPrice
        });
      } else {
        this.isUseBill = false;
      }
    },
    handleSubmit: function handleSubmit(row) {
      var _this = this;
      this.$refs.form.validate(function (valid) {
        if (!valid) return;
        var balancePrice = _this.balancePrice;
        var totalPrice = _this.totalPrice;
        var payType = _this.form.payType;
        // if (payType === 3 && balancePrice < totalPrice) {
        //   this.$message.warning("用户余额不足，请更换其他支付方式");
        //   return;
        // }
        _this.loading = true;
        var params = _objectSpread(_objectSpread({}, _this.form), {}, {
          uid: _this.uid,
          totalPrice: _this.totalPrice,
          //发票总金额
          balancePrice: _this.isUseBill ? _this.totalPrice < _this.balancePrice ? _this.totalPrice : _this.balancePrice : 0,
          //用户余额
          invoiceNo: _this.invoiceNo.join(",")
        });
        (0, _user.userPayInvoice)(params).then(function (res) {
          _this.$message.success("Pay success");
          _this.$emit("ok");
          _this.handleCancel();
        }).finally(function () {
          _this.loading = false;
        });
      });
    },
    handleCancel: function handleCancel() {
      this.visible = false;
      this.$refs.form.resetFields();
    }
  }
};
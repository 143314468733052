var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "Edit",
        "before-close": _vm.handleClose,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "order_box" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                _vm._l(_vm.columns, function (item) {
                  return _c(
                    "el-col",
                    {
                      key: item.prop,
                      attrs: { span: item.col ? item.col : _vm.col },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: item.label, prop: item.prop } },
                        [
                          item.type === "textarea"
                            ? [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    autosize: "",
                                    placeholder: "Please Enter",
                                  },
                                  model: {
                                    value: _vm.form[item.prop],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, item.prop, $$v)
                                    },
                                    expression: "form[item.prop]",
                                  },
                                }),
                              ]
                            : [
                                _c("el-input", {
                                  attrs: { placeholder: "Please Enter" },
                                  model: {
                                    value: _vm.form[item.prop],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, item.prop, $$v)
                                    },
                                    expression: "form[item.prop]",
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("form")
                },
              },
            },
            [_vm._v("Confirm")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        visible: _vm.visible,
        width: "800px",
        "before-close": _vm.close,
        title: "Vehicle detail",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.pram,
                rules: _vm.rules,
                "label-position": "top",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "delivery-box" }, [
                      _c("img", {
                        staticStyle: {
                          width: "44px",
                          height: "44px",
                          "border-radius": "50%",
                          margin: "0 auto",
                        },
                        attrs: { src: require("@/assets/imgs/car.svg") },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "text" }, [
                        _c("span", { staticClass: "car" }, [
                          _vm._v(
                            _vm._s(_vm.pram.carType == 1 ? "Truck" : "Mini Van")
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("Car model")]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text" }, [
                        _c("span", { staticClass: "car" }, [
                          _vm._v(_vm._s(_vm.pram.carNum)),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("License plate")]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text" }, [
                        _c("span", { staticClass: "order" }, [
                          _vm._v(_vm._s(_vm.pram.allNum) + " Items"),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("Total number of orders")]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _vm.pram.productList && _vm.pram.productList.length > 0
                        ? [
                            _c(
                              "el-table",
                              {
                                staticStyle: { "margin-top": "24px" },
                                attrs: {
                                  data: _vm.pram.productList,
                                  border: "",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Product name",
                                    prop: "productName",
                                    "min-width": "100",
                                    "show-overflow-tooltip": true,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Number",
                                    "min-width": "100",
                                    "show-overflow-tooltip": true,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c("span", [
                                              _vm._v(_vm._s(row.number) + "G"),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3973553323
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { productLstApi, productDeleteApi, categoryApi, putOnShellApi, storeAddproduct, offShellApi, productHeadersApi, productExportApi, restoreApi, productExcelApi, stockManager } from "@/api/store";
import { getToken } from "@/utils/auth";
import addShop from "./components/addShop";
import inventory from "./inventory";
import { checkPermi } from "@/utils/permission"; // 权限判断函数
import { mapGetters } from "vuex";
import { Debounce } from "@/utils/validate";
import productEdit from "./components/productEdit.vue";
export default {
  name: "ProductList",
  components: {
    inventory: inventory,
    addShop: addShop,
    productEdit: productEdit
  },
  data: function data() {
    return {
      props: {
        children: "child",
        label: "name",
        value: "id",
        emitPath: false
      },
      deliveryVisible: false,
      headeNum: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      form: {
        productId: "",
        number: ""
      },
      loading: false,
      rules: {
        number: [{
          required: true,
          message: "please enter  Quantity",
          trigger: ["blur", "change"]
        }],
        productId: [{
          required: true,
          message: "please choose product",
          trigger: ["blur", "change"]
        }]
      },
      tableFrom: {
        page: 1,
        limit: 20,
        cateId: "",
        keywords: ""
        // type: '1'
      },
      productId: "",
      categoryList: [],
      merCateList: [],
      shopList: [],
      objectUrl: process.env.VUE_APP_BASE_API,
      dialogVisible: false
    };
  },
  computed: _objectSpread({}, mapGetters(["storeId"])),
  mounted: function mounted() {
    // this.goodHeade()
    this.getList();
    this.getShopList();
    this.getCategorySelect();
    this.checkedCities = this.$cache.local.has("goods_stroge") ? this.$cache.local.getJSON("goods_stroge") : this.checkedCities;
  },
  methods: {
    handleAdd: function handleAdd() {
      this.$refs.modalForm.add();
    },
    handleEdit: function handleEdit(record) {
      this.$refs.modalForm.edit(record);
    },
    addShopShow: function addShopShow() {
      this.$refs["addShopForm"].open();
    },
    editShopShow: function editShopShow(id) {
      this.$refs["addShopForm"].open(id);
    },
    checkPermi: checkPermi,
    handleRestore: function handleRestore(id) {
      var _this = this;
      this.$modalSure("Return goods").then(function () {
        restoreApi(id).then(function (res) {
          _this.$message.success("successful");
          _this.goodHeade();
          _this.getList();
        });
      });
    },
    handlerSubmit: Debounce(function (form) {
      var _this2 = this;
      if (this.storeId) {
        this.form.storeId = this.storeId;
      }
      this.form.pm = 1;
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        stockManager(_this2.form).then(function (res) {
          _this2.getList();
          _this2.dialogVisible = false;
        });
      });
    }),
    handleRecord: function handleRecord(record) {
      this.productId = record.row.productId;
      this.deliveryVisible = true;
    },
    close: function close(form) {
      this.$refs[form].resetFields();
      this.dialogVisible = false;
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    handleCloseMod: function handleCloseMod(item) {
      this.dialogVisible = item;
      this.goodHeade();
      this.getList();
    },
    // 复制
    onCopy: function onCopy() {
      this.dialogVisible = true;
    },
    // 导出
    exports: function exports() {
      productExcelApi({
        cateId: this.tableFrom.cateId,
        keywords: this.tableFrom.keywords,
        type: this.tableFrom.type
      }).then(function (res) {
        window.location.href = res.fileName;
      });
    },
    // 获取商品表单头数量
    goodHeade: function goodHeade() {
      var _this3 = this;
      productHeadersApi().then(function (res) {
        _this3.headeNum = res;
      }).catch(function (res) {
        _this3.$message.error(res.message);
      });
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this4 = this;
      categoryApi({
        status: -1,
        type: 1
      }).then(function (res) {
        _this4.merCateList = res;
      }).catch(function (res) {
        _this4.$message.error(res.message);
      });
    },
    getShopList: function getShopList() {
      var _this5 = this;
      productLstApi({
        page: 1,
        limit: 99999
      }).then(function (res) {
        _this5.shopList = res.list;
      });
    },
    // 列表
    getList: function getList() {
      var _this6 = this;
      this.listLoading = true;
      productLstApi(this.tableFrom).then(function (res) {
        _this6.tableData.data = res.list;
        _this6.tableData.total = res.total;
        _this6.listLoading = false;
      }).catch(function (res) {
        _this6.listLoading = false;
        _this6.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, type) {
      var _this7 = this;
      var text = "Are you sure you want to delete the product";
      this.$modalSure(text).then(function () {
        var deleteFlag = "delete";
        productDeleteApi(id, deleteFlag).then(function () {
          _this7.$message.success("操作成功");
          _this7.getList();
          _this7.goodHeade();
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this8 = this;
      if (this.storeId) {
        storeAddproduct({
          productId: row.productId,
          storeId: this.storeId,
          type: row.isShow ? 0 : 1
        }).then(function () {
          _this8.getList();
        }).catch(function () {
          row.isShow = !row.isShow;
        });
      } else {
        row.isShow ? putOnShellApi(row.productId).then(function () {
          _this8.$message.success("Listed successfully");
          _this8.getList();
        }).catch(function () {
          row.isShow = !row.isShow;
        }) : offShellApi(row.productId).then(function () {
          _this8.$message.success("Removed successfully");
          _this8.getList();
        }).catch(function () {
          row.isShow = !row.isShow;
        });
      }
    }
  }
};
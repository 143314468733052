"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _vuex = require("vuex");
var _validate = require("@/utils/validate");
var _orderDetail = _interopRequireDefault(require("../../order/orderDetail"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    detailsFrom: _orderDetail.default
  },
  data: function data() {
    return {
      size: "",
      uid: null,
      optionList: [],
      orderVisible: false,
      dataSelected: null,
      pagination: {
        show: true,
        current: 1,
        size: 5,
        total: 0,
        layout: "prev, next"
      },
      period: null,
      loading: false,
      constants: this.$constants,
      pram: {
        storeId: null
      },
      rules: {
        amount: [{
          required: true,
          message: "refund amount",
          trigger: ["blur", "change"]
        }],
        refundReason: [{
          required: true,
          message: "refund notes",
          trigger: ["blur", "change"]
        }]
      },
      listData: [],
      payPrice: 999,
      addressData: [],
      addressId: null,
      address: {},
      storeList: [],
      listLoading: false,
      shopList: [],
      selectStore: "0",
      detail: {},
      orderId: null
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(["storeId"])),
  mounted: function mounted() {},
  methods: {
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (rowIndex % 2 == 0) {
        return "hilight-row";
      } else {
        return "";
      }
    },
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = "sum";
          return;
        }
        if (index === 3) {
          sums[index] = "";
          return;
        }
        var values = data.map(function (item) {
          return Number(item[column.property]);
        });
        if (!values.every(function (value) {
          return isNaN(value);
        })) {
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    getDetail: function getDetail(row) {
      var _this = this;
      this.detail = row;
      this.listLoading = true;
      (0, _user.userBillDetail)({
        billId: row.id
      }).then(function (res) {
        _this.listData = res;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    //查看订单详情
    onDetails: function onDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.getDetail(id);
    },
    Close: function Close() {
      this.orderVisible = false;
      this.detail = {};
      this.listData = [];
    },
    handlerSubmit: (0, _validate.Debounce)(function (row) {
      var _this2 = this;
      var that = this;
      this.$confirm("Are you sure to complete it?", "Complete the bill", {
        confirmButtonText: "confirm",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        (0, _user.finishBill)({
          billId: _this2.detail.id
        }).then(function (res) {
          that.$emit("init");
          that.Close();
        });
      });
    })
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    props: {
      type: Object,
      default: function _default() {
        return {
          label: 'label',
          value: 'value'
        };
      }
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: '搜索'
    },
    size: {
      type: String,
      default: 'small'
    },
    pagination: {
      type: Object,
      default: function _default() {
        return {
          show: true,
          current: 1,
          size: 10,
          total: 0,
          layout: 'prev, next'
        };
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      value: '',
      filterText: ''
    };
  },
  mounted: function mounted() {},
  methods: {
    // 初始化数据
    initData: function initData() {
      this.value = '';
      this.filterText = '';
    },
    // 切换页码
    handleCurrentChange: function handleCurrentChange() {
      this.$emit('getOptions');
    },
    // 切换选项
    handleChange: function handleChange() {
      this.$emit('selectValue', this.value);
    },
    // 筛选选项
    handleFilter: function handleFilter() {
      this.$emit('getOptions', this.filterText);
    }
  }
};
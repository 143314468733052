"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var subscribeApi = _interopRequireWildcard(require("@/api/subscribe.js"));
var _utils = require("@/utils");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "delivery",
  props: {},
  data: function data() {
    return {
      visible: false,
      loading: false,
      form: {},
      rules: {
        nextOrderDate: [{
          required: true,
          message: "Please choose",
          trigger: ["blur", "change"]
        }]
      },
      tmpIds: []
    };
  },
  mounted: function mounted() {},
  methods: {
    open: function open(item) {
      this.form = (0, _utils.deepClone)(item);
      console.log(this.form);
      this.visible = true;
      this.tmpIds = [item.tmpId];
    },
    batchEdit: function batchEdit(tmpIds) {
      this.visible = true;
      this.form = Object.assign({}, {
        nextOrderDate: null
      });
      this.tmpIds = tmpIds;
    },
    // 提交
    handlerSubmit: function handlerSubmit(name) {
      var _this = this;
      this.$refs[name].validate(function (valid) {
        if (!valid) return;
        _this.loading = true;
        var data = Object.assign({}, _this.form);
        var tmpIds = _this.tmpIds;
        var params = {
          nextOrderDate: data.nextOrderDate,
          tmpId: tmpIds.join(",")
        };
        subscribeApi.updateDate(params).then(function (res) {
          _this.$message.success("Update success");
          _this.visible = false;
          _this.$refs[name].resetFields();
          _this.$emit("ok");
          _this.loading = false;
        }).catch(function () {
          _this.loading = false;
        });
      });
    },
    handleClose: function handleClose() {
      this.handleCancel("form");
    },
    handleCancel: function handleCancel(name) {
      this.visible = false;
      this.$refs[name].resetFields();
    }
  }
};
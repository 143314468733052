"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.message = void 0;
var _elementUi = require("element-ui");
//resetMessage.js
/**重置message，防止重复点击重复弹出message消息提示 */

var messageInstance = null;
var resetMessage = function resetMessage(options) {
  if (messageInstance) {
    messageInstance.close();
  }
  _elementUi.Message.closeAll(); //手动关闭所有消息提示实例
  messageInstance = (0, _elementUi.Message)(options);
  // console.log(Message(options));
};
['error', 'success', 'info', 'warning'].forEach(function (type) {
  resetMessage[type] = function (options) {
    if (typeof options === 'string') {
      options = {
        message: options
      };
    }
    options.type = type;
    return resetMessage(options);
  };
});
var message = exports.message = resetMessage;
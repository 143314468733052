"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "EditableCell",
  props: {
    text: [String, Number],
    isNeedBorder: {
      type: Boolean,
      default: false
    },
    index: {
      type: [String, Number],
      default: 0
    },
    type: {
      type: [String, Number],
      default: "text"
    },
    inputType: {
      type: [String, Number],
      default: "text"
    },
    unit: {
      type: [String, Number],
      default: ""
    },
    color: {
      type: String,
      default: "rgba(0,0,0,.6)"
    }
  },
  data: function data() {
    return {
      value: "",
      editable: false,
      isFocus: false
    };
  },
  computed: {
    label: {
      get: function get() {
        return typeof this.value === "number" ? this.value.toString() : this.value;
      },
      set: function set(val) {
        this.value = val;
      }
    },
    inputRef: function inputRef() {
      return this.inkey;
    }
  },
  watch: {
    text: {
      immediate: true,
      handler: function handler(val) {
        this.value = val;
      }
    }
  },
  methods: {
    getValue: function getValue() {
      return this.inputType === "password" ? "******" : (this.value ? this.value : "") + this.unit;
    },
    handleClick: function handleClick() {
      var _this = this;
      this.editable = true;
      if (this.type == "textarea") {
        this.$nextTick(function () {
          _this.$refs.textarea.$refs.textarea.focus();
        });
      } else {
        this.$nextTick(function () {
          _this.$refs.myInput.$refs.input.focus();
        });
      }
    },
    handleChange: function handleChange(value) {
      this.value = value;
    },
    check: function check() {
      this.editable = false;
      if (this.text === this.value) return;
      this.$emit("change", this.value);
    },
    edit: function edit() {
      this.editable = true;
    },
    handleFocus: function handleFocus() {
      this.editable = true;
    }
  }
};
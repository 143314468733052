var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        visible: _vm.visible,
        title: "Email management",
        "before-close": _vm.handleClose,
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "order_box",
        },
        [
          _c("div", { staticClass: "address_title" }, [
            _vm._v("Account email"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "address_item flex-row-bet primary" }, [
            _c("div", [
              _c("div", { staticClass: "address_user" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.userInfo.account) + "\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex-row-bet",
              staticStyle: { "margin-top": "15px" },
            },
            [
              _c("div", { staticClass: "address_title" }, [
                _vm._v("Service email"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleAddEmail },
                },
                [_vm._v("Add email")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.emailList, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "address_item flex-row-bet" },
              [
                _c("div", [
                  _vm.editIndex == index
                    ? _c(
                        "div",
                        { staticClass: "address_user" },
                        [
                          _c("el-input", {
                            ref: "myInput",
                            refInFor: true,
                            model: {
                              value: item.account,
                              callback: function ($$v) {
                                _vm.$set(item, "account", $$v)
                              },
                              expression: "item.account",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "address_user" }, [
                        _vm._v(
                          "\n          " + _vm._s(item.account) + "\n        "
                        ),
                      ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _vm.editIndex == index
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEditEmail(index)
                              },
                            },
                          },
                          [_vm._v("Confirm")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSelectEmail(index)
                              },
                            },
                          },
                          [_vm._v("Edit")]
                        ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDeleteEmail(index)
                          },
                        },
                      },
                      [_vm._v("Delete")]
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
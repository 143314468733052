function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as driverApi from "@/api/driver.js";
import * as systemMerchantApi from "@/api/merchant.js";
import { mapGetters } from "vuex";
import { Debounce } from "@/utils/validate";
import { deepClone } from "@/utils";
// 邮箱
var email = function email(rule, value, callback) {
  var result = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  if (!result.test(value)) {
    callback(new Error("Please enter the correct email address!"));
  } else {
    callback();
  }
};
var pwd = function pwd(rule, value, callback) {
  if (value && value.length < 6) {
    callback(new Error("Please enter at least 6 digits in English!"));
  } else {
    callback();
  }
};
var defaultObj = {
  storeId: null,
  account: null,
  firstName: null,
  lastName: null,
  phone: null
};
export default {
  // name: "edit"
  components: {},
  props: {},
  data: function data() {
    return {
      constants: this.$constants,
      dialogVisible: false,
      pram: deepClone(defaultObj),
      listData: [],
      rules: {
        storeId: [{
          required: true,
          message: "please choose plant",
          trigger: ["blur"]
        }],
        account: [{
          required: true,
          message: "please enter the correct email address",
          trigger: ["blur", "change"],
          validator: email
        }],
        firstName: [{
          required: true,
          message: "please enter First Name",
          trigger: ["blur", "change"]
        }],
        lastName: [{
          required: true,
          message: "please enter Last Name",
          trigger: ["blur", "change"]
        }],
        phone: [{
          required: true,
          message: "please enter phone number",
          trigger: ["blur", "change"]
        }],
        pwd: [{
          required: true,
          message: "please enter password",
          trigger: ["blur", "change"],
          validator: pwd
        }]
      },
      title: "Add driver",
      isCreate: true,
      loading: false
    };
  },
  computed: _objectSpread({}, mapGetters(["storeId"])),
  //   mounted() {
  //     this.initEditData();
  //     this.getPlantList();
  //   },
  methods: {
    add: function add() {
      this.title = "Add driver";
      this.dialogVisible = true;
      this.isCreate = true;
      this.pram.storeId = this.storeId;
      // this.pram = Object.assign({}, defaultObj, { storeId: this.storeId });
      this.getPlantList();
    },
    edit: function edit(record) {
      this.title = "Edit driver";
      this.dialogVisible = true;
      this.isCreate = false;
      this.getPlantList(record);
    },
    getPlantList: function getPlantList(record) {
      var _this = this;
      this.loading = true;
      systemMerchantApi.merchantList(this.listPram).then(function (data) {
        _this.listData = data.list;
        if (record) {
          _this.pram = Object.assign({}, record, {
            id: record.uid
          });
        }
      }).finally(function () {
        _this.loading = false;
      });
    },
    close: function close() {
      this.$refs.pram.resetFields();
      this.dialogVisible = false;
    },
    initEditData: function initEditData() {
      this.pram.storeId = this.storeId;
      if (this.isCreate !== 1) return;
      var _this$editData = this.editData,
        account = _this$editData.account,
        firstName = _this$editData.firstName,
        lastName = _this$editData.lastName,
        phone = _this$editData.phone,
        storeId = _this$editData.storeId;
      this.pram.account = account;
      this.pram.firstName = firstName;
      this.pram.lastName = lastName;
      this.pram.phone = phone;
      this.pram.storeId = storeId;
    },
    handlerSubmit: Debounce(function (form) {
      var _this2 = this;
      if (this.storeId) {
        this.pram.storeId = this.storeId;
      }
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        if (_this2.isCreate) {
          _this2.handlerSave();
        } else {
          _this2.handlerEdit();
        }
      });
    }),
    handlerSave: function handlerSave() {
      var _this3 = this;
      driverApi.addDriver(this.pram).then(function (data) {
        _this3.$message.success("Add successfully");
        _this3.close();
        _this3.$emit("hideEditDialog");
      });
    },
    handlerEdit: function handlerEdit() {
      var _this4 = this;
      driverApi.updateDriver(this.pram).then(function (data) {
        _this4.$message.success("Update successfully");
        _this4.close();
        _this4.$emit("hideEditDialog");
      });
    }
  }
};
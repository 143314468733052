"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "WriteOff",
  props: {
    iShidden: {
      type: Boolean,
      default: true
    },
    orderInfo: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {};
  },
  created: function created() {
    Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/assets/js/media_750'));
    });
  },
  methods: {
    toDetail: function toDetail(item) {
      this.$router.push({
        path: "/javaMobile/orderDetail/" + item.orderId + "/looks"
      });
    },
    cancel: function cancel() {
      this.$emit("cancel", true);
    },
    confirm: function confirm() {
      this.$emit("confirm", true);
    }
  }
};
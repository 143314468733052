"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var deviceApi = _interopRequireWildcard(require("@/api/device.js"));
var _userList = _interopRequireDefault(require("../components/userList"));
var _index = _interopRequireDefault(require("@/components/cards/index"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  // name: "index"
  components: {
    cardsData: _index.default,
    userList: _userList.default
  },
  data: function data() {
    return {
      constants: this.$constants,
      listData: {
        list: []
      },
      userVisible: false,
      listPram: {
        page: 1,
        limit: this.$constants.page.limit[0],
        name: null,
        deviceNum: null,
        description: null,
        status: null,
        createTime: null,
        updateTime: null
      },
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        // 0=创建，1=编辑
        editData: {}
      }
    };
  },
  mounted: function mounted() {
    this.handleList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    handleOpenDel: function handleOpenDel(rowData) {
      var _this = this;
      this.$confirm("确认删除当前设备").then(function () {
        deviceApi.delDevice(rowData).then(function (data) {
          _this.$message.success("删除设备成功");
          _this.handleList();
        });
      });
    },
    close: function close() {
      this.userVisible = false;
      this.handleList();
    },
    handleBind: function handleBind(rowData) {
      var _this2 = this;
      this.userVisible = true;
      this.$nextTick(function () {
        _this2.$refs.userList.edit(rowData);
      });
    },
    handleOpenEnable: function handleOpenEnable(rowData) {
      var _this3 = this;
      this.$confirm("确认启用当前设备").then(function () {
        deviceApi.enableDevice(rowData).then(function (data) {
          _this3.$message.success("启用设备成功");
          _this3.handleList();
        });
      });
    },
    handleOpenDisable: function handleOpenDisable(rowData) {
      var _this4 = this;
      this.$confirm("确认禁用当前设备").then(function () {
        deviceApi.disableDevice(rowData).then(function (data) {
          _this4.$message.success("禁用设备成功");
          _this4.handleList();
        });
      });
    },
    handleList: function handleList() {
      var _this5 = this;
      deviceApi.deviceGroupList(this.listPram).then(function (data) {
        _this5.listData = data;
      });
    },
    handleOpenEdit: function handleOpenEdit(isCreate, editDate) {
      isCreate === 1 ? this.editDialogConfig.editData = editDate : this.editDialogConfig.editData = {};
      this.editDialogConfig.isCreate = isCreate;
      this.editDialogConfig.visible = true;
    },
    hideEditDialog: function hideEditDialog() {
      this.editDialogConfig.visible = false;
      this.handleList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handleList(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handleList(this.listPram);
    },
    resetQuery: function resetQuery() {
      this.listPram.keywords = "";
      this.handleList();
    }
  }
};
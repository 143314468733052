//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import orderDetails from "@/views/order/orderDetails.vue";
import { getUnreadTotal as _getUnreadTotal, getNotifications } from "@/api/user";
import bus from "@/utils/bus.js";
export default {
  components: {
    orderDetails: orderDetails
  },
  data: function data() {
    return {
      notificationList: [],
      visible: false,
      total: 0,
      orderId: null,
      loading: false,
      unreadNum: 0
    };
  },
  mounted: function mounted() {
    this.getUnreadTotal();
    var _this = this;
    setInterval(function () {
      _this.getUnreadTotal();
    }, 1000 * 60);
    bus.$on("update-message", function () {
      _this.getUnreadTotal();
    });
  },
  methods: {
    getUnreadTotal: function getUnreadTotal() {
      var _this2 = this;
      _getUnreadTotal().then(function (res) {
        _this2.unreadNum = res;
      });
    },
    onDetails: function onDetails(id) {
      this.visible = false;
      this.orderId = id;
      this.$refs.orderDetail.show(id);
    },
    getMessageList: function getMessageList() {
      var _this3 = this;
      var params = {
        page: 1,
        limit: 10
      };
      this.loading = true;
      getNotifications(params).then(function (res) {
        _this3.notificationList = res.list;
        _this3.total = res.total;
      }).finally(function () {
        _this3.loading = false;
      });
    },
    handleMore: function handleMore() {
      this.visible = false;
      this.$router.push({
        path: "/user/notice"
      });
    }
  }
};
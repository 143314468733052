"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var subscribeApi = _interopRequireWildcard(require("@/api/subscribe.js"));
var _utils = require("@/utils");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "delivery",
  props: {},
  data: function data() {
    return {
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      visible: false,
      loading: false,
      form: {},
      rules: {
        nextOrderDate: [{
          required: true,
          message: "Please choose",
          trigger: ["blur", "change"]
        }]
      },
      tmpIds: []
    };
  },
  mounted: function mounted() {},
  methods: {
    open: function open(item) {
      var tmpProductList = item.tmpProductList;
      this.form = Object.assign({}, {
        productInfo: tmpProductList,
        tmpId: tmpProductList[0].tmpId
      });
      this.visible = true;
    },
    // 提交
    handlerSubmit: function handlerSubmit(name) {
      var _this = this;
      this.$refs[name].validate(function (valid) {
        if (!valid) return;
        _this.loading = true;
        var data = Object.assign({}, _this.form);
        var params = _objectSpread(_objectSpread({}, data), {}, {
          productInfo: typeof data.productInfo === "string" ? data.productInfo : JSON.stringify(data.productInfo)
        });
        subscribeApi.editChildTmp(params).then(function (res) {
          _this.$message.success("Update success");
          _this.visible = false;
          _this.$refs[name].resetFields();
          _this.$emit("ok");
          _this.loading = false;
        }).catch(function () {
          _this.loading = false;
        });
      });
    },
    handleClose: function handleClose() {
      this.handleCancel("form");
    },
    handleCancel: function handleCancel(name) {
      this.visible = false;
      this.$refs[name].resetFields();
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _vuex = require("vuex");
var _validate = require("@/utils/validate");
var _invoiceDetail = _interopRequireDefault(require("./invoiceDetail"));
var _orderDetail = _interopRequireDefault(require("@/views/order/orderDetail"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    invoiceDetail: _invoiceDetail.default,
    orderDetail: _orderDetail.default
  },
  data: function data() {
    return {
      size: "",
      uid: null,
      optionList: [],
      orderVisible: false,
      dataSelected: null,
      pagination: {
        show: true,
        current: 1,
        size: 5,
        total: 0,
        layout: "prev, next",
        limit: 10,
        page: 1
      },
      period: null,
      loading: false,
      constants: this.$constants,
      pram: {
        storeId: null
      },
      rules: {
        amount: [{
          required: true,
          message: "refund amount",
          trigger: ["blur", "change"]
        }],
        refundReason: [{
          required: true,
          message: "refund notes",
          trigger: ["blur", "change"]
        }]
      },
      orderId: null,
      listData: [],
      payPrice: 999,
      addressData: [],
      addressId: null,
      address: {},
      storeList: [],
      shopList: [],
      listLoading: false,
      selectStore: "0",
      detail: {}
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(["storeId"])),
  mounted: function mounted() {},
  methods: {
    // 查询订单详情
    handlePreview: function handlePreview(orderId) {
      this.orderId = orderId;
      this.$refs.orderDetail.getDetail(orderId);
    },
    handlerDetail: function handlerDetail(row) {
      this.$refs.invoiceModal.getDetail(row);
      this.$refs.invoiceModal.orderVisible = true;
    },
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = "sum";
          return;
        }
        if (index === 3) {
          sums[index] = "";
          return;
        }
        var values = data.map(function (item) {
          return Number(item[column.property]);
        });
        if (!values.every(function (value) {
          return isNaN(value);
        })) {
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    getUserInvoice: function getUserInvoice(row) {
      this.detail = row;
      var that = this;
      that.listLoading = true;
      var _this$pagination = this.pagination,
        page = _this$pagination.page,
        limit = _this$pagination.limit;
      (0, _user.userBillPageList)({
        page: page,
        limit: limit,
        uid: row.uid
      }).then(function (res) {
        that.listData = res.list;
        that.pagination.total = res.total;
        that.listLoading = false;
      }).catch(function () {
        that.listLoading = false;
      });
    },
    init: function init(e) {
      this.getUserInvoice(this.detail);
    },
    Close: function Close() {
      this.orderVisible = false;
      this.detail = {};
      this.listData = [];
    },
    handlerSubmit: (0, _validate.Debounce)(function (row) {
      var _this = this;
      var that = this;
      this.$confirm("Are you sure to complete it?", "Complete the bill", {
        confirmButtonText: "confirm",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        (0, _user.finishBill)({
          billId: row.id
        }).then(function (res) {
          that.getUserInvoice(_this.detail);
          that.$emit("handlerSubmit");
        });
      });
    }),
    pageChange: function pageChange(page) {
      this.pagination.page = page;
      this.getUserInvoice(this.detail);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pagination.limit = val;
      this.getUserInvoice(this.detail);
    }
  }
};
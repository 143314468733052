var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "order-page",
          attrs: {
            title: "Invoice details",
            "append-to-body": "",
            "modal-append-to-body": false,
            visible: _vm.orderVisible,
            width: "1000px",
            "before-close": _vm.Close,
          },
          on: {
            "update:visible": function ($event) {
              _vm.orderVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "description-title" }, [
            _vm._v("Invoice Information"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "description" }, [
            _c("div", { staticClass: "description-term" }, [
              _c("span", [_vm._v("Invoice date :")]),
              _c("span", [_vm._v(_vm._s(_vm.detail.createTime))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "description-term" }, [
              _c("span", [_vm._v("Invoice number :")]),
              _c("span", [_vm._v(_vm._s(_vm.detail.id))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "description-term" }, [
              _c("span", [_vm._v("Invoice amount :")]),
              _c("span", [_vm._v("$" + _vm._s(_vm.detail.totalPrice))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "description-term" }, [
              _c("span", [_vm._v("unpaid :")]),
              _c("span", [
                _vm._v(
                  "$" +
                    _vm._s(
                      _vm.detail.unpaid
                        ? _vm.detail.unpaid
                        : _vm.detail.totalPrice
                    )
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "description-title" }, [
            _vm._v("Related orders"),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "pram",
              attrs: { model: _vm.pram, rules: _vm.rules },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.listData,
                    "row-class-name": _vm.tableRowClassName,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "orderId", label: "order#", width: "260px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("span", [_vm._v(_vm._s(row.orderId))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "createTime", label: "order date" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "deliveryDate", label: "delivery date" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "payPrice", label: "payPrice" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "operation", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                staticClass: "primary-border",
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetails(row.orderId)
                                  },
                                },
                              },
                              [_vm._v("Order details")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("details-from", {
        ref: "orderDetail",
        attrs: { orderId: _vm.orderId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "userFrom",
                      attrs: {
                        inline: "",
                        size: "small",
                        model: _vm.userFrom,
                        "label-position": "right",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: { placeholder: "", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.userSearchs($event)
                              },
                            },
                            model: {
                              value: _vm.userFrom.keywords,
                              callback: function ($$v) {
                                _vm.$set(_vm.userFrom, "keywords", $$v)
                              },
                              expression: "userFrom.keywords",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "260px" },
                              attrs: {
                                placeholder: "Please Choose type",
                                clearable: "",
                              },
                              on: { change: _vm.userSearchs },
                              model: {
                                value: _vm.userFrom.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.userFrom, "type", $$v)
                                },
                                expression: "userFrom.type",
                              },
                            },
                            [
                              _c("el-option", { attrs: { value: "card" } }, [
                                _vm._v("card"),
                              ]),
                              _vm._v(" "),
                              _c("el-option", { attrs: { value: "invoice" } }, [
                                _vm._v("invoice"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "ios-search",
                                label: "default",
                                size: "small",
                              },
                              on: { click: _vm.userSearchs },
                            },
                            [_vm._v("search")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.reset("userFrom")
                                },
                              },
                            },
                            [_vm._v("reset")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                border: "",
                "header-cell-style": {
                  fontWeight: "bold",
                  background: "#f8f8f9",
                  color: "#515a6e",
                  height: "40px",
                },
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.onSelectTab },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Account number", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: { color: "#1890ff" },
                            on: {
                              click: function ($event) {
                                return _vm.handleArchives(scope.row.uid)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("filterEmpty")(scope.row.account))
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Name", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleArchives(scope.row.uid)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.firstName) +
                                _vm._s(scope.row.lastName)
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "isTradition",
                  label: "type",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass:
                              "ant-badge ant-badge-status ant-badge-not-a-wrapper",
                          },
                          [
                            scope.row.isTradition
                              ? _c("span", [
                                  _c("span", {
                                    staticClass:
                                      "ant-badge-status-dot ant-badge-status-processing",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "ant-badge-status-text" },
                                    [_vm._v("invoice")]
                                  ),
                                ])
                              : _c("span", [
                                  _c("span", {
                                    staticClass:
                                      "ant-badge-status-dot ant-badge-status-success",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "ant-badge-status-text" },
                                    [_vm._v("card")]
                                  ),
                                ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "Set up date and time",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "lineCredit",
                  label: "balance",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass:
                              "ant-badge ant-badge-status ant-badge-not-a-wrapper",
                          },
                          [
                            scope.row.isTradition
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.lineCredit) +
                                      "\n            "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v("\n              --\n            "),
                                ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "all buckets", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.allBuckets))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "empty buckets", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterEmpty")(scope.row.emptyBuckets)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "phone number", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.phone))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "next orderDate", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterEmpty")(scope.row.nextOrderDate)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "skip periods", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.skipPeriods))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "operation",
                  "min-width": "220",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleArchives(scope.row.uid)
                              },
                            },
                          },
                          [_vm._v("User profile")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [15, 30, 45, 60],
                  "page-size": _vm.userFrom.limit,
                  "current-page": _vm.userFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改推广人",
            visible: _vm.extensionVisible,
            width: "500px",
            "before-close": _vm.handleCloseExtension,
          },
          on: {
            "update:visible": function ($event) {
              _vm.extensionVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "formExtension",
              staticClass: "formExtension mt20",
              attrs: {
                model: _vm.formExtension,
                rules: _vm.ruleInline,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户头像：", prop: "image" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: { click: _vm.modalPicTap },
                    },
                    [
                      _vm.formExtension.image
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: { src: _vm.formExtension.image },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubExtension("formExtension")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户列表",
            visible: _vm.userVisible,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
          },
        },
        [
          _vm.userVisible
            ? _c("user-list", { on: { getTemplateRow: _vm.getTemplateRow } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [_vm._v("cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [_vm._v("confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置",
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "dynamicValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.dynamicValidateForm, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "groupId",
                    label: "user label",
                    rules: [
                      {
                        required: true,
                        message: "user label",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { placeholder: "label", filterable: "" },
                      model: {
                        value: _vm.dynamicValidateForm.groupId,
                        callback: function ($$v) {
                          _vm.$set(_vm.dynamicValidateForm, "groupId", $$v)
                        },
                        expression: "dynamicValidateForm.groupId",
                      },
                    },
                    _vm._l(_vm.labelLists, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { value: item.id, label: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("cancel"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("dynamicValidateForm")
                    },
                  },
                },
                [_vm._v("confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "edit", visible: _vm.visible, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm.visible
            ? _c("userEdit", {
                attrs: { uid: _vm.uid },
                on: { resetForm: _vm.resetForm },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "积分余额",
            visible: _vm.VisiblePoint,
            width: "500px",
            "close-on-click-modal": false,
            "before-close": _vm.handlePointClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.VisiblePoint = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingPoint,
                  expression: "loadingPoint",
                },
              ],
              ref: "PointValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.PointValidateForm, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "修改余额", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.PointValidateForm.moneyType,
                        callback: function ($$v) {
                          _vm.$set(_vm.PointValidateForm, "moneyType", $$v)
                        },
                        expression: "PointValidateForm.moneyType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("增加")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("减少")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "余额", required: "" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      type: "text",
                      precision: 2,
                      step: 0.1,
                      min: 0,
                      max: 999999,
                    },
                    model: {
                      value: _vm.PointValidateForm.moneyValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.PointValidateForm, "moneyValue", $$v)
                      },
                      expression: "PointValidateForm.moneyValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "修改积分", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.PointValidateForm.integralType,
                        callback: function ($$v) {
                          _vm.$set(_vm.PointValidateForm, "integralType", $$v)
                        },
                        expression: "PointValidateForm.integralType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("增加")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("减少")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "积分", required: "" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      type: "text",
                      "step-strictly": "",
                      min: 0,
                      max: 999999,
                    },
                    model: {
                      value: _vm.PointValidateForm.integralValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.PointValidateForm, "integralValue", $$v)
                      },
                      expression: "PointValidateForm.integralValue",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handlePointClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingBtn },
                  on: {
                    click: function ($event) {
                      return _vm.submitPointForm("PointValidateForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.uid
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "user detail",
                visible: _vm.Visible,
                width: "1100px",
                "before-close": _vm.Close,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.Visible = $event
                },
              },
            },
            [
              _vm.Visible
                ? _c("user-details", {
                    ref: "userDetails",
                    attrs: { uid: _vm.uid },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置",
            visible: _vm.levelVisible,
            width: "600px",
            "before-close": _vm.Close,
          },
          on: {
            "update:visible": function ($event) {
              _vm.levelVisible = $event
            },
          },
        },
        [
          _c("level-edit", {
            attrs: { levelInfo: _vm.levelInfo, levelList: _vm.levelList },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("invoice", {
        ref: "invoiceModal",
        attrs: { uid: _vm.uid },
        on: {
          handlerSubmit: function ($event) {
            return _vm.getList()
          },
        },
      }),
      _vm._v(" "),
      _c("order-edit", {
        ref: "orderNew",
        attrs: { uid: _vm.uid },
        on: {
          handlerSubmit: function ($event) {
            return _vm.getList()
          },
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.upload.title,
            visible: _vm.upload.open,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.upload, "open", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              attrs: {
                limit: 1,
                accept: ".xlsx, .xls",
                headers: _vm.headers,
                action: _vm.action,
                disabled: _vm.upload.isUploading,
                "on-progress": _vm.handleFileUploadProgress,
                "on-success": _vm.handleFileSuccess,
                drag: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _vm._v(" "),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("\n        Drop file here or\n        "),
                _c("em", [_vm._v("click to upload")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("a"),
                  _vm._v(" "),
                  _c(
                    "el-link",
                    {
                      attrs: {
                        href: _vm.tempKey,
                        underline: false,
                        target: "_blank",
                      },
                    },
                    [
                      _c("el-button", { attrs: { type: "text" } }, [
                        _vm._v("Download template"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  staticStyle: { color: "red" },
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(
                    "\n        xlsx/xls files with a size less than 500kb\n      "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.upload.open = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
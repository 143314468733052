function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FullCalendar from "@fullcalendar/vue";
import Reordering from "./components/Reordering";
import changeDeliveryModal from "./components/changeDeliveryModal";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import draggable from "vuedraggable";
import allocateVehicles from "./components/allocateVehicles.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import { dayList, monthList, dayDetail, preMonthList, orderWeekList } from "@/api/order";
import loadingVehicle from "./components/loadingVehicle.vue";
import loadingVehicleDetail from "./components/loadingVehicleDetail.vue";
import { format } from "async-validator/lib/util";
import { deepClone } from "@/utils";
import orderDetails from "./orderDetails.vue";
function getWeek(dateTime) {
  // 获取当前日期
  var currentDate = new Date(dateTime);
  // 获取本周的第一天
  var firstDayOfWeek = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay());

  // 获取本周的最后一天
  var lastDayOfWeek = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + (6 - currentDate.getDay()));
  return {
    firstDayOfWeek: moment(firstDayOfWeek).format("YYYY-MM-DD"),
    lastDayOfWeek: moment(lastDayOfWeek).format("YYYY-MM-DD")
  };
}
function getRecentSevenDay(formatDate) {
  var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var date = moment(formatDate).format("YYYY-MM-DDTHH:mm:ss.sssZ");
  var currentDate = date ? new Date(date) : new Date();
  var arr = [];
  var weekArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  for (var i = 0; i < 7; i++) {
    var thirtyDaysAgo = new Date(currentDate.getTime() + i * 24 * 60 * 60 * 1000);
    var year = thirtyDaysAgo.getFullYear();
    var month = thirtyDaysAgo.getMonth() + 1;
    var day = thirtyDaysAgo.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    var formattedDate = year + "-" + month + "-" + day;
    arr.push({
      name: formattedDate,
      weekDay: weekArray[i],
      key: i + 1,
      value: obj[formattedDate] ? obj[formattedDate] : 0
    });
  }
  return arr;
}
function getLastWeek(dateTime) {
  var currentDate = dateTime ? new Date(dateTime) : new Date();
  currentDate.setDate(currentDate.getDate() - 7 * 1);
  return currentDate;
}
function getNextWeek(dateTime) {
  var currentDate = dateTime ? new Date(dateTime) : new Date();
  currentDate.setDate(currentDate.getDate() + 7 * 1);
  return currentDate;
}
function getLastDay(dateTime) {
  var currentDate = dateTime ? new Date(dateTime) : new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  return currentDate;
}
function getNextDay(dateTime) {
  var currentDate = dateTime ? new Date(dateTime) : new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  return currentDate;
}
function getLastMonth(dateTime) {
  var currentDate = dateTime ? new Date(dateTime) : new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);
  return currentDate;
}
function getNextMonth(dateTime) {
  var currentDate = dateTime ? new Date(dateTime) : new Date();
  currentDate.setMonth(currentDate.getMonth() + 1);
  return currentDate;
}
var mySwiper;
export default {
  components: {
    FullCalendar: FullCalendar,
    changeDeliveryModal: changeDeliveryModal,
    Reordering: Reordering,
    loadingVehicle: loadingVehicle,
    loadingVehicleDetail: loadingVehicleDetail,
    orderDetails: orderDetails,
    draggable: draggable,
    allocateVehicles: allocateVehicles
  },
  data: function data() {
    return {
      orderId: 0,
      initialDate: new Date(),
      //选择日期/月份数据
      tabPosition: "dayGridDay",
      //切换月视图日视图类型
      loading: false,
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin // needed for dateClick
        ],
        firstDay: 1,
        headerToolbar: false,
        initialView: "dayGridDay",
        events: [],
        editable: true,
        droppable: true,
        eventClick: this.handleEventClick,
        header: false,
        timeZone: null
      },
      status: false,
      show: true,
      type: 2,
      dayListData: {},
      dayListPreData: {
        allNum: 0,
        list: []
      },
      eventList: [],
      weekList: [],
      dateFormat: "yyyy-MM-dd",
      currentDate: moment(new Date()).format("YYYY-MM-DD")
    };
  },
  watch: {
    initialDate: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        console.log(moment(val).format("YYYY-MM-DD HH:mm:ss"));
      }
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["storeId"])), {}, {
    dateType: function dateType() {
      var tabPosition = this.tabPosition;
      if (tabPosition === "dayGridDay") {
        this.dateFormat = "yyyy-MM-dd";
        return "date";
      } else if (tabPosition === "dayGridWeek") {
        this.dateFormat = "yyyy Week WW";
        return "week";
      } else if (tabPosition === "dayGridMonth") {
        this.dateFormat = "yyyy-MM";
        return "month";
      }
    }
  }),
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (row.status == 9) {
        return "error-row";
      }
    },
    handleDetail: function handleDetail(order) {
      if (order.orderId.indexOf("order") > -1) {
        this.onDetails(order.orderId);
      } else {
        this.handleArchives(order.uid);
      }
    },
    getList: function getList(list) {
      return list ? list.length > 0 ? list.slice(0, 4) : list : [];
    },
    getTimeZone: function getTimeZone() {
      var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      console.log(timezone);
    },
    // 获取数据
    loadData: function loadData(carId) {
      this.loading = true;
      if (this.tabPosition == "dayGridMonth") {
        this.holidayEvent();
      } else if (this.tabPosition == "dayGridWeek") {
        this.getWeekList();
      } else {
        this.getDayList(carId);
      }
    },
    getOrderStatus: function getOrderStatus(t) {
      var obj = {
        0: "pending",
        1: "pending",
        2: "on route",
        3: "complete",
        4: "complete",
        8: "canceled",
        9: "exception"
      };
      return obj[t];
    },
    //装载车辆
    handleLoadCar: function handleLoadCar(item, minLoad) {
      this.$refs.loadCar.open(item, minLoad);
    },
    //装载详情
    handleLoadCarDetail: function handleLoadCarDetail(carId) {
      this.$refs.loadCarDetail.open(carId);
    },
    // 订单详情
    onDetails: function onDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.show(id);
    },
    // 用户详情
    handleArchives: function handleArchives(uid) {
      if (!uid) return;
      this.$router.push({
        path: "/user/archives",
        query: {
          uid: uid
        }
      });
    },
    // 展开
    handleTapClick: function handleTapClick(index, childIndex, eclipse) {
      var weekList = deepClone(this.weekList);
      weekList[index].list[childIndex].eclipse = !eclipse;
      this.weekList = weekList;
    },
    getDetail: function getDetail(item) {
      var _this = this;
      this.getDayList();
      return;
      this.loading = true;
      dayDetail({
        driverId: item.driverId,
        date: moment(this.initialDate).format("YYYY-MM-DD"),
        carId: item.carId
      }).then(function (res) {
        _this.dayListData.driver.map(function (e) {
          if (e.carId == res.carId) {
            e = res;
          }
        });
        _this.loading = false;
      });
    },
    // 已配表格送背景色
    rowStyle: function rowStyle(_ref2) {
      var row = _ref2.row,
        rowIndex = _ref2.rowIndex;
      if (row.isChange && row.status !== 4 && row.status !== 9) {
        console.log(row.status);
        return {
          "background-color": "#67c23a40"
        };
      }
      if (row.status == 4) {
        return {
          "background-color": "#E8F4FF"
        };
      }
    },
    /** 获取订单周视图 */getWeekList: function getWeekList() {
      var _this2 = this;
      this.loading = true;
      var _getWeek = getWeek(this.initialDate),
        firstDayOfWeek = _getWeek.firstDayOfWeek,
        lastDayOfWeek = _getWeek.lastDayOfWeek;
      var weekList = getRecentSevenDay(firstDayOfWeek);
      var params = {
        date: firstDayOfWeek + "," + lastDayOfWeek
      };
      orderWeekList(params).then(function (res) {
        var length = 0;
        for (var key in res) {
          length += 1;
        }
        if (length == 0) {
          _this2.weekList = weekList;
          return;
        }
        _this2.weekList = weekList.map(function (item) {
          var preOrderList = res[item.key].preOrder ? [{
            allNum: res[item.key].preOrder ? res[item.key].preOrder.allNum : 0,
            type: "pre-order",
            orderList: res[item.key].preOrder ? res[item.key].preOrder.userNameList.map(function (child) {
              return {
                orderId: item.name,
                eclipse: false,
                realName: child.firstName + " " + child.lastName,
                uid: child.uid
              };
            }) : []
          }] : [];
          var list = res[item.key].today ? res[item.key].today.map(function (item) {
            return _objectSpread({
              eclipse: false,
              type: "order",
              orderList: item.orderList.sort(function (a, b) {
                return a.status - b.status;
              })
            }, item);
          }) : [];
          return _objectSpread(_objectSpread({}, item), {}, {
            isNeedEclipse: false,
            eclipse: false,
            preOrder: res[item.key].preOrder ? res[item.key].preOrder : {},
            list: list.concat(preOrderList)
          });
        });
        _this2.$nextTick(function () {
          var carList = document.getElementsByClassName("car-item-center-content");
          var array = [];
          for (var i = 0; i < carList.length; i++) {
            var clientHeight = carList[i].clientHeight;
            if (clientHeight > 140) {
              var className = carList[i].className;
              var newClassName = className.substring(24, carList[i].className.length);
              array.push(newClassName);
            }
          }
          _this2.weekList = _this2.weekList.map(function (item) {
            item.list.map(function (child) {
              var id = child.orderList ? child.orderList[0].orderId : "";
              if (array.indexOf(id) > -1) {
                child.isNeedEclipse = true;
              }
              return child;
            });
            if (item.preOrder) {
              if (array.indexOf(item.name) > -1) {
                item.isNeedEclipse = true;
              }
            }
            return item;
          });
        });
      }).finally(function () {
        _this2.loading = false;
      });
    },
    /** 获取订单月视图 */holidayEvent: function holidayEvent() {
      var _this3 = this;
      var fullCalendarApi = this.$refs.fullCalendar.getApi();
      // 是需要先清除 当前显示的各个eventSource(事件源) 就是事件(日程)
      fullCalendarApi.view.calendar.getEvents().forEach(function (eventSource) {
        eventSource.remove();
      });
      var eventList = [];
      var params = {
        date: moment(this.initialDate).format("YYYY-MM")
      };
      monthList(params).then(function (res) {
        _this3.loading = false;
        var preOrderData = [];
        var monthData = [];
        for (var key in res) {
          monthData.push({
            date: key,
            all: res[key].all,
            preOrder: res[key].preOrder
          });
          // if (res[key].all && res[key].all > 0) {
          //   monthData.push({
          //     date: key,
          //     all: res[key].all,
          //     preOrder: res[key].preOrder
          //   });
          // } else if (res[key].preOrder && res[key].preOrder > 0) {
          //   preOrderData.push({
          //     date: key,
          //     all: res[key].all,
          //     preOrder: res[key].preOrder
          //   });
          // }
        }
        // let subscribeData = this.subscribeEvent(preOrderData);
        if (monthData.length > 0) {
          var _eventList = monthData.map(function (item, index) {
            return {
              date: item.date,
              start: item.date,
              end: item.date,
              all: item.all,
              display: "background",
              carList: item.carList,
              preOrder: item.preOrder,
              backgroundColor: item.all ? index % 2 > 0 ? "#BED4FF" : "#fb9b43" : "#e9e9e9",
              url: "",
              iconBg: item.all ? index % 2 > 0 ? "linear-gradient(90.29deg, rgba(24, 144, 255, 0.6) 0.25%, rgba(24, 144, 255, 0.2) 95.39%)" : "linear-gradient(90.29deg, rgba(255, 255, 255, 0.6) 0.25%, rgba(255, 255, 255, 0.2) 95.39%)" : "#e9e9e9"
            };
          });
          var events = _eventList;
          fullCalendarApi.setOption("events", events);
        }
      });
    },
    getDayList: function getDayList(carId) {
      var _this4 = this;
      this.dayListData = {};
      var params = {
        date: moment(this.initialDate).format("YYYY-MM-DD")
      };
      dayList(params).then(function (res) {
        var dayListData = res.today;
        var currentIndex = 0; //滚动到指定位置
        var carList = dayListData.car.map(function (item, index) {
          if (carId == item.carId) {
            currentIndex = index;
          }
          var productNum = 0;
          var resetNum = 0;
          item.productList && item.productList.map(function (item) {
            productNum += item.number;
            resetNum += item.extraNum || 0;
          });
          item.productNum = productNum;
          item.restNum = resetNum;
          return item;
        });
        dayListData.driver.map(function (item, index) {
          if (carId == item.carId) {
            currentIndex = dayListData.car.length + index;
          }
        });
        _this4.dayListData = _objectSpread(_objectSpread({}, dayListData), {}, {
          car: carList
        });
        _this4.dayListPreData = res.preOrder ? {
          allNum: res.preOrder.allNum,
          list: [{
            userList: JSON.parse(res.preOrder.userList)
          }]
        } : {
          allNum: 0,
          list: []
        };
        _this4.loading = false;
        mySwiper = new Swiper(".mainSwiper", {
          // slidesPerView: 3,
          width: 780,
          //宽度不受swiper-slide影响,可以滚动到指定位置
          slidesPerView: "auto",
          spaceBetween: 20,
          freeMode: true,
          mousewheel: false,
          observer: true,
          observeParents: true,
          //解决this指向问题
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          }
          // breakpoints: {
          //   320: {
          //     //当屏幕宽度大于等于320
          //     slidesPerView: 1,
          //     spaceBetween: 0
          //   },
          //   768: {
          //     //当屏幕宽度大于等于768
          //     slidesPerView: 1,
          //     spaceBetween: 20
          //   },
          //   1080: {
          //     //当屏幕宽度大于等于1080
          //     slidesPerView: 1.3,
          //     spaceBetween: 20
          //   },
          //   1920: {
          //     //当屏幕宽度大于等于1920
          //     slidesPerView: 2,
          //     spaceBetween: 20
          //   }
          // }
        });
        //滚动到指定车辆
        setTimeout(function () {
          mySwiper.slideTo(currentIndex, 500);
        }, 100);
      }).catch(function () {
        _this4.loading = false;
      });
    },
    subscribeEvent: function subscribeEvent(data) {
      var eventList = data.map(function (item) {
        return {
          date: item.date,
          start: item.date,
          end: item.date,
          all: item.all,
          display: "background",
          carList: item.carList,
          preOrder: item.preOrder,
          backgroundColor: "#e9e9e9",
          isSubscribe: false,
          url: "",
          iconBg: "linear-gradient(90.29deg, rgba(255, 255, 255, 0.6) 0.25%, rgba(255, 255, 255, 0.2) 95.39%)"
        };
      });
      return eventList;
    },
    // 调整排序
    reorderingShow: function reorderingShow(item) {
      this.$refs["ReorderingForm"].open(item);
    },
    // 改期配送
    changeDate: function changeDate(item) {
      this.$refs["changeDeliveryForm"].open(item, "dateChange");
    },
    changeDelivery: function changeDelivery(item) {
      this.$refs["changeDeliveryForm"].open(item);
    },
    //异常订单分配车辆
    changeCar: function changeCar(item) {
      this.$refs["allocateVehicles"].open(item);
    },
    // 跳转方法
    goto: function goto(path, query) {
      if (query.date) {
        query.date = moment(query.date).format("YYYY-MM-DD");
      }
      if (path === "deliveryMap" && !query.carId) return;
      this.$router.push({
        path: path,
        query: query
      });
    },
    eventClick: function eventClick(data) {
      var startStr = data.startStr;
      this.tabPosition = "dayGridDay";
      var fullCalendarApi = this.$refs.fullCalendar.getApi();
      var newDate = moment(startStr).format("YYYY-MM-DDTHH:mm:ss.sssZ");
      this.initialDate = new Date(newDate);
      fullCalendarApi.gotoDate(this.initialDate);
      fullCalendarApi.changeView(this.tabPosition);
      this.loadData();
    },
    eventGoto: function eventGoto(data) {
      var url = data.url;
      if (url) {
        var path = url.split("?")[0];
        var nextOrderDate = url.split("nextOrderDate=")[1];
        this.$router.push({
          path: path,
          query: {
            nextOrderDate: nextOrderDate
          }
        });
        return;
      }
    },
    // 月视图有数据点击选中触发
    handleEventClick: function handleEventClick(e) {
      return;
      var _e$event = e.event,
        startStr = _e$event.startStr,
        url = _e$event.url;
      if (url) {
        var path = url.split("?")[0];
        var nextOrderDate = url.split("nextOrderDate=")[1];
        this.$router.push({
          path: path,
          query: {
            nextOrderDate: nextOrderDate
          }
        });
        return;
      }
      this.tabPosition = "dayGridDay";
      var fullCalendarApi = this.$refs.fullCalendar.getApi();
      this.initialDate = startStr;
      fullCalendarApi.gotoDate(this.initialDate);
      fullCalendarApi.changeView(this.tabPosition);
      this.loadData();
    },
    // 上个月/前一天
    prevMethod: function prevMethod() {
      var fullCalendarApi = this.$refs.fullCalendar.getApi();
      fullCalendarApi.prev();
      if (this.tabPosition == "dayGridMonth") {
        this.initialDate = getLastMonth(this.initialDate);
      } else if (this.tabPosition == "dayGridDay") {
        this.initialDate = getLastDay(this.initialDate);
      } else {
        this.initialDate = getLastWeek(this.initialDate);
      }
      this.loadData();
    },
    /** 下个月/后一天  */nextMethod: function nextMethod() {
      var fullCalendarApi = this.$refs.fullCalendar.getApi();
      fullCalendarApi.next();
      if (this.tabPosition == "dayGridMonth") {
        this.initialDate = getNextMonth(this.initialDate);
      } else if (this.tabPosition == "dayGridDay") {
        this.initialDate = getNextDay(this.initialDate);
      } else {
        this.initialDate = getNextWeek(this.initialDate);
      }
      this.loadData();
    },
    // 选择日期
    datePicker: function datePicker(e) {
      var fullCalendarApi = this.$refs.fullCalendar.getApi();
      fullCalendarApi.gotoDate(e);
      this.initialDate = new Date(e);
      this.loadData();
    },
    // 切换日/月视图
    tabPositionChange: function tabPositionChange(e) {
      var fullCalendarApi = this.$refs.fullCalendar.getApi();
      this.tabPosition = e;
      fullCalendarApi.changeView(this.tabPosition);
      // fullCalendarApi.gotoDate(new Date())
      // this.initialDate = new Date()
      this.loadData();
    },
    // 周试图点击切换到具体日期
    handleChangeViewDate: function handleChangeViewDate(e) {
      this.tabPosition = "dayGridDay";
      this.initialDate = new Date(e);
      this.loadData();
    },
    // 司机点击切换到具体日
    handleCarClick: function handleCarClick(e, carId) {
      this.tabPosition = "dayGridDay";
      this.initialDate = new Date(e);
      this.loadData(carId);
    }
  }
};
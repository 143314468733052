"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _googleMap = _interopRequireDefault(require("./googleMap.vue"));
var _utils = require("@/utils");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _readOnlyError(name) { throw new TypeError("\"" + name + "\" is read-only"); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var timer = null;
var _default = exports.default = {
  components: {
    GoogleMap: _googleMap.default
  },
  props: {
    uid: {
      type: [Number, String],
      default: 0
    }
  },
  data: function data() {
    return {
      form: {
        isDefault: false
      },
      type: "",
      loading: false,
      visible: false,
      rules: {
        spaceName: [{
          required: true,
          message: "Please enter",
          trigger: "blur"
        }],
        realName: [{
          required: true,
          message: "Please enter",
          trigger: "blur"
        }],
        phone: [{
          required: true,
          message: "Please enter",
          trigger: "blur"
        }],
        address: [{
          required: true,
          message: "Please enter",
          trigger: "click"
        }]
      },
      second: 60
    };
  },
  computed: {
    title: function title() {
      return this.form.id ? "Edit address" : "Add address";
    }
  },
  methods: {
    add: function add() {
      this.visible = true;
    },
    edit: function edit(record) {
      this.form = Object.assign({}, record, {
        spaceName: record.groupName
      });
      this.visible = true;
    },
    handleSend: function handleSend() {
      var phone = this.form.phone;
      var _this = this;
      setInterval(function () {
        _this.second = _this.second - 1;
        if (second == 0) {
          clearInterval(timer);
          _this.second = 60;
        }
      }, 1000), _readOnlyError("timer");
    },
    close: function close() {
      this.form = {};
      this.$refs.formRef.resetFields();
      this.visible = false;
    },
    handleUpdate: function handleUpdate() {
      var _this2 = this;
      this.$refs.formRef.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          _this2.form.detail = _this2.form.address;
          var formData = Object.assign({}, _this2.form, {
            uid: _this2.uid
          });
          (0, _user.userAddressEdit)(formData).then(function () {
            _this2.$message.success("Update successfully");
            _this2.close();
            _this2.loading = false;
            _this2.$emit("update");
          }).catch(function () {
            _this2.loading = false;
          });
        }
      });
    },
    modalPicTap: function modalPicTap(tit, image) {
      var _this = this;
      var attr = [];
      this.$modalUpload(function (img) {
        console.log(img);
        if (tit === "1") {
          _this.form[image] = img[0].sattDir;
          _this.form = Object.assign({}, _this.form, {
            img: img[0].sattDir
          });
          _this.$set(_this.form, "img", img[0].sattDir);
        }
        if (tit === "2") {
          img.map(function (item) {
            attr.push(item.attachment_src);
            _this.formValidate.slider_image.push(item);
          });
        }
      }, tit, "store");
    },
    googleShow: function googleShow() {
      this.$refs.googleRef.title = "Select address";
      this.$refs.googleRef.show(this.form);
    },
    googleUpdate: function googleUpdate(e) {
      console.log(e);
      this.form = Object.assign({}, this.form, _objectSpread({}, e));
    }
  }
};
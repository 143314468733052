var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "order-page",
          attrs: {
            title: "Check details",
            "append-to-body": "",
            "modal-append-to-body": false,
            visible: _vm.orderVisible,
            width: "1180px",
            "before-close": _vm.Close,
          },
          on: {
            "update:visible": function ($event) {
              _vm.orderVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "description-title" }, [
            _vm._v("Check information"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "description" }, [
            _vm.chequeInfo.paid
              ? _c("img", {
                  staticClass: "paid_png",
                  attrs: { src: require("@/assets/imgs/paid.svg") },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "description-term",
                  staticStyle: { margin: "0" },
                },
                [
                  _c("span", [_vm._v("payment date :")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatTime")(_vm.chequeInfo.payTime, "YY-MM-DD")
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "description-term" }, [
                _c("span", [_vm._v("Check number :")]),
                _c("span", [_vm._v(_vm._s(_vm.chequeInfo.chequeNo))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "description-term" }, [
                _c("span", [_vm._v("Check amount :")]),
                _c("span", [_vm._v("$" + _vm._s(_vm.chequeInfo.chequePrice))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "description-term" }, [
                _c("span", [_vm._v("Remark :")]),
                _c("span", [_vm._v(_vm._s(_vm.chequeInfo.remark))]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pay-status" }, [
              _vm.chequeInfo.balancePrice
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "description-term",
                        staticStyle: { "margin-top": "0" },
                      },
                      [
                        _c("span", [_vm._v("balance payment :")]),
                        _c("span", [
                          _vm._v("$" + _vm._s(_vm.chequeInfo.balancePrice)),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "description-title" }, [
            _vm._v("Related invoices"),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "pram",
              attrs: { model: _vm.pram, rules: _vm.rules },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.listData,
                    "header-cell-style": {
                      background: "#F5F5F5",
                      color: "#515a6e",
                      height: "40px",
                    },
                    border: "",
                    "span-method": _vm.arraySpanMethod,
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      selectable: _vm.selectable,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "id",
                      label: "Invoice details",
                      width: "300px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _c("div", [
                                  _vm._v(
                                    "Invoice number" + _vm._s(row.invoiceNo)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "Invoice date" +
                                      _vm._s(_vm._f("time")(row.invoiceTime))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "Invoice amount：" +
                                      _vm._s(row.invoicePrice)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "Amount paid:" + _vm._s(row.invoicePayPrice)
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderId",
                      label: "order #",
                      "min-width": "200px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("span", [_vm._v(_vm._s(row.orderId))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "createTime", label: "order date" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm._f("time")(row.createTime))),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "deliveryDate", label: "delivery date" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm._f("time")(row.deliveryDate))),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "payPrice", label: "payPrice" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "operation", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                staticClass: "primary-border",
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetails(row.orderId)
                                  },
                                },
                              },
                              [_vm._v("Oder details")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-top": "20px" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "end",
                    },
                  },
                  [
                    _vm.chequeInfo.status == 0
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerSubmit("pram")
                              },
                            },
                          },
                          [_vm._v("confirm")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", { staticStyle: { "font-size": "14px" } }, [
                _vm.multipleSelection.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "align-items": "center" },
                      },
                      [
                        _vm._v("\n          Cancelled"),
                        _c("span", { staticStyle: { color: "#1890ff" } }, [
                          _vm._v(_vm._s(_vm.multipleSelection.length)),
                        ]),
                        _vm._v("items, Refund:"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#1890ff",
                              "font-size": "20px",
                              display: "inline-block",
                              padding: "0 6px",
                            },
                          },
                          [_vm._v("$" + _vm._s(_vm.total))]
                        ),
                        _vm._v("Will be credited to user balance\n        "),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleUpdate },
                    },
                    [_vm._v("Confirm modification")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("details-from", {
        ref: "orderDetail",
        attrs: { orderId: _vm.orderId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
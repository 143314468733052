"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _utils = require("@/utils");
var userApi = _interopRequireWildcard(require("@/api/user.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      visible: false,
      uid: 0,
      emailList: [],
      address: {},
      loading: false,
      userInfo: {},
      editIndex: -1
    };
  },
  methods: {
    show: function show(info) {
      this.uid = info.uid;
      this.visible = true;
      this.getUserDetail();
    },
    getUserDetail: function getUserDetail() {
      var _this = this;
      this.loading = true;
      userApi.userInfoApi({
        id: this.uid
      }).then(function (res) {
        _this.userInfo = res;
        if (res.serviceEmail) {
          var array = res.serviceEmail.split(",");
          _this.emailList = array.map(function (item) {
            return {
              account: item
            };
          });
        }
      }).finally(function () {
        _this.loading = false;
      });
    },
    handleClose: function handleClose() {
      this.visible = false;
      this.editIndex = -1;
    },
    getUserEmail: function getUserEmail() {
      var _this2 = this;
      this.loading = true;
      userApi.addressList({
        uid: this.uid,
        page: 1,
        limit: 50
      }).then(function (res) {
        var emailList = (0, _utils.deepClone)(res.list) || [];
        _this2.emailList = emailList;
      }).finally(function () {
        _this2.loading = false;
      });
    },
    handleAddEmail: function handleAddEmail() {
      var emailList = (0, _utils.deepClone)(this.emailList) || [];
      emailList.push({
        account: ""
      });
      this.emailList = emailList;
      this.editIndex = emailList.length - 1;
    },
    handleDeleteEmail: function handleDeleteEmail(index) {
      var emailList = (0, _utils.deepClone)(this.emailList);
      emailList.splice(index, 1);
      this.emailList = emailList;
      this.handleCellChange();
    },
    handleCellChange: function handleCellChange() {
      var _this3 = this;
      var serviceEmail = this.emailList.map(function (item) {
        return item.account;
      }).join(",");
      if (serviceEmail == this.userInfo.serviceEmail) return;
      var params = _objectSpread(_objectSpread({}, this.userInfo), {}, {
        serviceEmail: serviceEmail,
        isPromoter: false
      });
      this.loading = true;
      userApi.userUpdateApi({
        id: this.userInfo.uid
      }, params).then(function (res) {
        _this3.$message.success("Update successfully");
      }).finally(function () {
        _this3.loading = false;
      });
    },
    handleEditEmail: function handleEditEmail(index) {
      this.editIndex = -1;
      this.handleCellChange();
    },
    handleSelectEmail: function handleSelectEmail(index) {
      this.editIndex = index;
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
var _orderDetails = _interopRequireDefault(require("@/views/order/orderDetails.vue"));
var _order = require("@/api/order");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "shopList",
  props: {
    driverId: {
      type: [Number, String],
      required: true
    }
  },
  components: {
    orderDetails: _orderDetails.default
  },
  data: function data() {
    return {
      props: {
        children: "child",
        label: "name",
        value: "id",
        emitPath: false
      },
      deliveryVisible: false,
      headeNum: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      form: {
        productId: "",
        number: ""
      },
      loading: false,
      tableFrom: {
        page: 1,
        limit: 10
        // type: '1'
      },
      productId: "",
      categoryList: [],
      merCateList: [],
      shopList: [],
      objectUrl: process.env.VUE_APP_BASE_API,
      dialogVisible: false,
      multipleSelection: [],
      orderId: null
    };
  },
  methods: {
    // 列表
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (row.status == 9) {
        return "error-row";
      } else {
        if (rowIndex % 2 == 0) {
          return "";
        } else {
          return "hilight-row";
        }
      }
    },
    getList: function getList(account) {
      var _this = this;
      if (account) {
        this.account = account;
      }
      this.listLoading = true;
      var params = _objectSpread(_objectSpread({}, this.tableFrom), {}, {
        account: this.account,
        type: 0
      });
      (0, _order.orderListApi)(params).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.$emit("change", res.total);
        _this.listLoading = false;
      }).finally(function (res) {
        _this.listLoading = false;
      });
    },
    onDetails: function onDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.show(id);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleBatchDelete: function handleBatchDelete() {
      var tmpIds = this.multipleOrderSelection.map(function (item) {
        return item.id;
      });
      var that = this;
      this.$confirm("Are you sure you want to delete the selected product?", "Tips", {
        confirmButtonText: "delete",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        that.$message.success("Delete successfully");
        that.getList();
      });
    },
    handleAdd: function handleAdd() {
      this.$refs.modalForm.add();
    },
    handleEdit: function handleEdit(record) {
      this.$refs.modalForm.edit(record);
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this2 = this;
      (0, _store.storeAddproduct)({
        productId: row.productId,
        driverId: this.driverId,
        type: row.isShow ? 0 : 1
      }).then(function () {
        _this2.getList();
      }).catch(function () {
        row.isShow = !row.isShow;
      });
    }
  }
};
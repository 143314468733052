var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "pram",
          attrs: { model: _vm.pram, rules: _vm.rules, "label-width": "100px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "order #", prop: "orderNo" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.pram.orderNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.pram, "orderNo", $$v)
                  },
                  expression: "pram.orderNo",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "remark" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", placeholder: "remark" },
                model: {
                  value: _vm.pram.disposeRemark,
                  callback: function ($$v) {
                    _vm.$set(_vm.pram, "disposeRemark", $$v)
                  },
                  expression: "pram.disposeRemark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlerSubmit("pram")
                    },
                  },
                },
                [_vm._v("confirm")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("cancel")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _vuex = require("vuex");
var _validate = require("@/utils/validate");
var _orderDetail = _interopRequireDefault(require("@/views/order/orderDetail"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    detailsFrom: _orderDetail.default
  },
  data: function data() {
    return {
      size: "",
      uid: null,
      optionList: [],
      orderVisible: false,
      dataSelected: null,
      period: null,
      loading: false,
      constants: this.$constants,
      DisableSelection: true,
      pram: {
        storeId: null
      },
      rules: {
        amount: [{
          required: true,
          message: "refund amount",
          trigger: ["blur", "change"]
        }],
        refundReason: [{
          required: true,
          message: "refund notes",
          trigger: ["blur", "change"]
        }]
      },
      listData: [],
      payPrice: 999,
      addressData: [],
      addressId: null,
      address: {},
      storeList: [],
      listLoading: false,
      shopList: [],
      selectStore: "0",
      detail: {},
      orderId: null,
      multipleSelection: [],
      total: 0,
      chequeInfo: {},
      chequeId: null
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(["storeId"])),
  mounted: function mounted() {},
  methods: {
    selectable: function selectable(row, index) {
      if (row.invoiceStatus == 1) {
        return true;
      } else {
        return true;
      }
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (rowIndex % 2 == 0) {
        return "hilight-row";
      } else {
        return "";
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      var totalPrice = val.length && val.reduce(function (total, record) {
        return total + (parseFloat(record.invoicePayPrice) || 0);
      }, 0);
      this.total = totalPrice;
    },
    show: function show(row) {
      this.orderVisible = true;
      this.chequeId = row.id;
      this.getDetail();
    },
    getDetail: function getDetail() {
      var _this = this;
      this.listLoading = true;
      (0, _user.userChequeInfo)({
        id: this.chequeId
      }).then(function (res) {
        _this.chequeInfo = res.cheque;
        var array = [];
        var disableLength = 0;
        res.invoiceList.map(function (item) {
          disableLength += item.status;
          item.orderList.map(function (child) {
            array.push(_objectSpread(_objectSpread({}, child), {}, {
              invoiceStatus: item.status,
              invoiceId: item.id,
              invoiceNo: item.invoiceNo,
              //发票编号
              invoiceTime: item.createTime,
              //发票日期
              invoicePrice: item.totalPrice,
              //Invoice amount
              invoicePayPrice: (parseFloat(item.totalPrice) - parseFloat(item.unpaid)).toFixed(2)
            }));
          });
        });
        // this.DisableSelection = disableLength ? false : true;
        _this.listData = array;
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    //修改支票
    handleUpdate: function handleUpdate() {
      var _this2 = this;
      var multipleSelection = this.multipleSelection;
      if (multipleSelection.length == 0) {
        this.$message.warning("至少选择一张发票");
        return;
      }
      var invoiceNo = multipleSelection.map(function (item) {
        return item.invoiceNo;
      });
      var chequeInfo = this.chequeInfo;
      var params = {
        id: chequeInfo.id,
        invoiceNo: invoiceNo.join(",")
      };
      this.listLoading = true;
      (0, _user.userUpdateCheque)(params).then(function (res) {
        // this.getDetail();
        _this2.$message.success("update success");
        _this2.orderVisible = false;
        _this2.$emit("ok");
      }).finally(function (e) {
        _this2.listLoading = false;
      });
    },
    //查看订单详情
    onDetails: function onDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.getDetail(id);
    },
    Close: function Close() {
      this.orderVisible = false;
      this.detail = {};
      this.listData = [];
    },
    arraySpanMethod: function arraySpanMethod(_ref2) {
      var row = _ref2.row,
        column = _ref2.column,
        rowIndex = _ref2.rowIndex,
        columnIndex = _ref2.columnIndex;
      if (columnIndex === 0) {
        var _row = this.getSpanArr(this.listData).one[rowIndex];
        var _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
      if (columnIndex === 1) {
        var _row2 = this.getSpanArr(this.listData).one[rowIndex];
        var _col2 = _row2 > 0 ? 1 : 0;
        return {
          rowspan: _row2,
          colspan: _col2
        };
      }
    },
    getSpanArr: function getSpanArr(arr) {
      if (arr) {
        var spanOneArr = [];
        var concatOne = 0;
        arr.forEach(function (item, index) {
          if (index === 0) {
            spanOneArr.push(1);
          } else {
            if (item.invoiceId === arr[index - 1].invoiceId) {
              // 第一列需合并相同内容的判断条件
              spanOneArr[concatOne] += 1;
              spanOneArr.push(0);
            } else {
              spanOneArr.push(1);
              concatOne = index;
            }
          }
        });
        return {
          one: spanOneArr
        };
      }
    },
    handlerSubmit: (0, _validate.Debounce)(function (row) {
      var _this3 = this;
      var that = this;
      this.$confirm("Are you sure to complete it?", "Complete the bill", {
        confirmButtonText: "confirm",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        (0, _user.finishBill)({
          billId: _this3.detail.id
        }).then(function (res) {
          that.$emit("init");
          that.Close();
        });
      });
    })
  }
};
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var deviceApi = _interopRequireWildcard(require("@/api/device.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "UserList",
  filters: {},
  data: function data() {
    return {
      templateRadio: 0,
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 10,
        keywords: ""
      },
      id: null
    };
  },
  mounted: function mounted() {
    // this.getList();
  },
  methods: {
    handleBind: function handleBind(rowData) {
      var _this = this;
      rowData.devId = this.id;
      this.$confirm("确认绑定用户").then(function () {
        deviceApi.bindUser(rowData).then(function (data) {
          _this.$message.success("绑定成功");
          _this.$emit('confirm');
        });
      });
    },
    edit: function edit(record) {
      var id = record.id;
      this.id = id;
      this.getList();
    },
    getTemplateRow: function getTemplateRow(idx, row) {
      this.$emit("getTemplateRow", row);
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      deviceApi.userListApi(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.loading = false;
      }).catch(function (res) {
        _this2.$message.error(res.message);
        _this2.loading = false;
      });
    },
    search: function search() {
      var _this3 = this;
      this.loading = true;
      userListApi({
        keywords: this.tableFrom.keywords
      }).then(function (res) {
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.loading = false;
      }).catch(function (res) {
        _this3.$message.error(res.message);
        _this3.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};
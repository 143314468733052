"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _infoMixins = require("./infoMixins");
var _editableCell = _interopRequireDefault(require("@/components/editableCell"));
var _productList = _interopRequireDefault(require("./components/productList.vue"));
var _areaMap = _interopRequireDefault(require("../list/areaMap.vue"));
var _selectAddress = _interopRequireDefault(require("../components/selectAddress.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    editableCell: _editableCell.default,
    productList: _productList.default,
    areaMap: _areaMap.default,
    selectAddress: _selectAddress.default
  },
  mixins: [_infoMixins.infoMixins]
};
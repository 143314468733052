var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        visible: _vm.visible,
        title: "Check records",
        "before-close": _vm.handleClose,
        width: "1200px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { attrs: { "body-style": { padding: "20px" } } },
        [
          _c(
            "div",
            {
              staticClass: "table-head-wrapper",
              staticStyle: { padding: "10px 0 20px" },
            },
            [
              _c(
                "el-form",
                { attrs: { size: "small", inline: true } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Date", "label-width": "48px" } },
                    [
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "220px" },
                          attrs: {
                            "value-format": "yyyy-MM-dd",
                            format: "yyyy-MM-dd",
                            type: "date",
                            placeholder: "Please choose",
                          },
                          on: { change: _vm.handleDateChange },
                          model: {
                            value: _vm.queryParams.searchDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "searchDate", $$v)
                            },
                            expression: "queryParams.searchDate",
                          },
                        }),
                      ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Check number", "label-width": "132px" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleSearch($event)
                        },
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "220px" },
                          attrs: {
                            placeholder: "Check number",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryParams.chequeNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "chequeNo", $$v)
                            },
                            expression: "queryParams.chequeNo",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: { click: _vm.handleSearch },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.invoiceData.loading,
                      expression: "invoiceData.loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { "min-height": "200px" },
                  attrs: {
                    data: _vm.invoiceData.data,
                    "row-class-name": _vm.tableRowClassName,
                    size: "mini",
                    "header-cell-style": {
                      background: "#f5f5f5",
                      color: "#515a6e",
                      height: "40px",
                    },
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "Payment date", prop: "payTime" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm._f("time")(row.payTime))),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Check number", prop: "chequeNo" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Invoice number", prop: "invoiceNo" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Check amount", prop: "chequePrice" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Remark", prop: "remark" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "operation" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                staticClass: "primary-border",
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(row)
                                  },
                                },
                              },
                              [_vm._v("Check details")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 40],
                      "page-size": _vm.pagination.limit,
                      "current-page": _vm.pagination.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.invoiceData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("check-detail", { ref: "checkDetail", on: { ok: _vm.handleOk } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as systemAdminApi from '@/api/systemadmin.js';
import Cookies from 'js-cookie';
import { Debounce } from '@/utils/validate';
export default {
  name: "index",
  data: function data() {
    var _this = this;
    var validatePass = function validatePass(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please enter password again'));
      } else if (value !== _this.pram.pwd) {
        callback(new Error('The password entered twice is inconsistent!'));
      } else {
        callback();
      }
    };
    var JavaInfo = JSON.parse(Cookies.get('JavaInfo'));
    return {
      password: '',
      JavaInfo: JSON.parse(Cookies.get('JavaInfo')),
      pram: {
        account: JavaInfo.account,
        pwd: null,
        repwd: null,
        realName: JavaInfo.realName,
        id: JavaInfo.id
      },
      roleList: [],
      rules: {
        account: [{
          required: true,
          message: 'Please enter',
          trigger: ['blur', 'change']
        }],
        pwd: [{
          required: true,
          message: 'Please enter',
          trigger: ['blur', 'change']
        }],
        repwd: [{
          required: true,
          message: 'Please enter',
          validator: validatePass,
          trigger: ['blur', 'change']
        }],
        realName: [{
          required: true,
          message: 'Please enter',
          trigger: ['blur', 'change']
        }]
      }
    };
  },
  methods: {
    close: function close(formName) {
      this.$refs[formName].resetFields();
    },
    handlerSubmit: Debounce(function (formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          systemAdminApi.adminUpdate(_this2.pram).then(function (data) {
            _this2.$message.success('Submit successfully');
            _this2.$router.go(-1);
          });
        } else {
          return false;
        }
      });
    }),
    handlerPwdInput: function handlerPwdInput(val) {
      var _this3 = this;
      if (!val) {
        this.rules.pwd = [];
        this.rules.repwd = [];
        return;
      }
      this.rules.pwd = [{
        required: true,
        message: 'Please enter the administrator password',
        trigger: ['blur', 'change']
      }, {
        min: 6,
        max: 20,
        message: 'Length required 6-20 characters',
        trigger: ['blur', 'change']
      }];
      this.rules.repwd = [{
        required: true,
        message: 'The password entered twice is inconsistent',
        validator: function validator(rule, value, callback) {
          if (value === '') {
            callback(new Error('The password entered twice is inconsistent!'));
          } else if (value !== _this3.pram.pwd) {
            callback(new Error('The password entered twice is inconsistent!'));
          } else {
            callback();
          }
        },
        trigger: ['blur', 'change']
      }];
    }
  }
};
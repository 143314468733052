var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "pram",
          attrs: { model: _vm.pram, rules: _vm.rules },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Plant name", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Plant name" },
                        model: {
                          value: _vm.pram.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.pram, "name", $$v)
                          },
                          expression: "pram.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Login", prop: "account" } },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: _vm.pram.id ? true : false,
                          placeholder: "Login",
                        },
                        model: {
                          value: _vm.pram.account,
                          callback: function ($$v) {
                            _vm.$set(_vm.pram, "account", $$v)
                          },
                          expression: "pram.account",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Login Password", prop: "pwd" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Login Password", clearable: "" },
                        on: {
                          input: _vm.handlerPwdInput,
                          clear: _vm.handlerPwdInput,
                        },
                        model: {
                          value: _vm.pram.pwd,
                          callback: function ($$v) {
                            _vm.$set(_vm.pram, "pwd", $$v)
                          },
                          expression: "pram.pwd",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _vm.pram.pwd
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "confirm password", prop: "repwd" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "confirm password",
                              clearable: "",
                            },
                            model: {
                              value: _vm.pram.repwd,
                              callback: function ($$v) {
                                _vm.$set(_vm.pram, "repwd", $$v)
                              },
                              expression: "pram.repwd",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Plant Address", prop: "address" } },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content:
                              "Please click Find Location to select a location",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "dialogWidth",
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "Please find location",
                                readOnly: "",
                              },
                              model: {
                                value: _vm.pram.address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pram, "address", $$v)
                                },
                                expression: "pram.address",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "append" },
                                [
                                  _c(
                                    "el-button",
                                    { on: { click: _vm.handleOpenAddress } },
                                    [_vm._v("find location")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Mailbox/email address?", prop: "email" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Mailbox/email address?" },
                        model: {
                          value: _vm.pram.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.pram, "email", $$v)
                          },
                          expression: "pram.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Contact name", prop: "realName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          prefix: "ios-contact-outline",
                          placeholder: "Contact name",
                          size: "large",
                        },
                        model: {
                          value: _vm.pram.realName,
                          callback: function ($$v) {
                            _vm.$set(_vm.pram, "realName", $$v)
                          },
                          expression: "pram.realName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Contact phone", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          prefix: "ios-contact-outline",
                          placeholder: "Contact phone",
                          size: "large",
                        },
                        model: {
                          value: _vm.pram.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.pram, "phone", $$v)
                          },
                          expression: "pram.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Deposit", prop: "depositAmt" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: { min: 0, placeholder: "Deposit" },
                        model: {
                          value: _vm.pram.depositAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.pram, "depositAmt", $$v)
                          },
                          expression: "pram.depositAmt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Profile", prop: "description" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "250",
                          rows: 3,
                          placeholder: "Profile",
                        },
                        model: {
                          value: _vm.pram.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.pram, "description", $$v)
                          },
                          expression: "pram.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Photo of Plant", prop: "image" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("1", "image")
                            },
                          },
                        },
                        [
                          _vm.pram.image
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", { attrs: { src: _vm.pram.image } }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "plant Logo", prop: "logo" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("1", "logo")
                            },
                          },
                        },
                        [
                          _vm.pram.logo
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", { attrs: { src: _vm.pram.logo } }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "status" } },
            [
              _c("el-switch", {
                staticStyle: { width: "40px" },
                attrs: { "active-value": true, "inactive-value": false },
                model: {
                  value: _vm.pram.isShow,
                  callback: function ($$v) {
                    _vm.$set(_vm.pram, "isShow", $$v)
                  },
                  expression: "pram.isShow",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadings,
                  expression: "loadings",
                },
              ],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("pram")
                },
              },
            },
            [_vm._v(_vm._s(_vm.isCreate === 0 ? "confirm" : "update"))]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("cancel")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("select-address", {
        ref: "plantAddress",
        attrs: { "is-create": _vm.isCreate },
        on: { change: _vm.selectAddressOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
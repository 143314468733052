import { render, staticRenderFns } from "./Reordering.vue?vue&type=template&id=3c4d8bd6&scoped=true"
import script from "./Reordering.vue?vue&type=script&lang=js"
export * from "./Reordering.vue?vue&type=script&lang=js"
import style0 from "./Reordering.vue?vue&type=style&index=0&id=3c4d8bd6&lang=css"
import style1 from "./Reordering.vue?vue&type=style&index=1&id=3c4d8bd6&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c4d8bd6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\项目\\水厂(h2go)\\h2go\\admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c4d8bd6')) {
      api.createRecord('3c4d8bd6', component.options)
    } else {
      api.reload('3c4d8bd6', component.options)
    }
    module.hot.accept("./Reordering.vue?vue&type=template&id=3c4d8bd6&scoped=true", function () {
      api.rerender('3c4d8bd6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/components/Reordering.vue"
export default component.exports
var _methods;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as roleApi from "@/api/role.js";
import "@/utils/directive";
import { configInfo } from "@/api/systemConfig";
import * as systemMerchantApi from "@/api/merchant.js";
import { Loader } from "@googlemaps/js-api-loader";
import { Debounce } from "@/utils/validate";
import { mapGetters } from "vuex";
import { validAddress } from "@/utils/validate.js";
import selectAddress from "../components/selectAddress.vue";
export default {
  // name: "edit"
  components: {
    selectAddress: selectAddress
  },
  props: {
    isCreate: {
      type: Number,
      required: true
    },
    editData: {
      type: Object,
      default: function _default() {
        return {
          rules: []
        };
      }
    }
  },
  data: function data() {
    var _this2 = this;
    var validatePhone = function validatePhone(rule, value, callback) {
      if (!value) {
        return callback(new Error("Please fill in your mobile phone number"));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("The format of the mobile phone number is incorrect!"));
      } else {
        callback();
      }
    };
    var validatePass = function validatePass(rule, value, callback) {
      if (value === "") {
        callback(new Error("Please enter your password again"));
      } else if (value !== _this2.pram.pwd) {
        callback(new Error("The two passwords are inconsistent!"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      addressList: [],
      selectaddressPram: {},
      searchVal: "",
      btnLoading: false,
      markLoading: false,
      loading: false,
      mapLoading: false,
      loadings: false,
      constants: this.$constants,
      modalMap: false,
      keyUrl: "",
      pram: {
        email: null,
        name: null,
        account: null,
        level: null,
        pwd: null,
        repwd: null,
        realName: null,
        isShow: null,
        id: null,
        image: null,
        logo: null,
        address: null,
        description: null,
        phone: null,
        deliveryDays: null,
        depositAmt: null,
        dayTime: "",
        latitude: ""
      },
      _roles: "",
      roleList: [],
      rules: {
        account: [{
          required: true,
          message: "Plant Account number",
          trigger: ["blur", "change"]
        }],
        name: [{
          required: true,
          message: "Plant name",
          trigger: ["blur", "change"]
        }],
        pwd: [{
          required: true,
          message: "Login Password",
          trigger: ["blur", "change"]
        }],
        repwd: [{
          required: true,
          message: "Login Password",
          validator: validatePass,
          trigger: ["blur", "change"]
        }],
        realName: [{
          required: true,
          message: "Contact name",
          trigger: ["blur", "change"]
        }],
        image: [{
          required: true,
          message: "Photo of Plant",
          trigger: ["blur", "change"]
        }],
        logo: [{
          required: true,
          message: "plant background img",
          trigger: ["blur", "change"]
        }],
        roles: [{
          required: true,
          message: "plant role",
          trigger: ["blur", "change"]
        }],
        phone: [{
          required: true,
          message: "phone number",
          trigger: ["blur", "change"]
        }],
        address: [{
          required: true,
          message: "plant address",
          trigger: ["blur", "change"]
        }],
        email: [{
          required: true,
          message: "email",
          trigger: ["blur", "change"]
        }]
      }
    };
  },
  computed: _objectSpread({}, mapGetters(["storeId"])),
  mounted: function mounted() {
    this.initEditData();
    this.handleGetRoleList();
  },
  methods: (_methods = {
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      var attr = [];
      this.$modalUpload(function (img) {
        _this.pram.image = img[0].sattDir;
      }, tit, "store");
    }
  }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_methods, "modalPicTap", function modalPicTap(tit, image) {
    var _this = this;
    var attr = [];
    this.$modalUpload(function (img) {
      if (tit === "1") {
        _this.pram[image] = img[0].sattDir;
      }
      if (tit === "2") {
        img.map(function (item) {
          attr.push(item.attachment_src);
          _this.formValidate.slider_image.push(item);
        });
      }
    }, tit, "store");
  }), "handleOpenAddress", function handleOpenAddress() {
    this.$refs.plantAddress.onSearch(this.pram);
  }), "selectAddressOk", function selectAddressOk(addressData) {
    this.pram = Object.assign({}, this.pram, _objectSpread({}, addressData));
  }), "close", function close() {
    this.$emit("close");
  }), "handleGetRoleList", function handleGetRoleList() {
    var _this3 = this;
    var _pram = {
      page: 1,
      limit: this.constants.page.limit[4],
      status: 1
    };
    roleApi.getRoleList(_pram).then(function (data) {
      _this3.roleList = data;
      var arr = [];
      data.list.forEach(function (item) {
        arr.push(item.id);
      });
      if (!arr.includes(Number.parseInt(_this3.pram.roles))) {
        _this3.$set(_this3.pram, "roles", []);
      }
    });
  }), "initEditData", function initEditData() {
    if (this.isCreate !== 1) return;
    var _this$editData = this.editData,
      logo = _this$editData.logo,
      account = _this$editData.account,
      realName = _this$editData.realName,
      email = _this$editData.email,
      level = _this$editData.level,
      isShow = _this$editData.isShow,
      id = _this$editData.id,
      phone = _this$editData.phone,
      name = _this$editData.name,
      address = _this$editData.address,
      description = _this$editData.description,
      image = _this$editData.image,
      deliveryDays = _this$editData.deliveryDays,
      depositAmt = _this$editData.depositAmt,
      roles = _this$editData.roles,
      pwd = _this$editData.pwd,
      latitude = _this$editData.latitude,
      longitude = _this$editData.longitude;
    this.pram.account = account;
    this.pram.realName = realName;
    var _roles = [];
    if (roles) {
      _roles = roles.split(",").map(function (item) {
        return Number.parseInt(item);
      });
    }
    this.pram.roles = _roles;
    this._roles = _roles;
    this.pram.email = email;
    this.pram.status = status;
    this.pram.id = id;
    this.pram.phone = phone;
    this.rules.pwd = [];
    this.rules.repwd = [];
    this.pram.name = name;
    this.pram.latitude = latitude;
    this.pram.longitude = longitude;
    this.pram.address = address;
    this.pram.description = description;
    this.pram.image = image;
    this.pram.deliveryDays = deliveryDays;
    this.pram.depositAmt = depositAmt;
    this.pram.logo = logo;
  }), "handlerSubmit", Debounce(function (form) {
    var _this4 = this;
    this.pram.roles = "7";
    this.$refs[form].validate(function (valid) {
      if (!valid) return;
      _this4.loadings = true;
      if (_this4.isCreate === 0) {
        _this4.handlerSave();
      } else {
        _this4.handlerEdit();
      }
    });
  })), "handlerSave", function handlerSave() {
    var _this5 = this;
    systemMerchantApi.merchantAdd(this.pram).then(function (data) {
      _this5.$message.success("successful");
      _this5.loadings = false;
      _this5.$emit("hideEditDialog");
    }).finally(function (err) {
      _this5.loadings = false;
    });
  }), "handlerEdit", function handlerEdit() {
    var _this6 = this;
    // this.pram.roles = this.pram.roles.join(',')
    systemMerchantApi.merchantUpdate(this.pram).then(function (data) {
      _this6.$message.success("successful");
      _this6.loadings = false;
      _this6.$emit("hideEditDialog");
    }).finally(function (err) {
      _this6.loadings = false;
    });
  }), "rulesSelect", function rulesSelect(selectKeys) {
    this.pram.rules = selectKeys;
  }), _defineProperty(_methods, "handlerPwdInput", function handlerPwdInput(val) {
    var _this7 = this;
    if (!val) {
      this.rules.pwd = [];
      this.rules.repwd = [];
      return;
    }
    this.rules.pwd = [{
      required: true,
      message: "Please fill in your login password",
      trigger: ["blur", "change"]
    }, {
      min: 6,
      max: 20,
      message: "Contains 6 to 20 characters",
      trigger: ["blur", "change"]
    }];
    this.rules.repwd = [{
      required: true,
      message: "The two passwords are inconsistent",
      validator: function validator(rule, value, callback) {
        if (value === "") {
          callback(new Error("The two passwords are inconsistent!"));
        } else if (value !== _this7.pram.pwd) {
          callback(new Error("The two passwords are inconsistent!"));
        } else {
          callback();
        }
      },
      trigger: ["blur", "change"]
    }];
  }))
};
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));
var _edit = _interopRequireDefault(require("./edit"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "index"
  components: {
    edit: _edit.default
  },
  props: {
    selectModel: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      constants: this.$constants,
      listPram: {
        keywords: null,
        page: 1,
        limit: this.$constants.page.limit[0]
      },
      editDialogConfig: {
        visible: false,
        editData: {},
        isCreate: 0
      },
      dataList: {
        list: [],
        total: 0
      },
      selectedConfigData: {}
    };
  },
  mounted: function mounted() {
    this.handlerGetList(this.listPram);
  },
  methods: {
    handlerSearch: function handlerSearch() {
      this.listPram.page = 1;
      this.handlerGetList(this.listPram);
    },
    handlerGetList: function handlerGetList(pram) {
      var _this = this;
      systemFormConfigApi.getFormConfigList(pram).then(function (data) {
        _this.dataList = data;
      });
    },
    handlerEditData: function handlerEditData(rowData, isCreate) {
      if (isCreate === 0) {
        this.editDialogConfig.editData = {};
      } else {
        this.editDialogConfig.editData = rowData;
      }
      this.editDialogConfig.isCreate = isCreate;
      this.editDialogConfig.visible = true;
    },
    handlerHide: function handlerHide() {
      this.editDialogConfig.editData = {};
      this.editDialogConfig.isCreate = 0;
      this.editDialogConfig.visible = false;
      this.handlerGetList(this.listPram);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetList(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetList(this.listPram);
    },
    handleCurrentRowChange: function handleCurrentRowChange(rowData) {
      this.selectedConfigData = rowData;
    },
    handlerConfimSelect: function handlerConfimSelect() {
      this.$emit('selectedRowData', this.selectedConfigData);
    }
  }
};
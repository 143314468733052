"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("@/utils/directive");
var deliveryApi = _interopRequireWildcard(require("@/api/delivery.js"));
var _validate = require("@/utils/validate");
var _jsApiLoader = require("@googlemaps/js-api-loader");
var _urlencode = _interopRequireDefault(require("urlencode"));
var _shopList = _interopRequireDefault(require("@/views/delivery/shopList.vue"));
var _edit = _interopRequireDefault(require("@/views/delivery/edit"));
var systemMerchantApi = _interopRequireWildcard(require("@/api/merchant.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

function ParseEncodedValue(value) {
  var safeCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-";
  var list = new Array();
  var index = 0;
  var xsum = 0;
  var ysum = 0;
  var max = 4294967296;
  while (index < value.length) {
    var n = 0;
    var k = 0;
    while (1) {
      if (index >= value.length) {
        return null;
      }
      var b = safeCharacters.indexOf(value.charAt(index++));
      if (b == -1) {
        return null;
      }
      var tmp = (b & 31) * Math.pow(2, k);
      var ht = tmp / max;
      var lt = tmp % max;
      var hn = n / max;
      var ln = n % max;
      var nl = (lt | ln) >>> 0;
      n = (ht | hn) * max + nl;
      k += 5;
      if (b < 32) break;
    }
    var diagonal = parseInt((Math.sqrt(8 * n + 5) - 1) / 2);
    n -= diagonal * (diagonal + 1) / 2;
    var ny = parseInt(n);
    var nx = diagonal - ny;
    nx = nx >> 1 ^ -(nx & 1);
    ny = ny >> 1 ^ -(ny & 1);
    xsum += nx;
    ysum += ny;
    var lat = ysum * 0.00001;
    var lon = xsum * 0.00001;
    list.push([lon, lat]);
  }
  return list;
}
var loader = new _jsApiLoader.Loader({
  apiKey: "AIzaSyB0KvRZORCJ-XVdwPgqvfYpl70ahSD8KJ8",
  //api的key
  version: "weekly",
  //版本
  libraries: ["places", "drawing"] //插件库
});
var geocoder;
var map;
var marker;
var shape;
var AirPolygon;
var polygon1;
var newShape;
var infowindow;
var drawingManager;
var service;
var graphicalExample = new Array();
var colorObj = {
  1: "#FF4444",
  2: "#1890ff",
  3: "#b240f9",
  4: "#00DD00",
  5: "#FFBB66",
  6: "#78ba2e",
  7: "#de0c2a"
};
var weekObj = {
  1: "Monday delivery",
  2: "Tuesday delivery",
  3: "Wednesday delivery",
  4: "Thursday delivery",
  5: "Friday delivery",
  6: "Saturday delivery",
  7: "Sunday delivery"
};
var _default2 = exports.default = {
  // name: "edit"
  components: {
    edit: _edit.default,
    shopList: _shopList.default
  },
  props: {
    editData: {
      type: Object,
      default: function _default() {
        return {
          rules: []
        };
      }
    },
    storeId: {
      type: [Number, String]
    }
  },
  data: function data() {
    return {
      searchVal: "",
      geoMapList: [],
      dangerStatus: false,
      addressList: [],
      markLoading: false,
      btnLoading: false,
      selectaddressPram: {},
      graphicalPathArray: [],
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        editData: {}
      },
      mapLoading: true,
      areaId: "",
      loading: false,
      deliveryVisible: false,
      constants: this.$constants,
      modalMap: false,
      keyUrl: "",
      graphicalPath: [],
      pram: {
        // code: "",
        lat: "",
        lng: "",
        deliveryPrice: "",
        weekDay: "",
        address: "",
        period: "",
        deliveryDays: null
      },
      roleList: [],
      //图形实例
      graphicalExample: null,
      location: {},
      rules: {
        code: [{
          required: true,
          message: "code",
          trigger: ["blur", "change"]
        }],
        address: [{
          required: true,
          message: "please enter area name",
          trigger: ["blur", "change"]
        }],
        lat: [{
          required: true,
          message: "please choose area",
          trigger: ["blur", "change"]
        }],
        deliveryDays: [{
          required: true,
          message: "please enter duration",
          trigger: ["blur", "change"]
        }]
      },
      listData: [],
      addressComponents: null,
      listPram: {
        page: 1,
        limit: 999
      },
      center: {}
    };
  },
  computed: {},
  mounted: function mounted() {
    window.operating = this.operating;
  },
  methods: {
    // 配送区域地图
    onSearch: function onSearch(row) {
      this.modalMap = true;
      var _this = this;
      this.$nextTick(function () {
        _this.initMap(row);
      });
    },
    // 搜索
    searchMap: function searchMap() {
      var _this2 = this;
      this.addressList = [];
      if (!this.searchVal) {
        this.addressList = [];
        return;
      }
      this.btnLoading = true;
      this.addressList = [];
      systemMerchantApi.googlePlace({
        input: this.searchVal
      }).then(function (res) {
        _this2.addressList = res.predictions;
        _this2.btnLoading = false;
      }).catch(function () {
        _this2.btnLoading = false;
      });
    },
    close: function close() {
      this.addressList = [];
      this.searchVal = "";
      // this.$emit("hideEditDialog");
    },
    handleCloseDialog: function handleCloseDialog() {
      this.editDialogConfig.visible = false;
      if (this.editDialogConfig.isCreate === 0) {
        AirPolygon.setMap(null);
      }
    },
    handleClose: function handleClose() {
      this.modalMap = false;
      this.graphicalPath = [];
      this.location = {};
      this.addressList = [];
      this.searchVal = "";
      this.clonseN();
    },
    initMap: function initMap(row) {
      var _this3 = this;
      this.center = row;
      var that = this;
      this.dangerStatus = false;
      var mapOptions = {
        center: {
          lat: Number(row.latitude),
          lng: Number(row.longitude)
        },
        zoom: 10,
        streetViewControl: false
      };
      loader.load().then(function (google) {
        _this3.google = google;
        map = new google.maps.Map(document.getElementById("map"), mapOptions);
        _this3.googleMap = map;
        geocoder = new google.maps.Geocoder();
        // service 地点查询类
        service = new google.maps.places.PlacesService(map);
        infowindow = new google.maps.InfoWindow({
          // 地图信息窗口
          content: ""
        });
        _this3.listPram.storeId = _this3.storeId;
        var returnMap = [];
        var that = _this3;
        deliveryApi.deliveryList(_this3.listPram).then(function (res) {
          if (res && res.list.length >= 0) {
            res.list.map(function (e) {
              e.list = [];
              var polygonCoords = [];
              var latArr = _this3.getAry(e.lat);
              var lngArr = _this3.getAry(e.lng);
              for (var _i = 0; _i < latArr.length; _i++) {
                var obj = {
                  lat: Number(latArr[_i]),
                  lng: Number(lngArr[_i])
                };
                polygonCoords.push(new google.maps.LatLng(latArr[_i], lngArr[_i]));
                e.list.push(obj);
              }
              returnMap.push(e);
              _this3.getMarker(e, polygonCoords);
            });
            _this3.geoMapList = returnMap;
            for (var i = returnMap.length - 1; i >= 0; i--) {
              _this3.getCircle(returnMap[i], returnMap[i].list, colorObj[returnMap[i].weekDay]);
            }
          }
        });
        if (row.latitude && row.longitude) {
          var pos = {
            lat: Number(row.latitude),
            lng: Number(row.longitude)
          };
          map.setCenter(pos);
          marker = new google.maps.Marker({
            position: pos,
            map: map
          });
        }

        // if (navigator.geolocation) {
        //   navigator.geolocation.getCurrentPosition(
        //     position => {
        //       let pos = {
        //         lat: position.coords.latitude,
        //         lng: position.coords.longitude
        //       };

        //     },
        //     () => {
        //       this.handleLocationError(true, infowindow, map.getCenter());
        //     }
        //   );
        // } else {
        //   this.handleLocationError(false, infowindow, map.getCenter());
        // }
      }).catch(function (e) {
        // alert(1)
        _this3.$message.error("Map loading failure");
      });
    },
    // 监听多边形绘画事件
    setDrawingManager: function setDrawingManager(drawingManager) {
      var _this4 = this;
      new google.maps.event.addListener(drawingManager, "polygonstart", function (e) {
        console.log(1);
      });
      //监听绘制多边形完成事件事件
      new google.maps.event.addListener(drawingManager, "polygoncomplete", function (e) {
        var arr = [];
        console.log(e.getPath().g);
        e.getPath().g.map(function (item) {
          arr.push(String(item.lat()));
          arr.push(String(item.lng()));
        });
        //path路径emit传出
        _this4.setPath(e.getPath().g);
        //监听回显图形每一个路径点
        new google.maps.event.addListener(e.getPath(), "insert_at", function (event) {
          _this4.setPath(e.getPath().g);
        });
        new google.maps.event.addListener(e.getPath(), "set_at", function (event) {
          _this4.setPath(e.getPath().g);
          console.log(e.getPath().g);
        });
        //绘制完成后关闭绘制状态
        drawingManager.setDrawingMode(null);
      });
    },
    getAry: function getAry(s) {
      if (typeof s == "string") {
        return s.split(",");
      }
      return s;
    },
    getWeekDay: function getWeekDay(w) {
      var obj = {
        1: "Mon",
        2: "Tue",
        3: "Wed",
        4: "Thu",
        5: "Fri",
        6: "Sat",
        7: "Sun"
      };
      return obj[w];
    },
    randomHexColor: function randomHexColor() {
      var color = "#" + Math.random().toString(16).substring(2, 8);
      return color.length !== 7 ? randomColor2() : color;
    },
    drawingManagers: function drawingManagers() {
      this.dangerStatus = true;
      this.drawPolygonMap();
      drawingManager.setDrawingMode(this.google.maps.drawing.OverlayType.POLYGON);
    },
    closeDrawingManagers: function closeDrawingManagers() {
      this.dangerStatus = false;
      drawingManager.setDrawingMode(null);
    },
    //画多边形区域
    drawPolygonMap: function drawPolygonMap() {
      if (drawingManager != null) {
        drawingManager.setMap(null);
      }
      drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON]
        },
        polygonOptions: {
          fillColor: "#1890ff",
          fillOpacity: 0.3,
          strokeColor: "#1890ff",
          strokeWeight: 1.5,
          editable: false,
          draggable: false
        }
      });
      drawingManager.setMap(map);
      drawingManager.addListener("polygoncomplete", this.shapecomplete); //添加完成事件事件监听
      drawingManager.addListener("overlaycomplete", this.overlaycomplete); //添加编辑事件监听
    },
    overlaycomplete: function overlaycomplete(e) {
      var polygon = e.overlay;
      polygon.type = e.type;
      AirPolygon = polygon;
      var array = polygon.getPath().getArray();
      var that = this;
      //图层的点击事件
      new google.maps.event.addListener(polygon, "click", function (e) {
        infowindow.close(); //关闭信息窗口
        var lat = e.latLng.lat();
        var lng = e.latLng.lng();
        AirPolygon = polygon;
        that.setEdit(array, {});
      });
    },
    shapecomplete: function shapecomplete(e) {
      var _this5 = this;
      if (e.getPath().g) {
        var arr = [];
        e.getPath().g.map(function (item) {
          arr.push(String(item.lat()));
          arr.push(String(item.lng()));
        });
      }
      this.setPath(e.getPath().g);
      var array = e.getPath().getArray();
      this.setEdit(array, {});
      var cricleArray = [];
      for (var i = 0; i < array.length; i++) {
        var obj = {
          lat: array[i].lat(),
          lng: array[i].lng()
        };
        cricleArray.push(obj);
      }
      //监听回显图形每一个路径点
      new google.maps.event.addListener(e.getPath(), "insert_at", function (event) {
        _this5.setPath(e.getPath().g);
      });
      new google.maps.event.addListener(e.getPath(), "set_at", function (event) {
        _this5.setPath(e.getPath().g);
      });
      //绘制完成后关闭绘制状态
      this.dangerStatus = false;
      drawingManager.setDrawingMode(null);
    },
    // 获取区域坐标中心点
    getPointsCenter: function getPointsCenter(lat, lng) {
      var i;
      var polygonCoords = [];
      lat.forEach(function (e, i) {
        var obj = new google.maps.LatLng(e, lng[i]);
        polygonCoords.push(obj);
      });
      var bounds = new google.maps.LatLngBounds();
      for (i = 0; i < polygonCoords.length; i++) {
        bounds.extend(polygonCoords[i]);
      }
      return bounds.getCenter();
    },
    clonseN: function clonseN() {
      console.log(this.graphicalExample);
      console.log(this.graphicalPath);
      return;
      if (this.graphicalExample) {
        this.graphicalExample.setMap(null);
        this.graphicalPath = [];
      }
    },
    setPath: function setPath(path) {
      // this.graphicalPath = []
      // if (path.length > 0) {
      //     path.forEach((v) => {
      //         this.graphicalPath.push({ lat: v.lat(), lng: v.lng() })
      //     })
      // }
    },
    handleLocationError: function handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(browserHasGeolocation ? "Error: The Geolocation service failed." : "Error: Your browser doesn't support geolocation.");
      infoWindow.open(map);
    },
    selectAddres: function selectAddres(place_id) {
      var _this6 = this;
      this.markLoading = true;
      systemMerchantApi.googleChoose({
        placeId: place_id,
        key: "AIzaSyB0KvRZORCJ-XVdwPgqvfYpl70ahSD8KJ8"
      }).then(function (res) {
        var sourceData = res.results[0];
        _this6.markLoading = false;
        _this6.getAddress(sourceData);
      }).catch(function () {
        _this6.btnLoading = false;
      });
    },
    getAddress: function getAddress(sourceData) {
      this.selectaddressPram.address = sourceData.geometry.formatted_address;
      this.selectaddressPram.latitude = sourceData.geometry.location.lat;
      this.selectaddressPram.longitude = sourceData.geometry.location.lng;
      this.addressList = [];
      // this.searchVal=''
      map.setCenter({
        lat: Number(this.selectaddressPram.latitude),
        lng: Number(this.selectaddressPram.longitude)
      });
      this.getBingMapsGeometrya();
    },
    getBingMapsGeometrya: function getBingMapsGeometrya() {
      var _this7 = this;
      if (!this.selectaddressPram.latitude || !this.selectaddressPram.longitude) {
        this.$message.error("Please enter the address to get the administrative area");
        return;
      }
      var entityType = "PopulatedPlace";
      // AdminDivision2
      var latitude = this.selectaddressPram.latitude;
      var longitude = this.selectaddressPram.longitude;
      var address = this.selectaddressPram.address;
      var baseUrl = "http://platform.bing.com/geo/spatial/v1/public/Geodata?SpatialFilter=",
        levelOfDetail = 0,
        getAllPolygons = 1,
        getEntityMetadata = 1,
        culture = "CN",
        userRegion = "CN",
        preferCuratedPolygons = 1,
        responseFormat = "json",
        key = "AuqqgCcnsSmLyS8-4Dc0QQhWV713UOSThvBg0-iOLGpX12PUgFZtiooNce85hIg7";
      // let url = `${baseUrl}GetBoundary(${address},${levelOfDetail},'${entityType}',${getAllPolygons},${getEntityMetadata},'${culture}','${userRegion}')&PreferCuratedPolygons=${preferCuratedPolygons}&$format=${responseFormat}&key=${key}`
      var url = "".concat(baseUrl, "GetBoundary(").concat(latitude, ",").concat(longitude, ",").concat(levelOfDetail, ",'").concat(entityType, "',").concat(getAllPolygons, ",").concat(getEntityMetadata, ",'").concat(culture, "','").concat(userRegion, "')&PreferCuratedPolygons=").concat(preferCuratedPolygons, "&$format=").concat(responseFormat, "&key=").concat(key);
      var url1 = url = (0, _urlencode.default)(url);
      deliveryApi.getBingMapsGeometry({
        url: url1
      }).then(function (res) {
        if (typeof res == "string") {
          res = JSON.parse(res);
        }
        var entity = res.d.results[0];
        if (!entity) {
          alert("no results");
          return;
        }
        _this7.clonseN();
        var entityMetadata = entity.EntityMetadata;
        entityMetadata.name = entity.Name.EntityName;
        var primitives = entity.Primitives;
        var boundaryVertices = null;
        var numOfVertices = 0;
        var multyiPolygonArray = [];
        var singlePolygonArray;
        for (var i = 0; i < primitives.length; i++) {
          var ringStr = primitives[i].Shape;
          var ringArray = ringStr.split(",");
          for (var j = 1; j < ringArray.length; j++) {
            var polygonArray = new Array();
            var array = ParseEncodedValue(ringArray[j]);
            if (array.length > numOfVertices) {
              numOfVertices = array.length;
              boundaryVertices = array;
            }
            polygonArray.push(array);
            if (ringArray.length > 1) multyiPolygonArray.push(polygonArray);else singlePolygonArray = polygonArray;
          }
        }
        var geojson = {
          type: "FeatureCollection",
          features: [{
            type: "Feature",
            properties: entityMetadata,
            geometry: {
              type: singlePolygonArray ? "Polygon" : "MultiPolygon",
              coordinates: singlePolygonArray ? singlePolygonArray : multyiPolygonArray
            }
          }]
        };
        var list = geojson.features[0].geometry.coordinates;
        list.sort(function (a, b) {
          return b[0].length - a[0].length;
        });
        // const length=geojson.features[0].geometry.coordinates.length
        // const arr=geojson.features[0].geometry.coordinates[length-1][0] || []
        var arr = list[0][0] || [];
        // console.log(geojson.features[0].properties.name)
        // this.pram.address=geojson.properties.na
        // this.pram.address=geojson.features[0].properties.name
        var paths = arr.map(function (e) {
          return {
            lat: e[1],
            lng: e[0]
          };
        });
        var paths_start_end_lat_lng = [paths[0].lat, paths[0].lng, paths[paths.length - 2].lat, paths[paths.length - 2].lng];
        var obj = {};
        var flag = true;
        var geoMapList = _this7.geoMapList;
        var newArray = geoMapList.filter(function (item) {
          return item.list.length == paths.length;
        });
        for (var i = 0; i < newArray.length; i++) {
          var _list = newArray[i].list;
          var start_end_lat_lng = [_list[0].lat, _list[0].lng, _list[_list.length - 2].lat, _list[_list.length - 2].lng];
          if (paths_start_end_lat_lng.join(",") === start_end_lat_lng.join(",")) {
            flag = false;
            break;
          }
        }
        if (!flag) {
          _this7.$message.warning("This area already exists on the map");
          return;
        }
        obj.address = geojson.features[0].properties.name;
        _this7.getCircle(obj, paths, "#1890ff", true);
      }).catch(function (err) {
        //  alert(err)
      });
    },
    getMarker: function getMarker(obj, polygonCoords) {
      var MarkerWithLabel = require("markerwithlabel")(this.google.maps);
      var bounds = new google.maps.LatLngBounds();
      for (var i = 0; i < polygonCoords.length; i++) {
        bounds.extend(polygonCoords[i]);
      }
      var weekDay = obj.subType === "day" ? "" : this.getWeekDay(obj.weekDay) + "/";
      var markerSvg = window.btoa("\n                <svg t=\"1717058364505\" class=\"icon\" viewBox=\"0 0 1024 1024\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" p-id=\"9359\" width=\"200\" height=\"200\"><path d=\"M-100.68565228 215.20383072h98.67147444v98.87386322H-100.68565228zM-298.47740987 215.20383072h98.67147445v98.87386322H-298.47740987zM-496.49009564 215.09954643h98.67147445v98.87386322H-496.49009564zM-199.47145322 314.29707717h98.67147445v98.87386323H-199.47145322zM-397.26398328 314.29707717h98.67147445v98.87386323H-397.26398328zM-595.27589658 314.19279289h98.67147445v98.87386322H-595.27589658zM-100.5751882 413.33779526h98.67147447v98.87386322H-100.5751882zM-298.36771826 413.33779526h98.67147447v98.87386322H-298.36771826zM-496.37963156 413.23351097h98.67147447v98.87386322H-496.37963156zM-199.36176161 512.08883476h98.67147447v98.87386322H-199.36176161zM-397.15351918 512.08883476h98.67147445v98.87386322H-397.15351918zM-595.16620497 511.98532295h98.67147447v98.87386323H-595.16620497zM-100.46549657 610.84064675h98.67147446v98.87386321H-100.46549657zM-298.25802663 610.84064675h98.67147445v98.87386321H-298.25802663zM-496.26993993 610.73636246h98.67147446v98.87386322H-496.26993993zM-199.2512975 709.59168625h98.67147444v98.87386322H-199.2512975zM-397.04382757 709.59168625h98.67147445v98.87386322H-397.04382757zM-595.05574086 709.48740196h98.67147444v98.87386322H-595.05574086z\" p-id=\"9360\"></path></svg>");
      var marker = new MarkerWithLabel({
        position: bounds.getCenter(),
        draggable: false,
        icon: {
          url: "data:image/svg+xml;base64,".concat(markerSvg),
          //配送完成/未完成图标
          scaledSize: new google.maps.Size(10, 40),
          fillColor: "white"
        },
        optimized: false,
        map: map,
        labelContent: "".concat(weekDay).concat(obj.period).concat(obj.subType ? obj.subType : "week"),
        labelAnchor: new google.maps.Point(40, 0),
        labelClass: "labels",
        labelStyle: {
          padding: "0",
          textAlign: "center",
          backgroundColor: "transparent",
          width: "90px",
          color: "#FF0000",
          fontSize: "16px",
          border: "none",
          borderRadius: "4px",
          borderColor: "#0072E2"
        }
      });
      // let title = `<div style="margin-bottom:2px;margin-bottom: 2px;font-weight: 500;font-size: 20px">area:${
      //   obj.address
      // } ${weekObj[obj.weekDay]}</div> <br />
      //           <div class="operating" style="color:#1890ff"><a style="padding:0 10px" onclick="operating('edit',${
      //   obj.id
      // })">edit</a><a style="color:#1890ff" onclick="operating(0)">edit area</a><a onclick="operating(1)" style="padding:0 10px">drag</a><a onclick="operating(2,${
      //   obj.id
      // })">delete</a></div>
      //            `;
      // let that = this;
      // //图层的点击事件
      // new google.maps.event.addListener(marker, "click", function(e) {
      //   infowindow.close(); //关闭
      //   infowindow.setPosition(bounds.getCenter());
      //   infowindow.setContent(title);
      //   infowindow.open(map);
      // });
    },
    // 生成多边形
    getCircle: function getCircle(obj, list, color, flag) {
      var path = [];
      for (var i = 0; i < list.length; i++) {
        path.push(new google.maps.LatLng(list[i].lat, list[i].lng));
      }
      // let color = this.randomHexColor()
      // 多边形参数
      var polygonOptions = {
        paths: path,
        fillColor: color,
        // 填充色
        fillOpacity: obj.id ? 0.65 : 0.3,
        // 填充色透明度
        strokeColor: color,
        // 线条颜色 黑色
        strokeOpacity: 0.7,
        // 透明度 70%
        strokeWeight: 1.5 // 宽度 5像素
      };
      var polygon1 = new google.maps.Polygon(polygonOptions);
      polygon1.setMap(map); // 设置显示到map上
      if (flag) {
        AirPolygon = polygon1;
      }
      var title = "<div style=\"margin-bottom:2px;margin-bottom: 2px;font-weight: 500;font-size: 20px\">area:".concat(obj.address, " ").concat(weekObj[obj.weekDay], "</div> <br />\n                <div class=\"operating\" style=\"color:#1890ff\"><a style=\"padding:0 10px\" onclick=\"operating('edit',").concat(obj.id, ")\">edit</a><a style=\"color:#1890ff\" onclick=\"operating(0)\">edit area</a><a onclick=\"operating(1)\" style=\"padding:0 10px\">drag</a><a onclick=\"operating(2,").concat(obj.id, ")\">delete</a></div>\n                 ");
      var that = this;
      if (!obj.id) {
        title = "<div style=\"margin-bottom:2px;margin-bottom: 2px;font-weight: 500;font-size: 20px\">area:".concat(obj.address, " </div> <br />\n                <div class=\"operating\" style=\"color:#1890ff\"><a style=\"padding:0 10px\" onclick=\"operating('edit',").concat(obj.id, ")\">edit</a><a style=\"color:#1890ff\" onclick=\"operating(0)\">edit area</a><a onclick=\"operating(1)\" style=\"padding:0 10px\">drag</a><a onclick=\"operating(2,").concat(obj.id, ")\">delete</a></div>\n                 ");
        this.areaId = "";
        this.setEdit(path, obj);
        //Pescadero
      }
      //图层的点击事件
      new google.maps.event.addListener(polygon1, "click", function (e) {
        infowindow.close(); //关闭信息窗口
        var lat = e.latLng.lat();
        var lng = e.latLng.lng();
        AirPolygon = polygon1;
        // if (obj.id) {
        infowindow.setPosition(new google.maps.LatLng(lat, lng));
        infowindow.setContent(title);
        infowindow.open(map);
      });

      //图层的双击事件
      google.maps.event.addListener(polygon1, "dblclick", function (e) {
        infowindow.close(); //关闭信息窗口
        var lat = e.latLng.lat();
        var lng = e.latLng.lng();
        AirPolygon = polygon1;
        // console.log(lat,lng);
      });

      //鼠标拖动结束事件
      google.maps.event.addListener(polygon1, "dragend", function (e) {
        infowindow.close(); //关闭信息窗口
        var array = polygon1.getPath().getArray(); //得到移动以后的经纬度
        that.setEdit(array, obj);
        AirPolygon = polygon1;
        // ReturnLonLat(array,1);
        polygon1.setDraggable(false); //图形设定可拖动
      });

      //鼠标拖动多边形点结束事件
      google.maps.event.addListener(polygon1, "mouseup", function (e) {
        if (polygon1.getEditable()) {
          //如果多边形没有触动可编辑状态不用再去获取经纬度
          infowindow.close(); //关闭信息窗口
          var array = polygon1.getPath().getArray(); //得到移动以后的经纬度
          that.setEdit(array, obj);
          AirPolygon = polygon1;
          // polygon1.setDraggable(false);//图形设定可拖动
          polygon1.setEditable(false); //图形设定可编辑
        }
      });
      //鼠标右击事件
      google.maps.event.addListener(polygon1, "rightclick", function (e) {
        var lat = e.latLng.lat();
        var lng = e.latLng.lng();
        // console.log(lat,lng);
        AirPolygon = polygon1;
        infowindow.setPosition(new google.maps.LatLng(lat, lng));
        infowindow.setContent(title);
        infowindow.open(map);
      });
    },
    // 拖动 拉伸 调用编辑接口
    setEdit: function setEdit(array, obj) {
      var latArr = [];
      var lngArr = [];
      array.forEach(function (e) {
        latArr.push(e.lat());
        lngArr.push(e.lng());
      });
      obj.lat = latArr.join(",");
      obj.lng = lngArr.join(",");
      if (obj.id) {
        deliveryApi.updateDelivery(obj).then(function (data) {});
      } else {
        this.areaId = "";
        this.editDialogConfig.editData = obj;
        this.editDialogConfig.isCreate = 0;
        this.editDialogConfig.visible = true;
      }
    },
    //图层操作
    operating: function operating(type, id, address) {
      var _this8 = this;
      var that = this;
      // 商品列表
      if (type == "edit") {
        if (id) {
          this.areaId = id;
          deliveryApi.areaInfo({
            id: id
          }).then(function (res) {
            _this8.editDialogConfig.visible = true;
            _this8.editDialogConfig.editData = res;
            _this8.editDialogConfig.isCreate = 1;
          });
        } else {
          this.areaId = "";
          this.editDialogConfig.visible = true;
          this.editDialogConfig.editData = {
            address: address
          };
          this.editDialogConfig.isCreate = 0;
        }
      } else if (0 == type) {
        AirPolygon.setEditable(true); // 设置图层可编辑
      } else if (1 == type) {
        AirPolygon.setDraggable(true); // 设置图层可拖动
      } else {
        this.$confirm("Data prior to deletion").then(function () {
          var _pram = {
            id: id
          };
          if (id) {
            deliveryApi.delDelivery(_pram).then(function (data) {
              that.$message.success("successful");
              that.initMap(_this8.center);
              AirPolygon.setMap(null); //删除图层
            });
          } else {
            AirPolygon.setMap(null);
          }
        });
      }
      infowindow.close(); //关闭信息窗口
    },
    hideEditDialog: function hideEditDialog() {
      this.editDialogConfig.visible = false;
      this.initMap(this.center);
    }
  }
};
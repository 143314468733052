var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        visible: _vm.visible,
        title: _vm.title,
        "before-close": _vm.handleClose,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "order_box" },
        [
          !_vm.orderType
            ? _c("div", { staticClass: "delivery-box" }, [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c("img", {
                    staticStyle: {
                      width: "44px",
                      height: "44px",
                      "border-radius": "50%",
                      margin: "0 auto",
                    },
                    attrs: { src: require("@/assets/imgs/driver.webp") },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "text",
                      staticStyle: { "align-items": "flex-start" },
                    },
                    [
                      _c("span", { staticClass: "car" }, [
                        _vm._v(_vm._s(_vm.form.driverName) + "ssss"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "15px" } },
                          [
                            _vm._v("Car model:\n              "),
                            _c(
                              "span",
                              {
                                staticClass: "car",
                                staticStyle: { "font-size": "12px" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.form.carType == 1 ? "Truck" : "Mini Van"
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v("License plate:\n              "),
                          _c(
                            "span",
                            {
                              staticClass: "car",
                              staticStyle: { "font-size": "12px" },
                            },
                            [_vm._v(_vm._s(_vm.form.carNum))]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _c("span", { staticClass: "order" }, [
                    _vm._v(_vm._s(_vm.form.takeNum)),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v("Remaining buckets")]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _c("span", { staticClass: "order" }, [
                    _vm._v(_vm._s(_vm.form.orderResidueNum)),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v("Remaining order buckets")]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.orderType
            ? _c(
                "div",
                {
                  staticClass: "delivery-box",
                  staticStyle: { "grid-template-columns": "1fr 1fr 1fr 1fr" },
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "44px",
                      height: "44px",
                      "border-radius": "50%",
                      margin: "0 auto",
                    },
                    attrs: { src: require("@/assets/imgs/car.svg") },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "text" }, [
                    _c("span", { staticClass: "car" }, [
                      _vm._v(
                        _vm._s(_vm.form.carType == 1 ? "Truck" : "Mini Van")
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("Car model")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text" }, [
                    _c("span", { staticClass: "car" }, [
                      _vm._v(_vm._s(_vm.form.carNum)),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("License plate")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text" }, [
                    _c("span", { staticClass: "order" }, [
                      _vm._v(_vm._s(_vm.form.allNum) + " items"),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("Total number of orders")]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              !_vm.orderType
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Please select transfer order type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "group",
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: 1 } }, [
                            _vm._v("Change date"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-radio-button",
                            {
                              staticStyle: { margin: "0 10px" },
                              attrs: { label: 2 },
                            },
                            [_vm._v("Change driver")]
                          ),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: 3 } }, [
                            _vm._v("Turn barrel"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.form.type == 1
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "deliveryTime",
                        label: _vm.orderType
                          ? "Please select a change date"
                          : "",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                          format: "yyyy-MM-dd",
                          placeholder: "Please select a change date",
                        },
                        model: {
                          value: _vm.form.deliveryTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "deliveryTime", $$v)
                          },
                          expression: "form.deliveryTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _vm.form.type == 2
                            ? _c(
                                "el-form-item",
                                { attrs: { prop: "driverId" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder:
                                          "Please select the assigned driver",
                                        filterable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleDriverChange(
                                            $event,
                                            "driverList"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.form.driverId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "driverId", $$v)
                                        },
                                        expression: "form.driverId",
                                      },
                                    },
                                    _vm._l(_vm.driverList, function (item) {
                                      return _c("el-option", {
                                        key: item.uid,
                                        attrs: {
                                          label: item.account,
                                          value: item.uid,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.form.type == 3
                            ? _c(
                                "el-form-item",
                                { attrs: { prop: "driverId" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder:
                                          "Please select the assigned driver",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.form.driverId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "driverId", $$v)
                                        },
                                        expression: "form.driverId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.transDriverList,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.uid,
                                          attrs: {
                                            label: item.account,
                                            value: item.uid,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.form.driverId && !_vm.driver.carId && _vm.form.type == 2
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "transCarId",
                                    required: "",
                                    label: "Please select the assigned car",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder:
                                          "Please select the assigned car",
                                        filterable: "",
                                        clearable: false,
                                      },
                                      model: {
                                        value: _vm.form.transCarId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "transCarId", $$v)
                                        },
                                        expression: "form.transCarId",
                                      },
                                    },
                                    _vm._l(_vm.carList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          value: item.id,
                                          label:
                                            "License plate:" +
                                            item.carNum +
                                            "  Car model" +
                                            (item.carType == 1
                                              ? "Truck"
                                              : "Mini Van"),
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _vm.form.type == 3
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "line-height": "36px",
                                    color: "#606266",
                                    "font-weight": "600",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              Turn barrel info\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.form.productList &&
                              _vm.form.productList.length > 0
                                ? [
                                    _c(
                                      "el-table",
                                      {
                                        attrs: {
                                          data: _vm.form.productList,
                                          border: "",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "Product name",
                                            prop: "productName",
                                            "min-width": "100",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "Number",
                                            "min-width": "100",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var row = ref.row
                                                  var $index = ref.$index
                                                  return [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "productList." +
                                                            $index +
                                                            ".number",
                                                          rules:
                                                            _vm.rules.number,
                                                        },
                                                      },
                                                      [
                                                        _c("el-input-number", {
                                                          attrs: {
                                                            max: row.max,
                                                          },
                                                          model: {
                                                            value: row.number,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                row,
                                                                "number",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "row.number",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "red",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "max:" +
                                                                _vm._s(row.max)
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1863199978
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.type != 3
            ? _c(
                "div",
                { staticClass: "flex-row-bet query_select_all" },
                [
                  _c("div", [_vm._v("Please select an order for delivery")]),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      on: { change: _vm.handleCheckAll },
                      model: {
                        value: _vm.checkedAll,
                        callback: function ($$v) {
                          _vm.checkedAll = $$v
                        },
                        expression: "checkedAll",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.checkedAll ? "cancel selection" : "select all"
                        )
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form.type != 3
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "grid",
                    "grid-template-columns": "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                    "grid-gap": "5px",
                  },
                },
                _vm._l(_vm.form.orderList, function (item, i) {
                  return _c(
                    "el-popover",
                    {
                      key: i,
                      staticClass: "item",
                      attrs: {
                        width: "350",
                        "open-delay": 500,
                        trigger: "hover",
                        effect: "light",
                        placement: "top-start",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "a",
                                { staticClass: "label", attrs: { href: "#" } },
                                [_vm._v("ORDER ID:")]
                              ),
                              _c("el-link", { attrs: { type: "primary" } }, [
                                _vm._v(_vm._s(item.orderId)),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", [
                            _c(
                              "a",
                              { staticClass: "label", attrs: { href: "#" } },
                              [_vm._v("USER INFO:")]
                            ),
                            _vm._v(" "),
                            _c("a", { attrs: { href: "#" } }, [
                              _vm._v(_vm._s(item.userAddress)),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tag",
                        {
                          staticStyle: {
                            background: "#ebebeb",
                            "border-color": "#ebebeb",
                            color: "#000",
                            height: "35px",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center",
                          },
                          style:
                            item.status == 3 || item.status == 4
                              ? "background: rgb(232, 244, 255);border-color: rgb(232, 244, 255);"
                              : "",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: {
                                disabled: item.status == 3 || item.status == 4,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleChange($event, i)
                                },
                              },
                              model: {
                                value: item.checked,
                                callback: function ($$v) {
                                  _vm.$set(item, "checked", $$v)
                                },
                                expression: "item.checked",
                              },
                            },
                            [_vm._v("Order " + _vm._s(item.sort))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("form")
                },
              },
            },
            [_vm._v("Confirm")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("changeLoadingVehicle", {
        ref: "loadCar",
        attrs: { deliveryDate: _vm.deliveryDate },
        on: { update: _vm.handleUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
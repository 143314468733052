var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "editable-cell " + _vm.type },
    [
      !_vm.editable
        ? [
            _c(
              "div",
              {
                class: "text-overflow " + _vm.type,
                style: { color: _vm.color },
              },
              [
                _vm.type === "text"
                  ? _c("div", [_vm._v(_vm._s(_vm.getValue()))])
                  : _c("div", { domProps: { innerHTML: _vm._s(_vm.value) } }),
              ]
            ),
          ]
        : [
            _c("el-input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.type === "text",
                  expression: "type === 'text'",
                },
              ],
              ref: "myInput",
              class: _vm.editable ? "border" : "",
              attrs: {
                value: _vm.value,
                readOnly: !_vm.editable,
                focus: _vm.editable,
                type: _vm.inputType,
              },
              on: {
                input: _vm.handleChange,
                change: _vm.check,
                blur: _vm.check,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.check($event)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.type === "textarea",
                    expression: "type === 'textarea'",
                  },
                ],
              },
              [
                _c("el-input", {
                  ref: "textarea",
                  class: _vm.editable ? "border" : "",
                  attrs: {
                    value: _vm.value,
                    readOnly: !_vm.editable,
                    focus: _vm.editable,
                    autosize: { minRows: 2 },
                    type: "textarea",
                  },
                  on: {
                    input: _vm.handleChange,
                    change: _vm.check,
                    blur: _vm.check,
                  },
                }),
              ],
              1
            ),
          ],
      _vm._v(" "),
      _c("img", {
        staticStyle: { "margin-top": "6px" },
        attrs: { src: require("@/assets/imgs/edit.svg") },
        on: { click: _vm.handleClick },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "div",
        { staticClass: "table-header-wrapper" },
        [
          _c(
            "el-form",
            { attrs: { inline: "", size: "small" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-input", {
                    staticClass: "selWidth",
                    attrs: {
                      placeholder: "Please enter product name",
                      size: "small",
                      clearable: "",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getList($event)
                      },
                    },
                    model: {
                      value: _vm.tableFrom.keywords,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableFrom, "keywords", $$v)
                      },
                      expression: "tableFrom.keywords",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    size: "small",
                    type: "primary",
                  },
                  on: { click: _vm.getList },
                },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("Add new product")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            size: "mini",
            border: "",
            "highlight-current-row": true,
            "header-cell-style": {
              fontWeight: "bold",
              background: "#f8f8f9",
              color: "#515a6e",
              height: "40px",
            },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Product photo", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: scope.row.productImg,
                            "preview-src-list": [scope.row.productImg],
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Product name",
              prop: "productName",
              "min-width": "120",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "price",
              label: "Price",
              "min-width": "80",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "sales",
              label: "Sales Volume",
              "min-width": "100",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Status", "min-width": "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      staticStyle: { "text-align": "left" },
                      attrs: {
                        "active-value": true,
                        "inactive-value": false,
                        "active-text": "Active",
                        "inactive-text": "Inactive",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onchangeIsShow(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.isShow,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "isShow", $$v)
                        },
                        expression: "scope.row.isShow",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Operation", "min-width": "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "primary-border",
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(row.productId)
                          },
                        },
                      },
                      [_vm._v("Delete")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("Edit")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-edit", {
        ref: "modalForm",
        attrs: { storeId: _vm.storeId },
        on: { ok: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
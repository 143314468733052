"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsApiLoader = require("@googlemaps/js-api-loader");
var _changeDeliverySingle = _interopRequireDefault(require("./components/changeDeliverySingle"));
var _order = require("@/api/order");
var _Reordering = _interopRequireDefault(require("./components/Reordering"));
var _orderDetails = _interopRequireDefault(require("./orderDetails.vue"));
var _changeDeliveryModal = _interopRequireDefault(require("./components/changeDeliveryModal"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 权限判断函数
var loader = new _jsApiLoader.Loader({
  apiKey: "AIzaSyB0KvRZORCJ-XVdwPgqvfYpl70ahSD8KJ8",
  //api的key
  version: "weekly",
  //版本
  libraries: ["places", "drawing"] //插件库
});
var geocoder;
var map;
var markers = [];
var infowindow;
var service;
var _default = exports.default = {
  components: {
    changeDeliveryModal: _changeDeliveryModal.default,
    Reordering: _Reordering.default,
    changeDeliverySingle: _changeDeliverySingle.default,
    orderDetails: _orderDetails.default
  },
  name: "Dashboard",
  data: function data() {
    return {
      authStatus: null,
      orderId: null,
      authHost: "",
      detail: {},
      loading: true,
      deliveryDate: new Date(),
      authQueryStatus: false
    };
  },
  mounted: function mounted() {
    window.show = this.show;
  },
  created: function created() {
    this.getDetail();
  },
  methods: {
    getOrderStatus: function getOrderStatus(t) {
      var obj = {
        0: "pending",
        1: "pending",
        2: "on route",
        3: "complete",
        4: "complete",
        8: "canceled",
        9: "exception"
      };
      return obj[t];
    },
    handleRouterBack: function handleRouterBack() {
      this.$router.go(-1);
    },
    handleSetCenter: function handleSetCenter(e, order) {
      e.stopPropagation();
      console.log(order);
      var pos = {
        lat: Number(order.latitude),
        lng: Number(order.longitude)
      };
      map.setCenter(pos);
      this.setMarker(order);
    },
    getDetail: function getDetail() {
      var _this = this;
      this.loading = true;
      this.deliveryDate = new Date(this.$route.query.date);
      (0, _order.dayDetail)(this.$route.query).then(function (res) {
        _this.detail = res;
        _this.loading = false;
        _this.initMap();
      }).catch(function (e) {
        _this.$message.error("timeout! please refresh this page");
      });
    },
    changeDelivery: function changeDelivery(item) {
      this.$refs["changeDeliveryForm"].open(item);
    },
    goto: function goto(id) {
      this.orderId = id;
      this.$refs.orderDetail.show(id);
    },
    checkPermi: _permission.checkPermi,
    initMap: function initMap() {
      var _this2 = this;
      var that = this;
      var mapOptions = {
        center: {
          lat: 22.602,
          lng: 114.043
        },
        zoom: 13,
        streetViewControl: false
      };
      loader.load().then(function (google) {
        var MarkerWithLabel = require("markerwithlabel")(google.maps);
        _this2.google = google;
        map = new google.maps.Map(document.getElementById("map"), mapOptions);
        _this2.googleMap = map;
        geocoder = new google.maps.Geocoder();
        // service 地点查询类
        service = new google.maps.places.PlacesService(map);
        infowindow = new google.maps.InfoWindow({
          // 地图信息窗口
          content: ""
        });
        var orderList = _this2.detail.orderList;
        if (orderList.length > 0) {
          var noData = [null, undefined, ""];
          if (noData.includes(orderList[0].latitude) || noData.includes(orderList[0].longitude)) {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(function (position) {
                var pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                };
                map.setCenter(pos);
              }, function () {
                // console.log("未授权地理位置");
                // that.handleLocationError(true, infowindow, map.getCenter());
              });
            } else {
              that.handleLocationError(false, infowindow, map.getCenter());
            }
          } else {
            var pos = {
              lat: Number(orderList[0].latitude),
              lng: Number(orderList[0].longitude)
            };
            map.setCenter(pos);
          }
          _this2.setMarker();
        }
      }).catch(function (e) {
        _this2.$message.error("timed out, please refresh again");
      });
      this.loading = false;
    },
    setMarker: function setMarker(order) {
      this.clearMarkers();
      try {
        var MarkerWithLabel = require("markerwithlabel")(google.maps);
        infowindow = new google.maps.InfoWindow({
          // 地图信息窗口
          content: ""
        });
        var orderList = this.detail.orderList;
        if (orderList.length > 0) {
          //已配送
          var markerSvg = window.btoa("\n                <svg width=\"39\" height=\"48\" viewBox=\"0 0 39 48\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path d=\"M32.2972 6.50512L32.3033 6.51144L32.3095 6.51765L32.4293 6.63742C35.6615 9.99257 37.7008 14.6693 37.7008 19.8316C37.7008 25.1234 35.6591 29.7993 32.4293 33.152L32.3095 33.2718L32.303 33.2783L32.2966 33.2849L19.504 46.5558L6.70805 33.1618L6.7001 33.1535L6.69197 33.1454L6.57213 33.0256C3.34003 29.6705 1.30078 24.9937 1.30078 19.8315C1.30078 14.666 3.34275 9.9866 6.5785 6.63099L6.68468 6.52088L6.75308 6.38407L6.80593 6.27838C10.1554 2.98227 14.5368 1 19.5008 1C24.5191 1 28.9376 3.02557 32.2972 6.50512ZM11.806 18.5685C11.806 22.8738 15.2497 26.5158 19.5008 26.5158C23.7519 26.5158 27.1955 22.8738 27.1955 18.5684C27.1955 14.263 23.7519 10.6211 19.5008 10.6211C16.9861 10.6211 14.8187 11.9508 13.4045 13.7882C12.3831 15.1024 11.806 16.7077 11.806 18.5685Z\" fill=\"#F44C35\" stroke=\"white\" stroke-width=\"2\"/>\n                </svg>");
          // 未配送图片
          var markerSvgNone = window.btoa("\n                <svg width=\"49\" height=\"48\" viewBox=\"0 0 49 48\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path d=\"M36.2972 6.50512L36.3033 6.51144L36.3095 6.51765L36.4293 6.63742C39.6615 9.99257 41.7008 14.6693 41.7008 19.8316C41.7008 25.1234 39.6591 29.7993 36.4293 33.152L36.3095 33.2718L36.303 33.2783L36.2966 33.2849L23.504 46.5558L10.708 33.1618L10.7001 33.1535L10.692 33.1454L10.5721 33.0256C7.34003 29.6705 5.30078 24.9937 5.30078 19.8315C5.30078 14.666 7.34275 9.9866 10.5785 6.63099L10.6847 6.52088L10.7531 6.38407L10.8059 6.27838C14.1554 2.98227 18.5368 1 23.5008 1C28.5191 1 32.9376 3.02557 36.2972 6.50512ZM15.806 18.5685C15.806 22.8738 19.2497 26.5158 23.5008 26.5158C27.7519 26.5158 31.1955 22.8738 31.1955 18.5684C31.1955 14.263 27.7519 10.6211 23.5008 10.6211C20.9861 10.6211 18.8187 11.9508 17.4045 13.7882C16.3831 15.1024 15.806 16.7077 15.806 18.5685Z\" fill=\"#0072E2\" stroke=\"white\" stroke-width=\"2\"/>\n                </svg>\n                ");
          var lineData = [];
          orderList.forEach(function (item, i) {
            lineData.push(new google.maps.LatLng(Number(item.latitude), Number(item.longitude)));
            var selected = false;
            if (order !== undefined && item.orderId == order.orderId) {
              selected = true;
            }
            var title = "<div>\n              <div>\n                <span class=\"label\">ORDER ID:</span>\n                <span style=\"color:#0072E2\">".concat(item.orderId, "</span>\n              </div>\n              <div>\n                <span class=\"label\">USER INFO:</span>\n                <span>").concat(item.userAddress, "</span>\n              </div>\n              <div class=\"btn-box\" style=\"margin-top: 5px;\">\n                <span class=\"btn\" style=\"background-color: #0072E2;color: #fff;padding:3px 10px;border-radius:5px\" onclick=\"show('2',").concat(item, ")\">Change delivery</span>\n                <span class=\"btn\" style=\"background-color: #0072E2;color: #fff;padding:3px 10px;border-radius:5px\" onclick=\"show('3',").concat(item, ")\">Adjust sorting</span>\n                <span class=\"btn\" style=\"background-color: #0072E2;color: #fff;padding:3px 10px;border-radius:5px\" onclick=\"show('1',").concat(item, ")\">Order details</span>\n              </div>\n            </div>");
            var marker = new MarkerWithLabel({
              position: {
                lat: Number(item.latitude),
                lng: Number(item.longitude)
              },
              icon: {
                url: "data:image/svg+xml;base64,".concat(item.status == 4 ? markerSvgNone : markerSvg),
                //配送完成/未完成图标
                scaledSize: new google.maps.Size(100, 40),
                fillColor: "red"
              },
              draggable: false,
              map: map,
              labelContent: "".concat(item.sort),
              labelAnchor: new google.maps.Point(30, 0),
              labelClass: "labels",
              labelStyle: {
                padding: "5px 5px",
                textAlign: "center",
                backgroundColor: selected ? item.status == 9 ? "#ee502f" : "#0072E2" : "#fff",
                width: "60px",
                color: selected ? "#ffffff" : item.status == 9 ? "#ee502f" : "#0072E2",
                fontSize: "20px",
                border: item.status == 9 ? "1px solid #ee502f" : "1px solid #0072E2",
                borderRadius: "4px",
                borderColor: item.status == 9 ? "ee502f" : "#0072E2"
              },
              zIndex: selected ? 9999 : 1
            });
            markers.push(marker);
            new google.maps.event.addListener(marker, "click", function (e) {
              infowindow.close();
              infowindow.setContent(title);
              infowindow.open(map, marker);
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    clearMarkers: function clearMarkers() {
      for (var i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }
      markers = []; // 清空数组
    },
    //图层操作
    show: function show(type, orderInfo) {
      if (type == 2) {
        var info = Object.assign({}, orderInfo, {
          type: type,
          show: true,
          carId: this.detail.carId
        });
        this.$refs["changeDeliverysingleForm"].open(info);
      }
      if (type == 1) {
        var orderId = orderInfo.orderId;
        this.goto(orderId);
      }
      if (type == 3) {
        this.$refs["ReorderingForm"].open(this.detail);
      }
    },
    handleLocationError: function handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(browserHasGeolocation ? "Error: The Geolocation service failed." : "Error: Your browser doesn't support geolocation.");
      infoWindow.open(map);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        title: "Complete order",
        visible: _vm.dialogVisible,
        width: "800px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-position": "top" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Returned bottle info" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, max: _vm.receiveMax },
                        model: {
                          value: _vm.form.receiveNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "receiveNum", $$v)
                          },
                          expression: "form.receiveNum",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(" Max return " + _vm._s(_vm.receiveMax)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: {
                data: _vm.detail.orderInfo,
                border: "",
                "header-cell-style": {
                  fontWeight: "bold",
                  background: "#E8F4FF",
                  color: "#515a6e",
                  height: "40px",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "Goods" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "flex align-center" }, [
                          _c(
                            "div",
                            { staticClass: "demo-image__preview mr10" },
                            [
                              _c("el-image", {
                                attrs: {
                                  src: scope.row.info.image,
                                  "preview-src-list": [scope.row.info.image],
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex align-center" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(scope.row.info.productName) +
                                "\n            "
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Delivery quantity", width: "240" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input-number", {
                          attrs: { min: 0 },
                          model: {
                            value: row.info.payNum,
                            callback: function ($$v) {
                              _vm.$set(row.info, "payNum", $$v)
                            },
                            expression: "row.info.payNum",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "unit price", width: "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.info.price) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleDone } },
            [_vm._v("Complete order")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
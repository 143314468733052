"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var storeApi = _interopRequireWildcard(require("@/api/store.js"));
var _vuex = require("vuex");
var _validate = require("@/utils/validate");
var _utils = require("@/utils");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    areaId: {
      type: [Number, String]
    }
  },
  data: function data() {
    return {
      form: {
        oldPrice: "",
        price: ""
      },
      loading: false,
      constants: this.$constants,
      dialogVisible: false,
      listData: {
        list: [],
        loading: false
      },
      listPram: {
        page: 1,
        limit: this.$constants.page.limit[0]
      },
      currentIndex: 0,
      rules: {
        price: [{
          required: true,
          message: "please enter price",
          trigger: ["blur", "change"]
        }]
      },
      roleList: [],
      menuList: [],
      storeData: [],
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        // 0=创建，1=编辑
        editData: {}
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(["storeId"])),
  mounted: function mounted() {
    this.handleList();
  },
  methods: {
    handleSearch: function handleSearch() {
      this.listPram.page = 1;
      this.handleList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handleList();
      this.handleGetRoleList(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handleList();
      this.handleGetRoleList(this.listPram);
    },
    handleList: function handleList() {
      var _this = this;
      this.listPram.storeId = this.storeId;
      if (this.areaId) {
        this.listPram.areaId = this.areaId;
      }
      this.listData.loading = true;
      storeApi.getStockProduct(this.listPram).then(function (data) {
        _this.listData.list = data;
      }).finally(function () {
        _this.listData.loading = false;
      });
    },
    handlerUpdate: function handlerUpdate(record, index) {
      this.form = Object.assign({}, record);
      this.currentIndex = index;
      this.dialogVisible = true;
    },
    close: function close(form) {
      this.$refs[form].resetFields();
      this.dialogVisible = false;
    },
    handlerSubmit: (0, _validate.Debounce)(function (form) {
      var _this2 = this;
      this.loading = true;
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        if (_this2.form.areaId) {
          storeApi.productUpdate(_this2.form).then(function (res) {
            _this2.handleList();
            _this2.dialogVisible = false;
            _this2.loading = false;
          }).catch(function (e) {
            _this2.loading = false;
          });
        } else {
          var index = _this2.currentIndex;
          var list = (0, _utils.deepClone)(_this2.listData.list);
          list[index].price = _this2.form.price;
          _this2.listData.list = list;
          _this2.dialogVisible = false;
          _this2.loading = false;
        }
      });
    })
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var orderApi = _interopRequireWildcard(require("@/api/order.js"));
var _vuex = require("vuex");
var _validate = require("@/utils/validate");
var systemMerchantApi = _interopRequireWildcard(require("@/api/merchant.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  // name: "edit"
  components: {},
  props: {
    isCreate: {
      type: Number,
      required: true
    },
    editData: {
      type: Object,
      default: function _default() {
        return {
          rules: []
        };
      }
    }
  },
  data: function data() {
    return {
      constants: this.$constants,
      pram: {
        description: null,
        disposeRemark: null,
        disposeStatus: null,
        id: null,
        orderNo: null,
        storeId: null,
        storeName: null,
        type: null
      },
      rules: {},
      loading: false,
      listData: []
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(["storeId"])),
  mounted: function mounted() {
    this.initEditData();
    this.handleGetAdminList();
  },
  methods: {
    handleGetAdminList: function handleGetAdminList() {
      var _this = this;
      systemMerchantApi.merchantList(this.listPram).then(function (data) {
        _this.listData = data.list;
      });
    },
    close: function close() {
      this.$emit("hideEditDialog");
    },
    initEditData: function initEditData() {
      if (this.isCreate !== 1) return;
      var _this$editData = this.editData,
        description = _this$editData.description,
        disposeRemark = _this$editData.disposeRemark,
        disposeStatus = _this$editData.disposeStatus,
        disposeStatusNew = _this$editData.disposeStatusNew,
        id = _this$editData.id,
        orderNo = _this$editData.orderNo,
        storeId = _this$editData.storeId,
        storeName = _this$editData.storeName,
        type = _this$editData.type;
      this.pram.description = description;
      this.pram.disposeRemark = disposeRemark;
      this.pram.disposeStatus = disposeStatusNew;
      this.pram.id = id;
      this.pram.orderNo = orderNo;
      this.pram.storeId = storeId;
      this.pram.storeName = storeName;
      this.pram.type = type;
    },
    handlerSubmit: (0, _validate.Debounce)(function (form) {
      var _this2 = this;
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        _this2.handlerEdit();
      });
    }),
    handlerEdit: function handlerEdit() {
      var _this3 = this;
      this.loading = true;
      orderApi.dispose(this.pram).then(function (data) {
        _this3.$message.success("successful");
        _this3.$emit("hideEditDialog");
        _this3.loading = false;
      }).catch(function () {
        _this3.loading = false;
      });
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative", staticStyle: { "padding-top": "0" } },
    [
      _c(
        "div",
        { staticClass: "page-wrapper", attrs: { bordered: false } },
        [_c("message-list")],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
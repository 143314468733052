function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { orderDetailApi, orderFinish } from "@/api/order";
export default {
  components: {},
  props: {},
  data: function data() {
    return {
      detail: {},
      loading: false,
      orderId: null,
      dialogVisible: false,
      receiveMax: 0,
      form: {
        receiveNum: 0
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    open: function open(detail) {
      var total = 0;
      var orderInfo = detail.orderInfo.map(function (item) {
        total += Number(item.info.payNum);
        return _objectSpread(_objectSpread({}, item), {}, {
          info: _objectSpread(_objectSpread({}, item.info), {}, {
            cartNum: item.info.payNum
          })
        });
      });
      this.receiveMax = detail.abnormalNum;
      this.form.receiveNum = 0;
      this.detail = Object.assign({}, detail, {
        orderInfo: orderInfo
      });
      this.dialogVisible = true;
    },
    getTotal: function getTotal(price, quantity) {
      return price * quantity.toFixed(2);
    },
    getOrderStatus: function getOrderStatus(t) {
      var obj = {
        0: "pending",
        1: "pending",
        2: "on route",
        3: "complete",
        4: "complete",
        8: "canceled",
        9: "exception"
      };
      return obj[t];
    },
    handleDone: function handleDone() {
      var _this = this;
      //完成订单
      this.loading = true;
      var receiveNum = this.form.receiveNum;
      var finishNum = 0;
      var infoList = this.detail.orderInfo.map(function (item) {
        finishNum += item.info.payNum;
        return {
          id: item.id,
          refundNum: 0,
          deliveryNum: item.info.payNum,
          cartNum: item.info.cartNum,
          price: item.info.price,
          productId: item.info.productId,
          unitName: item.info.unitName,
          weight: ""
        };
      });
      var params = {
        receiveNum: receiveNum,
        finishNum: finishNum,
        orderNo: this.detail.orderId,
        infoList: infoList
      };
      orderFinish(params).then(function (res) {
        _this.$message.success("Update successfully");
        _this.$emit("update");
        _this.dialogVisible = false;
      }).finally(function () {
        _this.loading = false;
      });
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
var _productEdit = _interopRequireDefault(require("./productEdit.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "shopList",
  components: {
    productEdit: _productEdit.default
  },
  props: {
    storeId: {
      type: [Number, String]
    }
  },
  data: function data() {
    return {
      props: {
        children: "child",
        label: "name",
        value: "id",
        emitPath: false
      },
      deliveryVisible: false,
      headeNum: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      form: {
        productId: "",
        number: ""
      },
      loading: false,
      tableFrom: {
        page: 1,
        limit: 10,
        cateId: "",
        keywords: ""
        // type: '1'
      },
      productId: "",
      categoryList: [],
      merCateList: [],
      shopList: [],
      objectUrl: process.env.VUE_APP_BASE_API,
      dialogVisible: false,
      multipleSelection: []
    };
  },
  mounted: function mounted() {},
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.storeId = this.storeId;
      (0, _store.productLstApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.$emit("change", res.total);
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        _this.$message.error(res.message);
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleBatchDelete: function handleBatchDelete() {
      var ids = this.multipleOrderSelection.map(function (item) {
        return item.id;
      });
      var that = this;
      this.$confirm("Are you sure you want to delete the selected products?", "Tips", {
        confirmButtonText: "delete",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        (0, _store.productBatchDelete)({
          ids: ids.join(',')
        }).then(function (res) {
          that.$message.success("Delete successfully");
          that.getList();
        });
      });
    },
    handleAdd: function handleAdd() {
      this.$refs.modalForm.add();
    },
    handleEdit: function handleEdit(record) {
      this.$refs.modalForm.edit(record);
    },
    handleDelete: function handleDelete(id, type) {
      var that = this;
      this.$confirm("Are you sure you want to delete the product?", "Tips", {
        confirmButtonText: "delete",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        (0, _store.productDeleteApi)(id, "delete").then(function () {
          that.$message.success("Delete successfully");
          that.getList();
        });
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this2 = this;
      (0, _store.storeAddproduct)({
        productId: row.productId,
        storeId: this.storeId,
        type: row.isShow ? 0 : 1
      }).then(function () {
        _this2.getList();
      }).catch(function () {
        row.isShow = !row.isShow;
      });
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _order = require("@/api/order");
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Reordering",
  props: {
    orderId: String
  },
  components: {
    draggable: _vuedraggable.default
  },
  data: function data() {
    return {
      visible: false,
      loading: false,
      isLoading: false,
      driverList: [],
      tagsList: [],
      drag: false,
      myArray: [{
        people: "cn",
        id: 1,
        name: "www.itxst.com"
      }, {
        people: "cn",
        id: 2,
        name: "www.baidu.com"
      }, {
        people: "cn",
        id: 3,
        name: "www.taobao.com"
      }, {
        people: "us",
        id: 4,
        name: "www.google.com"
      }],
      form: {
        type: 1
      },
      rules: {
        deliveryTime: [{
          required: true,
          message: "Please choose",
          trigger: ["blur", "change"]
        }],
        type: [{
          required: true,
          message: "",
          trigger: ["blur", "change"]
        }],
        driverId: [{
          required: true,
          message: "Please select a driver",
          trigger: ["blur", "change"]
        }]
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    chChange: function chChange() {
      this.$message.success("Withdrawal successful");
      this.$set(this.form, "orderList", this.tagsList);
    },
    onChoose: function onChoose() {
      this.isLoading = true;
    },
    onDraggableUpdate: function onDraggableUpdate(e) {
      var _this = this;
      setTimeout(function () {
        _this.isLoading = false;
        // //老位置
        // const oldIndex = e.oldIndex
        // //新位置
        // const newIndex = e.newIndex
        // const newSort = this.tagsList[e.newIndex].sort
        // this.tagsList[e.newIndex].sort = this.tagsList[e.oldIndex].sort
        // this.tagsList[e.oldIndex].sort = newSort
        // this.$message.success('顺序变更成功!')
      }, 5000);
    },
    open: function open(item) {
      this.tagsList = item.orderList;
      // item.newOrderList=item.orderList
      item.type = 1;
      this.form = JSON.parse(JSON.stringify(item));
      this.visible = true;
    },
    // 提交
    handlerSubmit: function handlerSubmit(name) {
      var _this2 = this;
      this.$refs[name].validate(function (valid) {
        if (!valid) return;
        _this2.loading = true;
        var detail = JSON.parse(JSON.stringify(_this2.form));
        var data = {
          carId: _this2.form.carId,
          deliveryTime: (0, _moment.default)(_this2.form.deliveryTime).format("YYYY-MM-DD"),
          driverId: _this2.form.driverId,
          num: _this2.form.num,
          orderList: detail.orderList.filter(function (e, i) {
            e.sort = i + 1;
            return e;
          })
        };
        data.orderIds = [];
        _this2.form.orderList.map(function (e) {
          data.orderIds.push(e.id);
        });
        (0, _order.sortOrder)(data).then(function (async) {
          _this2.$message.success("Update successfully");
          _this2.visible = false;
          _this2.$refs[name].resetFields();
          _this2.$emit("update");
          _this2.loading = false;
        }).catch(function () {
          _this2.loading = false;
        });
      });
    },
    handleClose: function handleClose() {
      this.cancel("form");
    },
    cancel: function cancel(name) {
      this.visible = false;
      this.form = {};
      this.$refs[name].resetFields();
    }
  }
};
//
//
//
//
//
//
//
//

import messageList from "../archives/components/messageList.vue";
export default {
  components: {
    messageList: messageList
  }
};
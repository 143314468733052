import Vue from 'vue';
import ElementUI from 'element-ui';
Vue.use(ElementUI);

// ElementUI.Form.props.labelWidth.default = '120px'
// ElementUI.Form.props.inlineMessage.default = true

// 默认不显示斑马纹
ElementUI.Table.props.stripe.default = false;
// ElementUI.DatePicker.props.value.default = false
ElementUI.Dialog.props.width.default = '500px';
// 默认不可以通过点击 modal 关闭 Dialog
ElementUI.Dialog.props.closeOnClickModal.default = false;
// 默认关闭 Dialog 的时候自动销毁
// ElementUI.Dialog.props.destroyOnClose.default = true
// 默认关闭 Dialog 的时候自动销毁
ElementUI.Dialog.props.beforeClose.default = function (done) {
  if (this.$parent && this.$parent.controller) {
    this.$parent.controller.close();
  }
  done();
};
// ElementUI.Input.props.clearable.default=true
ElementUI.Select.props.clearable.default = true;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _vuex = require("vuex");
var orderApi = _interopRequireWildcard(require("@/api/order.js"));
var _validate = require("@/utils/validate");
var _index = _interopRequireDefault(require("@/components/select/index"));
var _export = require("echarts/lib/export");
var subscribeApi = _interopRequireWildcard(require("@/api/subscribe.js"));
var _store = _interopRequireDefault(require("@/store"));
var _utils = require("@/utils");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    selectNew: _index.default
  },
  props: {},
  data: function data() {
    return {
      uid: null,
      optionList: [],
      orderVisible: false,
      dataSelected: null,
      pagination: {
        show: true,
        current: 1,
        size: 5,
        total: 0,
        layout: "prev, next"
      },
      period: null,
      loading: false,
      constants: this.$constants,
      pram: {
        storeId: null
      },
      rules: {
        amount: [{
          required: true,
          message: "refund amount",
          trigger: ["blur", "change"]
        }],
        refundReason: [{
          required: true,
          message: "refund notes",
          trigger: ["blur", "change"]
        }]
      },
      listData: [],
      payPrice: 999,
      addressData: [],
      addressId: null,
      address: {},
      storeList: [],
      shopList: [],
      selectStore: "0",
      tmpId: null,
      tmpInfo: {},
      confirmLoading: false,
      isGoodsEdit: false,
      tmpProductList: [],
      title: "",
      subType: "week"
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(["storeId"])), {}, {
    periodTypeText: function periodTypeText() {
      var periodType = this.subType;
      var text = "Week";
      if (periodType === "day") {
        text = "Day";
      } else {
        text = "Week";
      }
      return text;
    },
    total: function total() {
      var _this = this;
      var num = 0;
      if (this.storeList.length <= 0) return num;
      this.shopDetail.waterList.forEach(function (e) {
        num += e.number * e.price + Number(_this.shopDetail.depositAmt) * e.number;
      });
      num = num ? num + Number(this.shopDetail.deliveryPrice) : num;
      return num.toFixed(2);
    },
    depositAmt: function depositAmt() {
      var _this2 = this;
      var num = 0;
      if (this.storeList.length <= 0) return num;
      this.shopDetail.waterList.forEach(function (e) {
        num += Number(_this2.shopDetail.depositAmt) * e.number;
      });
      return num.toFixed(2);
    },
    shopDetail: function shopDetail() {
      if (this.storeList.length <= 0) return {};else {
        var subType = this.storeList[this.selectStore].subType;
        this.subType = subType ? subType : "week";
        return this.storeList[this.selectStore];
      }
    },
    // 已选中列表
    selectedProductObj: function selectedProductObj() {
      var obj = {
        total: 0,
        list: []
      };
      var storeList = JSON.parse(JSON.stringify(this.storeList));
      var total = 0;
      var list = [];
      var m = Math.pow(10, 2);
      storeList.map(function (item) {
        item.waterList.map(function (child) {
          if (child.number > 0) {
            total += child.number * child.price;
            list.push({
              productId: child.id,
              cartNum: child.number,
              productName: child.title,
              price: child.price,
              unitName: child.unitName,
              image: child.image
            });
          }
        });
      });
      obj.total = total.toFixed(2);
      obj.list = list;
      return obj;
    },
    emptyText: function emptyText() {
      var length = this.addressData.length;
      var shopDetail = this.shopDetail;
      var text = "";
      if (length) {
        if (shopDetail.period) {
          text = "No data";
        } else {
          text = "Beyond delivery range";
        }
      } else {
        text = "No data";
      }
      return text;
    },
    periodList: function periodList() {
      var arr = [];
      if (!this.shopDetail) {
        arr = [];
      } else {
        if (this.shopDetail && this.shopDetail.period) {
          for (var i = 0; i < 6; i++) {
            var obj = {
              name: this.shopDetail.period * (i + 1) + "week",
              value: this.shopDetail.period * (i + 1)
            };
            arr.push(obj);
          }
        } else {
          arr = [];
        }
      }
      return arr;
    }
  }),
  mounted: function mounted() {},
  methods: {
    // 编辑/添加地址
    edit: function edit(row) {
      this.orderVisible = true;
      this.address = row.address;
      this.tmpId = row.tmpId;
      this.uid = row.uid;
      this.title = "Modify subscription";
      this.getTempDetail();
    },
    add: function add(row) {
      this.orderVisible = true;
      this.address = row.address;
      this.uid = row.uid;
      this.tmpId = null;
      this.title = "Add new subscription";
      this.getListAndProduct();
    },
    goodsEdit: function goodsEdit(row) {
      this.orderVisible = true;
      this.address = row.address;
      this.tmpId = row.tmpId;
      this.uid = row.uid;
      this.title = "Modify product";
      this.isGoodsEdit = true;
      this.getTempDetail(row.tmpInfo);
    },
    getTempDetail: function getTempDetail(info) {
      var _this3 = this;
      this.loading = true;
      subscribeApi.tmpDetail(this.tmpId).then(function (data) {
        _this3.tmpInfo = info ? Object.assign({}, info, {
          storeId: data.storeId
        }) : data;
        _this3.period = data.period;
        _this3.getListAndProduct(data.storeId);
      }).finally(function () {
        _this3.loading = false;
      });
    },
    checkPeriod: function checkPeriod() {
      var period = this.period;
      var areaPeriod = this.shopDetail.period;
      console.log(period, areaPeriod);
      if (period % areaPeriod !== 0) {
        this.period = "";
      }
      return period % areaPeriod == 0;
    },
    change: function change(e) {
      var _this4 = this;
      this.addressData.map(function (item) {
        if (e == item.id) {
          _this4.address = item;
        }
      });
      this.selectStore = "0";
      this.loading1 = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.getListAndProduct();
    },
    // 修改购物车数量
    update: function update(productId, type) {
      var shopDetail = this.shopDetail;
      var storeList = this.storeList;
      var list = storeList.map(function (item) {
        if (item.id == shopDetail.id) {
          item.waterList.map(function (child) {
            if (child.id == productId) {
              if (!child.number && !type) return;
              child.number = type ? child.number + 1 : child.number - 1;
            }
            return child;
          });
        }
        return item;
      });
      this.storeList = list;
      this.$forceUpdate();
    },
    //    获取水厂商品
    getListAndProduct: function getListAndProduct(storeId) {
      var _this5 = this;
      try {
        var pram = {
          latitude: this.address.latitude,
          longitude: this.address.longitude,
          uid: this.uid
        };
        (0, _user.listAndProduct)(pram).then(function (res) {
          res.list.map(function (item) {
            item.scoreAvg = item.scoreAvg || 0;
            item.depositAmt = item.depositAmt || 0;
            item.waterList.map(function (e) {
              e.number = 0;
            });
          });
          if (storeId) {
            _this5.storeList = res.list ? res.list.filter(function (item) {
              return item.id == storeId;
            }) : [];
            _this5.analyseData();
          } else {
            _this5.storeList = res.list || [];
          }
          _this5.pram.storeId = _this5.storeList[0].id;
          if (_this5.loading1) {
            _this5.loading1.close();
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    analyseData: function analyseData() {
      var isGoodsEdit = this.isGoodsEdit;
      var orderInfoList = isGoodsEdit ? this.tmpInfo.productInfo : this.tmpInfo.orderInfoList;
      var productIds = orderInfoList.map(function (item) {
        return item.productId;
      });
      var storeList = (0, _utils.deepClone)(this.storeList);
      var newList = storeList.map(function (item) {
        item.waterList.map(function (child) {
          if (productIds.indexOf(child.id) > -1) {
            child.number = isGoodsEdit ? orderInfoList[productIds.indexOf(child.id)].payNum : orderInfoList[productIds.indexOf(child.id)].cartNum;
          }
          return child;
        });
        return item;
      });
      this.storeList = newList;
      if (!this.tmpId) {
        this.period = storeList[0].period;
      }
    },
    Close: function Close() {
      this.selectStore = "0";
      this.orderVisible = false;
      this.storeList = [];
      this.address = {};
      this.addressId = null;
      this.period = null;
      this.subType = "week";
      this.isGoodsEdit = false;
    },
    handlerSubmit: (0, _validate.Debounce)(function (form) {
      var _this6 = this;
      var orderDetails = [];
      var detail = this.shopDetail;
      detail.waterList.map(function (e) {
        if (e.number) {
          orderDetails.push({
            productId: e.id,
            productNum: e.number
          });
        }
      });
      if (orderDetails.length <= 0) {
        this.$message.error("Please select the ordered product");
        return;
      }
      if (!this.period) {
        this.$message.error("Please select a subscription period");
        return;
      }
      var params = {
        orderDetails: orderDetails,
        areaId: detail.areaId,
        preOrderType: "shoppingCart",
        uid: this.uid
      };
      var that = this;
      this.$confirm("Are you sure you want to place the order?", "prompt", {
        confirmButtonText: "confirm",
        cancelButtonText: "cancel",
        type: "warning"
      }).then(function () {
        that.loading1 = that.$loading({
          lock: true,
          text: "Placing an order",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        (0, _user.buyNow)(params).then(function (res) {
          console.log(res);
          var preOrderNo = res.preOrderNo;
          var createParams = {
            addressId: that.address.id,
            preOrderNo: preOrderNo,
            payType: "app",
            shippingType: "1",
            payChannel: "app",
            useIntegral: true,
            isOneTime: false,
            couponId: 0,
            mark: "",
            uid: _this6.uid,
            period: _this6.period,
            subType: _this6.subType
          };
          (0, _user.create)(createParams).then(function (res1) {
            that.$message.success("success");
            that.$emit("ok");
            that.loading1.close();
            that.Close();
          }).catch(function () {
            that.loading1.close();
          });
        }).catch(function () {
          that.loading1.close();
        });
      });
    }),
    handleEdit: (0, _validate.Debounce)(function (form) {
      if (!this.selectedProductObj.list.length) {
        this.$message.error("Please select the ordered product");
        return;
      }
      if (this.isGoodsEdit) {
        this.handleGoodsEdit();
      } else {
        this.handleSubEdit();
      }
    }),
    handleSubEdit: function handleSubEdit() {
      var _this7 = this;
      var data = Object.assign({}, this.tmpInfo, {
        orderInfoList: this.selectedProductObj.list,
        period: this.period,
        tmpId: this.tmpId
      });
      var newArray = ["nextOrderDate", "orderInfoList", "period", "tmpId"];
      var params = {};
      for (var key in data) {
        if (newArray.indexOf(key) > -1) {
          params[key] = data[key];
        }
      }
      var that = this;
      that.loading1 = that.$loading({
        lock: true,
        text: "Modify subscription",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      subscribeApi.tmpUpdate(params).then(function (res) {
        _this7.$message.success("Update success");
        _this7.Close();
        _this7.$emit("ok");
      }).finally(function () {
        that.loading1.close();
      });
    },
    handleGoodsEdit: function handleGoodsEdit() {
      var _this8 = this;
      var that = this;
      var list = this.selectedProductObj.list.map(function (item) {
        return {
          image: item.image,
          payNum: item.cartNum,
          price: item.price,
          productId: item.productId,
          productName: item.productName,
          tmpId: _this8.tmpId,
          unitName: item.unitName
        };
      });
      var data = Object.assign({}, this.tmpInfo, {
        productInfo: JSON.stringify(list)
      });
      delete data.currentDate;
      that.loading1 = that.$loading({
        lock: true,
        text: "Modify product",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      subscribeApi.editChildTmp(data).then(function (res) {
        _this8.$message.success("Update success");
        _this8.Close();
        _this8.$emit("ok");
      }).finally(function () {
        that.loading1.close();
      });
    }
  }
};
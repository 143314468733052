var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        visible: _vm.visible,
        title: _vm.title,
        "before-close": _vm.close,
        "append-to-body": "",
        width: "695px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "end",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.handleUpdate },
                    },
                    [_vm._v("confirm")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("cancel"),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          staticClass: "form",
          attrs: {
            model: _vm.form,
            "status-icon": "",
            labelPosition: "top",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "realName", label: "Contact Person" } },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.form.realName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "realName", $$v)
                          },
                          expression: "form.realName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phone", label: "Mobile number" } },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "address", prop: "address" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "dialogWidth",
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "Please find location",
                            readOnly: "",
                          },
                          model: {
                            value: _vm.form.address,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "address", $$v)
                            },
                            expression: "form.address",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c(
                                "el-button",
                                { on: { click: _vm.googleShow } },
                                [_vm._v("Find location")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "spaceName", label: "Space name" } },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.form.spaceName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "spaceName", $$v)
                          },
                          expression: "form.spaceName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "bz", label: "Remark" } },
                    [
                      _c("el-input", {
                        staticStyle: {
                          background: "none",
                          border: "none",
                          "box-shadow": "none",
                          padding: "0",
                        },
                        attrs: {
                          placeholder: "",
                          type: "textarea",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "bz", label: "Detail notes" } },
                    [
                      _c("div", { staticClass: "remark" }, [
                        _c("div", [
                          _c("span", [
                            _vm._v(
                              "Note the detailed location or upload a photo"
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("1", "img")
                              },
                            },
                          },
                          [
                            _vm.form.img
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", { attrs: { src: _vm.form.img } }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "bz", label: "Detail notes" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.form.isDefault,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isDefault", $$v)
                            },
                            expression: "form.isDefault",
                          },
                        },
                        [_vm._v("set as the default address")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c("GoogleMap", { ref: "googleRef", on: { update: _vm.googleUpdate } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
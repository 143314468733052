var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: { size: _vm.size },
      on: { change: _vm.handleChange },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("el-input", {
            staticClass: "select-input",
            attrs: {
              size: _vm.size,
              "prefix-icon": "el-icon-search",
              placeholder: _vm.placeholder,
            },
            on: { change: _vm.handleFilter },
            model: {
              value: _vm.filterText,
              callback: function ($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText",
            },
          }),
          _vm._v(" "),
          _vm._l(_vm.options, function (o) {
            return _c("el-option", {
              key: o[_vm.value],
              attrs: { label: o[_vm.props.label], value: o[_vm.props.value] },
            })
          }),
          _vm._v(" "),
          _vm.pagination.show
            ? _c("el-pagination", {
                staticClass: "mt12 w100 base select-pagination",
                attrs: {
                  "current-page": _vm.pagination.current,
                  "page-size": _vm.pagination.size,
                  layout: _vm.pagination.layout,
                  total: _vm.pagination.total,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pagination, "current", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pagination, "current", $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.pagination.show
            ? _c("p", { staticClass: "page-size" }, [
                _vm._v("第" + _vm._s(_vm.pagination.current) + "/页"),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { productLstApi, productDeleteApi, categoryApi, putOnShellApi, storeAddproduct, offShellApi, productHeadersApi, productExportApi, restoreApi, productExcelApi, stockManager } from "@/api/store";
import { checkPermi } from "@/utils/permission"; // 权限判断函数
import { Debounce } from "@/utils/validate";
export default {
  name: "shopList",
  components: {},
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    var _ref;
    return _ref = {
      visible: false,
      props: {
        children: "child",
        label: "name",
        value: "id",
        emitPath: false
      },
      deliveryVisible: false,
      headeNum: []
    }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "visible", false), "listLoading", true), "tableData", {
      data: [],
      total: 0
    }), "form", {
      productId: "",
      number: ""
    }), "loading", false), "rules", {
      number: [{
        required: true,
        message: "Please enter the quantity of storage",
        trigger: ["blur", "change"]
      }],
      productId: [{
        required: true,
        message: "Please select the goods to be stored",
        trigger: ["blur", "change"]
      }]
    }), "tableFrom", {
      page: 1,
      limit: 10,
      cateId: "",
      productName: ""
      // type: '1'
    }), "productId", ""), "categoryList", []), "merCateList", []), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "shopList", []), "selectionArray", []), "objectUrl", process.env.VUE_APP_BASE_API), "dialogVisible", false);
  },
  computed: {
    storeId: function storeId() {
      return this.$store.getters.storeId;
    }
  },
  //   watch: {
  //     list: {
  //       immediate: true,
  //       handler(val) {
  //         if (val && val.length) {
  //           this.toggleSelection();
  //         }
  //       }
  //     }
  //   },
  mounted: function mounted() {},
  methods: {
    edit: function edit() {
      this.visible = true;
      this.selectionArray = [];
      this.getList();
    },
    toggleSelection: function toggleSelection() {
      var _this = this;
      var list = this.list || [];
      var ids = list.map(function (item) {
        return item.productId;
      });
      var rows = this.tableData.data;
      if (ids.length > 0) {
        rows.forEach(function (row) {
          if (ids.indexOf(row.productId) > -1) {
            console.log(ids, row);
            _this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.storeId = this.storeId;
      productLstApi(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.$nextTick(function () {
          // this.toggleSelection();
        });
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        _this2.$message.error(res.message);
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectionArray = val;
    },
    handleOk: function handleOk() {
      this.$emit("ok", this.selectionArray);
      this.close();
    },
    close: function close() {
      this.visible = false;
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this3 = this;
      storeAddproduct({
        productId: row.productId,
        storeId: this.storeId,
        type: row.isShow ? 0 : 1
      }).then(function () {
        _this3.getList();
      }).catch(function () {
        row.isShow = !row.isShow;
      });
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _utils = require("@/utils");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    uid: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      form: {
        isDefault: false
      },
      type: "",
      loading: false,
      visible: false,
      rules: {
        cardNo: [{
          required: true,
          message: "请输入卡号",
          trigger: "blur"
        }],
        name: [{
          required: true,
          message: "持卡人姓名",
          trigger: "blur"
        }],
        cardExpiryYear: [{
          required: true,
          message: "到期年份",
          trigger: "blur"
        }],
        cardExpiryMonth: [{
          required: true,
          message: "到期月份",
          trigger: "blur"
        }],
        cvv: [{
          required: true,
          message: "CVV",
          trigger: "blur"
        }]
      },
      second: 60
    };
  },
  computed: {
    title: function title() {
      return this.form.id ? "Edit card" : "Add card";
    }
  },
  methods: {
    add: function add() {
      this.visible = true;
    },
    edit: function edit(record) {
      this.form = JSON.parse(JSON.stringify(record));
      this.visible = true;
    },
    close: function close() {
      this.form = {};
      this.$refs.formRef.resetFields();
      this.visible = false;
    },
    handleUpdate: function handleUpdate() {
      var _this = this;
      this.$refs.formRef.validate(function (valid) {
        if (valid) {
          _this.loading = true;
          if (_this.form.id) {
            (0, _user.editPaymentMethod)(_this.form).then(function () {
              _this.$message.success("操作成功");
              _this.close();
              _this.loading = false;
              _this.$emit("update");
            }).catch(function () {
              _this.loading = false;
            });
          } else {
            var formData = (0, _utils.deepClone)(_objectSpread(_objectSpread({}, _this.form), {}, {
              uid: _this.uid
            }));
            (0, _user.createPaymentMethod)(formData).then(function () {
              _this.$message.success("操作成功");
              _this.close();
              _this.loading = false;
              _this.$emit("update");
            }).catch(function () {
              _this.loading = false;
            });
          }
        }
      });
    }
  }
};
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
var _default = exports.default = {
  // name: "info"
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: function data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      ddd: [{
        label: '一级 1',
        children: [{
          label: '二级 1-1',
          children: [{
            label: '三级 1-1-1'
          }]
        }]
      }, {
        label: '一级 2',
        children: [{
          label: '二级 2-1',
          children: [{
            label: '三级 2-1-1'
          }]
        }, {
          label: '二级 2-2',
          children: [{
            label: '三级 2-2-1'
          }]
        }]
      }, {
        label: '一级 3',
        children: [{
          label: '二级 3-1',
          children: [{
            label: '三级 3-1-1'
          }]
        }, {
          label: '二级 3-2',
          children: [{
            label: '三级 3-2-1'
          }]
        }]
      }],
      dataList: {
        // 数据结果
        page: 0,
        limit: 0,
        totalPage: 0,
        total: 0,
        list: []
      }
    };
  },
  mounted: function mounted() {
    this.handlerGetTreeList(this.id);
  },
  methods: {
    handlerGetTreeList: function handlerGetTreeList(id) {
      var _this = this;
      if (!id) {
        this.$message.error('当前数据id不正确');
        return;
      }
      categoryApi.treeCategroy({
        pid: id
      }).then(function (data) {
        _this.dataList = data;
      });
    },
    handleNodeClick: function handleNodeClick(data) {
      console.log('data:', data);
    }
  }
};
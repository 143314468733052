"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var roleApi = _interopRequireWildcard(require("@/api/role.js"));
require("@/utils/directive");
var _systemConfig = require("@/api/systemConfig");
var _vuex = require("vuex");
var deliveryApi = _interopRequireWildcard(require("@/api/delivery.js"));
var systemMerchantApi = _interopRequireWildcard(require("@/api/merchant.js"));
var _validate = require("@/utils/validate");
var _jsApiLoader = require("@googlemaps/js-api-loader");
var _export = require("echarts/lib/export");
var _urlencode = _interopRequireDefault(require("urlencode"));
var _shopList = _interopRequireDefault(require("./shopList.vue"));
var _utils = require("@/utils");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
function ParseEncodedValue(value) {
  var safeCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-";
  var list = new Array();
  var index = 0;
  var xsum = 0;
  var ysum = 0;
  var max = 4294967296;
  while (index < value.length) {
    var n = 0;
    var k = 0;
    while (1) {
      if (index >= value.length) {
        return null;
      }
      var b = safeCharacters.indexOf(value.charAt(index++));
      if (b == -1) {
        return null;
      }
      var tmp = (b & 31) * Math.pow(2, k);
      var ht = tmp / max;
      var lt = tmp % max;
      var hn = n / max;
      var ln = n % max;
      var nl = (lt | ln) >>> 0;
      n = (ht | hn) * max + nl;
      k += 5;
      if (b < 32) break;
    }
    var diagonal = parseInt((Math.sqrt(8 * n + 5) - 1) / 2);
    n -= diagonal * (diagonal + 1) / 2;
    var ny = parseInt(n);
    var nx = diagonal - ny;
    nx = nx >> 1 ^ -(nx & 1);
    ny = ny >> 1 ^ -(ny & 1);
    xsum += nx;
    ysum += ny;
    var lat = ysum * 0.00001;
    var lon = xsum * 0.00001;
    list.push([lon, lat]);
  }
  return list;
}
var loader = new _jsApiLoader.Loader({
  apiKey: "AIzaSyB0KvRZORCJ-XVdwPgqvfYpl70ahSD8KJ8",
  //api的key
  version: "weekly",
  //版本
  libraries: ["places", "drawing"] //插件库
});
var geocoder;
var map;
var marker;
var infowindow;
var service;
var _default2 = exports.default = {
  // name: "edit"
  components: {
    shopList: _shopList.default
  },
  props: {
    isCreate: {
      type: Number,
      required: true
    },
    editData: {
      type: Object,
      default: function _default() {
        return {
          rules: []
        };
      }
    },
    storeId: {
      type: Number
    },
    areaId: {
      type: [Number, String],
      require: false
    }
  },
  data: function data() {
    return {
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      loading: false,
      constants: this.$constants,
      modalMap: false,
      keyUrl: "",
      graphicalPath: [],
      pram: {
        // code: "",
        lat: "",
        lng: "",
        deliveryPrice: "",
        weekDay: "",
        address: "",
        period: "",
        deadline: "",
        deliveryDays: null
      },
      roleList: [],
      //图形实例
      graphicalExample: null,
      location: {},
      rules: {
        code: [{
          required: true,
          message: "code",
          trigger: ["blur", "change"]
        }],
        address: [{
          required: true,
          message: "please enter area name",
          trigger: ["blur", "change"]
        }],
        lat: [{
          required: true,
          message: "please choose delivery area",
          trigger: ["blur", "change"]
        }],
        deliveryDays: [{
          required: true,
          message: "please enter delivery time",
          trigger: ["blur", "change"]
        }]
      },
      listData: [],
      addressComponents: null
    };
  },
  computed: {},
  mounted: function mounted() {
    this.initEditData();
    // this.handleList();
  },
  methods: {
    confirm: function confirm() {
      console.log(this.location);
    },
    // 按钮触发检索
    handelSearch: function handelSearch() {
      var _this2 = this;
      this.placeSearch.search(this.searchValue, function (status, info) {
        _this2.searchInfoList = info.poiList.pois;
      });
    },
    dayTypeChange: function dayTypeChange(value) {
      console.log(value);
      var period = this.pram.period;
      if (value == "day") {
        this.pram.period = period * 7;
      } else {
        this.pram.period = parseInt(period / 7);
      }
    },
    //搜索地点方法
    // selectPoint() {
    //   const input = document.getElementById('pac-input')
    //   let searchBox = new this.google.maps.places.SearchBox(input)
    //   const infoWindow = new this.google.maps.InfoWindow()
    //   this.googleMap.controls[this.google.maps.ControlPosition.TOP_LEFT].push(input)
    //   this.googleMap.addListener('click', (e) => {
    //     this.clickGmap(e)
    //   })
    //   this.googleMap.addListener('bounds_changed', () => {
    //     searchBox.setBounds(this.googleMap.getBounds())
    //     let markers = []
    //     searchBox.addListener('places_changed', () => {
    //       const places = searchBox.getPlaces()
    //       // this.getBingMapsGeometrya(places[0].geometry.location.lat(), places[0].geometry.location.lng(),'Postcode1')
    //       if (places.length == 0) {
    //         this.google = null
    //         this.$message.error('请输入正确的区域');
    //         return
    //       }
    //       this.location = {
    //         lat: places[0].geometry.location.lat(),
    //         lng: places[0].geometry.location.lng(),
    //       }
    //       this.pram.address = places[0].name
    //       // Clear out the old markers.
    //       markers.forEach((marker) => {
    //         marker.setMap(null)
    //       })
    //       markers = []
    //       // For each place, get the icon, name and location.
    //       const bounds = new this.google.maps.LatLngBounds()
    //       places.forEach((place) => {
    //         if (!place.geometry || !place.geometry.location) {
    //           console.log('Returned place contains no geometry')
    //           return
    //         }
    //         const icon = {
    //           url: place.icon,
    //           size: new this.google.maps.Size(71, 71),
    //           origin: new this.google.maps.Point(0, 0),
    //           anchor: new this.google.maps.Point(17, 34),
    //           scaledSize: new this.google.maps.Size(25, 25),
    //         }
    //         // Create a marker for each place.
    //         markers.push(
    //           new this.google.maps.Marker({
    //             map: this.googleMap,
    //             icon,
    //             title: place.name,
    //             position: place.geometry.location,
    //             draggable: false,
    //           })
    //         )
    //         if (place.geometry.viewport) {
    //           // Only geocodes have viewport.
    //           bounds.union(place.geometry.viewport)
    //         } else {
    //           bounds.extend(place.geometry.location)
    //         }
    //       })
    //       this.googleMap.fitBounds(bounds)
    //     })
    //   })
    // },
    // handleList() {
    //   systemMerchantApi.merchantList(this.listPram).then(data => {
    //     this.listData = data.list;
    //   });
    // },
    //查找位置
    onSearch: function onSearch() {
      this.modalMap = true;
      var _this = this;
      this.$nextTick(function () {
        _this.initMap();
      });
    },
    clickGmap: function clickGmap(e) {
      // this.selectPoint()
    },
    close: function close() {
      this.$emit("close");
    },
    handleClose: function handleClose() {
      this.modalMap = false;
      this.graphicalPath = [];
      this.location = {};
      // this.pram.address=''
      this.clonseN();
    },
    handleOk: function handleOk() {
      if (this.graphicalPath.length <= 0) {
        this.$message.error("please choose delivery area");
        return;
      }
      var latArr = [];
      var lngArr = [];
      this.graphicalPath.forEach(function (e) {
        latArr.push(e.lat);
        lngArr.push(e.lng);
      });
      this.pram.lat = latArr.join(",");
      this.pram.lng = lngArr.join(",");
      // this.pram.lng = geometry.location.lng();
      // return
      // const {
      //   geometry,
      //   formatted_address,
      //   address_components
      // } = this.addressComponents;
      // this.pram.lat = geometry.location.lat();
      // this.pram.lng = geometry.location.lng();
      // this.pram.address = formatted_address;
      // this.pram.code = address_components[1].short_name
      this.modalMap = false;
    },
    initEditData: function initEditData() {
      this.pram.storeId = this.storeId;
      // if (this.isCreate !== 1) return;
      var _this$editData = this.editData,
        code = _this$editData.code,
        lat = _this$editData.lat,
        lng = _this$editData.lng,
        address = _this$editData.address,
        storeId = _this$editData.storeId,
        deliveryDays = _this$editData.deliveryDays,
        deliveryPrice = _this$editData.deliveryPrice,
        weekDay = _this$editData.weekDay,
        period = _this$editData.period,
        deadline = _this$editData.deadline,
        subType = _this$editData.subType,
        startTime = _this$editData.startTime;
      this.pram = Object.assign({}, {
        code: code,
        lat: lat,
        lng: lng,
        address: address,
        storeId: storeId,
        deliveryDays: deliveryDays,
        deliveryPrice: deliveryPrice,
        weekDay: weekDay,
        period: period,
        deadline: deadline,
        startTime: startTime,
        subType: subType ? subType : "week"
      });
    },
    handlerSubmit: (0, _validate.Debounce)(function (form) {
      var _this3 = this;
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        if (_this3.isCreate === 0) {
          _this3.handlerSave();
        } else {
          _this3.handlerEdit();
        }
      });
    }),
    handlerSave: function handlerSave() {
      var _this4 = this;
      this.pram.storeId = this.storeId;
      var shopList = this.$refs.shopList.listData.list;
      var productList = (0, _utils.deepClone)(shopList);
      var newProductList = productList.map(function (item) {
        return {
          productId: item.productId,
          price: item.price ? item.price : null,
          oldPrice: item.oldPrice
        };
      });
      var params = _objectSpread(_objectSpread({}, this.pram), {}, {
        storeId: this.storeId,
        productList: newProductList
      });
      deliveryApi.addDelivery(params).then(function (data) {
        _this4.$message.success("successful");
        _this4.$emit("hideEditDialog");
      });
    },
    handlerEdit: function handlerEdit() {
      var _this5 = this;
      this.pram.id = this.editData.id;
      deliveryApi.updateDelivery(this.pram).then(function (data) {
        _this5.$message.success("successful");
        _this5.$emit("hideEditDialog");
      });
    },
    rulesSelect: function rulesSelect(selectKeys) {
      this.pram.rules = selectKeys;
    },
    initMap: function initMap() {
      var _this6 = this;
      var that = this;
      var mapOptions = {
        center: {
          lat: 22.602,
          lng: 114.043
        },
        zoom: 12,
        streetViewControl: false
      };
      loader.load().then(function (google) {
        _this6.google = google;
        map = new google.maps.Map(document.getElementById("map"), mapOptions);
        _this6.googleMap = map;
        geocoder = new google.maps.Geocoder();
        // service 地点查询类
        service = new google.maps.places.PlacesService(map);
        infowindow = new google.maps.InfoWindow({
          // 地图信息窗口
          content: ""
          // disableAutoPan: true,
        });
        _this6.drawingManager = new _this6.google.maps.drawing.DrawingManager({
          drawingControl: true,
          drawingControlOptions: {
            position: _this6.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [_this6.google.maps.drawing.OverlayType.POLYGON]
          },
          polygonOptions: {
            fillColor: "#1890ff",
            fillOpacity: 0.3,
            strokeColor: "#1890ff",
            strokeWeight: 1.5,
            editable: true,
            draggable: true
          }
        });

        // this.selectPoint()
        _this6.drawingManager.setMap(map);
        if (_this6.pram.lat) {
          _this6.drawingPolygon();
        }
        _this6.google.maps.event.addListener(_this6.drawingManager, "polygonstart", function (e) {
          console.log(1);
        });
        //监听绘制多边形完成事件事件
        _this6.google.maps.event.addListener(_this6.drawingManager, "polygoncomplete", function (e) {
          console.log(e);
          console.log(e.getPath().g);
          //存在就清除上一个图形
          if (_this6.graphicalExample) {
            _this6.graphicalExample.setMap(null);
          }
          _this6.pram.address = "";
          _this6.location = {};
          _this6.graphicalExample = e;
          //path路径emit传出
          _this6.setPath(e.getPath().g);
          //监听回显图形每一个路径点
          _this6.google.maps.event.addListener(e.getPath(), "insert_at", function (event) {
            _this6.setPath(e.getPath().g);
          });
          _this6.google.maps.event.addListener(e.getPath(), "set_at", function (event) {
            _this6.setPath(e.getPath().g);
          });
          //绘制完成后关闭绘制状态
          _this6.drawingManager.setDrawingMode(null);
        });
        // this.google.maps.event.addListener(map, "click", function (event) {
        //   that.clickMap(event.latLng);
        // }); // 监听地图点击事件
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            var pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            if (_this6.pram.lat && _this6.pram.lat) {
              pos = _this6.getPointsCenter(_this6.pram.lat.split(","), _this6.pram.lng.split(","));
              // pos={
              //   lat:Number(this.pram.lat.split(',')[0]),
              //   lng:Number(this.pram.lng.split(',')[0])
              //  }
            }
            map.setCenter(pos);
            marker = new google.maps.Marker({
              position: pos,
              map: map
            });
            that.clickMap(pos);
          }, function () {
            _this6.handleLocationError(true, infowindow, map.getCenter());
          });
        } else {
          // Browser doesn't support Geolocation
          _this6.handleLocationError(false, infowindow, map.getCenter());
        }
      }).catch(function (e) {
        // alert(1)
        _this6.$message.error("map loading failed..");
      });
    },
    drawingManagers: function drawingManagers() {
      this.drawingManager.setDrawingMode(this.google.maps.drawing.OverlayType.POLYGON);
    },
    clonse: function clonse() {
      this.drawingManager.setDrawingMode(null);
    },
    // 获取区域坐标中心点
    getPointsCenter: function getPointsCenter(lat, lng) {
      var i;
      var polygonCoords = [];
      lat.forEach(function (e, i) {
        var obj = new google.maps.LatLng(e, lng[i]);
        polygonCoords.push(obj);
      });
      var bounds = new google.maps.LatLngBounds();
      for (i = 0; i < polygonCoords.length; i++) {
        bounds.extend(polygonCoords[i]);
      }
      return bounds.getCenter();
    },
    clonseN: function clonseN() {
      if (this.graphicalExample) {
        this.graphicalExample.setMap(null);
        this.graphicalPath = [];
      }
    },
    setPath: function setPath(path) {
      var _this7 = this;
      this.graphicalPath = [];
      if (path.length > 0) {
        path.forEach(function (v) {
          _this7.graphicalPath.push({
            lat: v.lat(),
            lng: v.lng()
          });
        });
      }
    },
    handleLocationError: function handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(browserHasGeolocation ? "Error: The Geolocation service failed." : "Error: Your browser doesn't support geolocation.");
      infoWindow.open(map);
    },
    getBingMapsGeometrya: function getBingMapsGeometrya() {
      var _this8 = this;
      if (!this.location.lat || !this.location.lng) {
        this.$message.error("Please enter the address to get the administrative area");
        return;
      }
      var entityType = "PopulatedPlace";
      var latitude = this.location.lat;
      var longitude = this.location.lng;
      console.log(latitude, longitude);
      var baseUrl = "http://platform.bing.com/geo/spatial/v1/public/Geodata?SpatialFilter=",
        levelOfDetail = 0,
        getAllPolygons = 1,
        getEntityMetadata = 1,
        culture = "CN",
        userRegion = "CN",
        preferCuratedPolygons = 1,
        responseFormat = "json",
        key = "AuqqgCcnsSmLyS8-4Dc0QQhWV713UOSThvBg0-iOLGpX12PUgFZtiooNce85hIg7";
      var url = "".concat(baseUrl, "GetBoundary(").concat(latitude, ",").concat(longitude, ",").concat(levelOfDetail, ",'").concat(entityType, "',").concat(getAllPolygons, ",").concat(getEntityMetadata, ",'").concat(culture, "','").concat(userRegion, "')&PreferCuratedPolygons=").concat(preferCuratedPolygons, "&$format=").concat(responseFormat, "&key=").concat(key);
      var url1 = url = (0, _urlencode.default)(url);
      deliveryApi.getBingMapsGeometry({
        url: url1
      }).then(function (res) {
        if (typeof res == "string") {
          res = JSON.parse(res);
        }
        var entity = res.d.results[0];
        if (!entity) {
          alert("no results");
          return;
        }
        _this8.clonseN();
        var entityMetadata = entity.EntityMetadata;
        entityMetadata.name = entity.Name.EntityName;
        var primitives = entity.Primitives;
        var boundaryVertices = null;
        var numOfVertices = 0;
        var multyiPolygonArray = [];
        var singlePolygonArray;
        for (var i = 0; i < primitives.length; i++) {
          var ringStr = primitives[i].Shape;
          var ringArray = ringStr.split(",");
          for (var j = 1; j < ringArray.length; j++) {
            var polygonArray = new Array();
            var array = ParseEncodedValue(ringArray[j]);
            if (array.length > numOfVertices) {
              numOfVertices = array.length;
              boundaryVertices = array;
            }
            polygonArray.push(array);
            if (ringArray.length > 1) multyiPolygonArray.push(polygonArray);else singlePolygonArray = polygonArray;
          }
        }
        var geojson = {
          type: "FeatureCollection",
          features: [{
            type: "Feature",
            properties: entityMetadata,
            geometry: {
              type: singlePolygonArray ? "Polygon" : "MultiPolygon",
              coordinates: singlePolygonArray ? singlePolygonArray : multyiPolygonArray
            }
          }]
        };
        var list = geojson.features[0].geometry.coordinates;
        list.sort(function (a, b) {
          return b[0].length - a[0].length;
        });
        // const length=geojson.features[0].geometry.coordinates.length
        // const arr=geojson.features[0].geometry.coordinates[length-1][0] || []
        var arr = list[0][0] || [];
        // this.pram.address=geojson.properties.na
        // this.pram.address=geojson.features[0].properties.name
        _this8.drawingPolygon(arr);
      }).catch(function (err) {
        //  alert(err)
      });
      // `http://platform.bing.com/geo/spatial/v1/public/Geodata?SpatialFilter=GetBoundary(24.5759099,118.0972799,Postcode1,1,1,CN,CN)&PreferCuratedPolygons=1&$format=json&key=AuqqgCcnsSmLyS8-4Dc0QQhWV713UOSThvBg0-iOLGpX12PUgFZtiooNce85hIg7`
      return;
      var header = {
        "Content-Type": "application/json"
      };
      wx.request({
        url: "".concat(baseUrl, "GetBoundary(").concat(latitude, ",").concat(longitude, ",").concat(levelOfDetail, ",'").concat(entityType, "',").concat(getAllPolygons, ",").concat(getEntityMetadata, ",'").concat(culture, "','").concat(userRegion, "')&PreferCuratedPolygons=").concat(preferCuratedPolygons, "&$format=").concat(responseFormat, "&key=").concat(key),
        header: header,
        data: {},
        method: "GET",
        timeout: 100000,
        success: function success(result) {
          var entity = result.data.d.results[0];
          console.log(entity);
          if (!entity) {
            alert("no results");
            return;
          }
          var entityMetadata = entity.EntityMetadata;
          entityMetadata.name = entity.Name.EntityName;
          var primitives = entity.Primitives;
          var boundaryVertices = null;
          var numOfVertices = 0;
          var multyiPolygonArray = [];
          var singlePolygonArray;
          for (var i = 0; i < primitives.length; i++) {
            var ringStr = primitives[i].Shape;
            var ringArray = ringStr.split(",");
            for (var j = 1; j < ringArray.length; j++) {
              var polygonArray = new Array();
              var array = ParseEncodedValue(ringArray[j]);
              if (array.length > numOfVertices) {
                numOfVertices = array.length;
                boundaryVertices = array;
              }
              polygonArray.push(array);
              if (ringArray.length > 1) multyiPolygonArray.push(polygonArray);else singlePolygonArray = polygonArray;
            }
          }
          var geojson = {
            type: "FeatureCollection",
            features: [{
              type: "Feature",
              properties: entityMetadata,
              geometry: {
                type: singlePolygonArray ? "Polygon" : "MultiPolygon",
                coordinates: singlePolygonArray ? singlePolygonArray : multyiPolygonArray
              }
            }]
          };
          console.log(geojson.features[0].geometry.coordinates[0][0]);
          return geojson;
        },
        fail: function fail(err) {
          reject(err);
        },
        complete: function complete() {
          wx.hideNavigationBarLoading();
        }
      });
    },
    //根据以知 点绘制图形
    drawingPolygon: function drawingPolygon(map) {
      var _this9 = this;
      // const arr=[[118.06479000000002, 24.53827],[118.1161, 24.629620000000003],[118.02964000000001, 24.708330000000004],[117.96540000000002, 24.671020000000002],[117.98639000000001, 24.57103],[118.06479000000002, 24.53827] ]
      var graphicalPath = [
        // {lat:24.53827, lng:118.06479000000002},
        // {lat: 24.629620000000003, lng:118.1161},
        // {lat:37.29332245336843, lng:-121.84821276555685},
        // {lat:37.298989654009794, lng:-121.83731226812033},
      ];
      if (map) {
        // alert(map)
        map.forEach(function (e) {
          var obj1 = {
            lat: e[1],
            lng: e[0]
          };
          graphicalPath.push(obj1);
        });
      } else {
        if (this.pram.lat && this.pram.lng) {
          var latArr = this.pram.lat.split(",");
          var lngArr = this.pram.lng.split(",");
          for (var i = 0; i < latArr.length; i++) {
            var obj = {
              lat: Number(latArr[i]),
              lng: Number(lngArr[i])
            };
            graphicalPath.push(obj);
          }
        }
        console.log(graphicalPath);
      }
      this.graphicalPath = graphicalPath;
      this.graphicalExample = new google.maps.Polygon({
        paths: this.graphicalPath,
        fillColor: "#1890ff",
        fillOpacity: 0.3,
        strokeColor: "#1890ff",
        strokeWeight: 1.5,
        editable: false,
        draggable: false
      });
      this.graphicalExample.setMap(this.googleMap);
      //监听回显图形每一个路径点
      this.google.maps.event.addListener(this.graphicalExample.getPath(), "insert_at", function (event) {
        _this9.setPath(_this9.graphicalExample.getPath().g);
      });
      this.google.maps.event.addListener(this.graphicalExample.getPath(), "set_at", function (event) {
        _this9.setPath(_this9.graphicalExample.getPath().g);
      });
    },
    clickMap: function clickMap(location) {
      var that = this;
      marker.setMap(null);
      map.setCenter(location);
      marker = new google.maps.Marker({
        position: location,
        map: map
      });
      if (geocoder) {
        geocoder.geocode({
          location: location
        }, function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              that.attachSecretMessage(marker, results[0].geometry.location, results[0].formatted_address);
              that.addressComponents = results[0];
            }
          } else {
            alert("Geocoder failed due to: " + status);
          }
        });
      }
    },
    attachSecretMessage: function attachSecretMessage(marker, piont, address) {
      //  var message =
      //   "<b>水厂坐标:</b>" +
      //   piont.lat() +
      //   " , " +
      //   piont.lng() +
      //   "<br />"
      var message = "<b>location:</b>" + piont.lat() + " , " + piont.lng() + "<br />" + "<b>地址:</b>" + address;
      var infowindow = new google.maps.InfoWindow({
        content: message,
        size: new google.maps.Size(50, 50)
      });
      // infowindow.open(map, marker);
    },
    initCircle: function initCircle() {
      new google.maps.Circle({
        strokeColor: "#25b2ee",
        strokeOpacity: 0.9,
        strokeWeight: 2,
        fillColor: "#25b2ee",
        fillOpacity: 0.2,
        map: map,
        center: {
          lat: mapData.lat,
          lng: mapData.lon
        },
        radius: 15000 //单位是米
      });
    },
    getLocation: function getLocation() {},
    getDetailsInfo: function getDetailsInfo(placeId) {
      var _this10 = this;
      var request = {
        placeId: placeId,
        fields: ["all"]
      };
      service.getDetails(request, function (res, status) {
        if (status === _this10.google.maps.places.PlacesServiceStatus.OK) {
          console.log("details", res);
          var addressList = res.address_components,
            street = res.formatted_address.split(" ");
          var i = 0;
          for (i; i < addressList.length; i++) {
            if (addressList[i].types.includes("country")) _this10.region.country = addressList[i].long_name;
            if (addressList[i].types.includes("administrative_area_level_1")) _this10.region.province = addressList[i].long_name;
            if (addressList[i].types.includes("locality")) _this10.region.city = addressList[i].long_name;else if (addressList[i].types.includes("sublocality_level_1")) _this10.region.city = addressList[i].long_name;
          }
        }
      });
    }
  }
};
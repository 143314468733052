"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var roleApi = _interopRequireWildcard(require("@/api/role.js"));
var _validate = require("@/utils/validate");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "roleEdit",
  props: {
    isCreate: {
      type: Number,
      required: true
    },
    editData: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      pram: {
        roleName: null,
        rules: '',
        status: null,
        id: null
      },
      menuExpand: false,
      menuNodeAll: false,
      menuOptions: [],
      menuCheckStrictly: true,
      currentNodeId: [],
      defaultProps: {
        children: "childList",
        label: "name"
      },
      menuIds: []
    };
  },
  mounted: function mounted() {
    this.initEditData();
    this.getCacheMenu();
  },
  methods: {
    close: function close() {
      this.$emit('hideEditDialog');
    },
    initEditData: function initEditData() {
      var _this = this;
      if (this.isCreate !== 1) return;
      var _this$editData = this.editData,
        roleName = _this$editData.roleName,
        status = _this$editData.status,
        id = _this$editData.id;
      this.pram.roleName = roleName;
      this.pram.status = status;
      this.pram.id = id;
      var loading = this.$loading({
        lock: true,
        text: 'Loading'
      });
      roleApi.getInfo(id).then(function (res) {
        _this.menuOptions = res.menuList;
        _this.checkDisabled(_this.menuOptions);
        loading.close();
        _this.getTreeId(res.menuList);
        _this.$nextTick(function () {
          _this.menuIds.forEach(function (i, n) {
            var node = _this.$refs.menu.getNode(i);
            if (node.isLeaf) {
              _this.$refs.menu.setChecked(node, true);
            }
          });
        });
      });
    },
    handlerSubmit: (0, _validate.Debounce)(function (form) {
      var _this2 = this;
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        var roles = _this2.getMenuAllCheckedKeys().toString();
        _this2.pram.rules = roles;
        if (_this2.isCreate === 0) {
          _this2.handlerSave();
        } else {
          _this2.handlerEdit();
        }
      });
    }),
    handlerSave: function handlerSave() {
      var _this3 = this;
      roleApi.addRole(this.pram).then(function (data) {
        _this3.$message.success('successful');
        _this3.$emit('hideEditDialog');
      });
    },
    handlerEdit: function handlerEdit() {
      var _this4 = this;
      roleApi.updateRole(this.pram).then(function (data) {
        _this4.$message.success('successful');
        _this4.$emit('hideEditDialog');
      });
    },
    rulesSelect: function rulesSelect(selectKeys) {
      this.pram.rules = selectKeys;
    },
    // 树权限（展开/折叠）
    handleCheckedTreeExpand: function handleCheckedTreeExpand(value, type) {
      if (type == 'menu') {
        var treeList = this.menuOptions;
        for (var i = 0; i < treeList.length; i++) {
          this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value;
        }
      }
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll: function handleCheckedTreeNodeAll(value, type) {
      if (type == 'menu') {
        this.$refs.menu.setCheckedNodes(value ? this.menuOptions : []);
      }
    },
    // 树权限（父子联动）
    handleCheckedTreeConnect: function handleCheckedTreeConnect(value, type) {
      if (type == 'menu') {
        this.menuCheckStrictly = value ? true : false;
      }
    },
    // 所有菜单节点数据
    getMenuAllCheckedKeys: function getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      var checkedKeys = this.$refs.menu.getCheckedKeys();
      // 半选中的菜单节点
      var halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    getCacheMenu: function getCacheMenu() {
      var _this5 = this;
      if (this.isCreate !== 0) return;
      var loading = this.$loading({
        lock: true,
        text: 'Loading'
      });
      roleApi.menuCacheList().then(function (res) {
        _this5.menuOptions = res;
        _this5.checkDisabled(_this5.menuOptions);
        loading.close();
      });
    },
    getTreeId: function getTreeId(datas) {
      for (var i in datas) {
        if (datas[i].checked) this.menuIds.push(datas[i].id);
        if (datas[i].childList) {
          this.getTreeId(datas[i].childList);
        }
      }
    },
    checkDisabled: function checkDisabled(data) {
      var _this6 = this;
      //设置公共权限默认勾选且不可操作
      data.forEach(function (item) {
        if (item.id === 280 || item.id === 294 || item.id === 344) {
          item.disabled = true;
          item.childList.forEach(function (item1) {
            item1.disabled = true;
            _this6.$nextTick(function () {
              var node = _this6.$refs.menu.getNode(item1.id);
              if (node.isLeaf) {
                _this6.$refs.menu.setChecked(node, true);
              }
            });
          });
        }
      });
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "pos-order-list" },
    [
      _c("div", { staticClass: "nav acea-row row-around row-middle" }, [
        _c(
          "div",
          {
            staticClass: "item",
            class: _vm.where.status == "unPaid" ? "on" : "",
            on: {
              click: function ($event) {
                return _vm.changeStatus("unPaid")
              },
            },
          },
          [_vm._v("\n      待付款\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item",
            class: _vm.where.status == "notShipped" ? "on" : "",
            on: {
              click: function ($event) {
                return _vm.changeStatus("notShipped")
              },
            },
          },
          [_vm._v("\n      待发货\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item",
            class: _vm.where.status == "spike" ? "on" : "",
            on: {
              click: function ($event) {
                return _vm.changeStatus("spike")
              },
            },
          },
          [_vm._v("\n      待收货\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item",
            class: _vm.where.status == "toBeWrittenOff" ? "on" : "",
            on: {
              click: function ($event) {
                return _vm.changeStatus("toBeWrittenOff")
              },
            },
          },
          [_vm._v("\n      待核销\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item",
            class: _vm.where.status == "complete" ? "on" : "",
            on: {
              click: function ($event) {
                return _vm.changeStatus("complete")
              },
            },
          },
          [_vm._v("\n      已完成\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item",
            class: _vm.where.status == "refunding" ? "on" : "",
            on: {
              click: function ($event) {
                return _vm.changeStatus("refunding")
              },
            },
          },
          [_vm._v("\n      退款\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list" },
        [
          _vm.list.length > 0
            ? _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "item" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "order-num acea-row row-middle",
                        on: {
                          click: function ($event) {
                            return _vm.toDetail(item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          订单号：" +
                            _vm._s(item.orderId) +
                            "\n          "
                        ),
                        _c("span", { staticClass: "time" }, [
                          _vm._v("Order time：" + _vm._s(item.createTime)),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(item.productList, function (val, key) {
                      return _c(
                        "div",
                        { key: key, staticClass: "pos-order-goods" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "goods acea-row row-between-wrapper",
                              on: {
                                click: function ($event) {
                                  return _vm.toDetail(item)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "picTxt acea-row row-between-wrapper",
                                },
                                [
                                  _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: { src: val.info.image },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text " }, [
                                    _c("div", { staticClass: "info line2" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(val.info.productName) +
                                          "\n                  "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    val.info.sku
                                      ? _c("div", { staticClass: "attr" }, [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(val.info.sku) +
                                              "\n                  "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "money" }, [
                                _c("div", { staticClass: "x-money" }, [
                                  _vm._v("￥" + _vm._s(val.info.price)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "num" }, [
                                  _vm._v("x" + _vm._s(val.info.payNum)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "y-money" }),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "public-total" }, [
                      _vm._v(
                        "\n          共" +
                          _vm._s(item.totalNum ? item.totalNum : 1) +
                          "件商品，应支付\n          "
                      ),
                      _c("span", { staticClass: "money" }, [
                        _vm._v("￥" + _vm._s(item.payPrice)),
                      ]),
                      _vm._v(
                        " ( 邮费 ¥" +
                          _vm._s(item.totalPostage ? item.totalPostage : 0) +
                          ")\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "operation acea-row row-between-wrapper" },
                      [
                        _c("div", { staticClass: "more" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "acea-row row-middle" },
                          [
                            !item.isAlterPrice && item.paid == false
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "bnt",
                                    on: {
                                      click: function ($event) {
                                        return _vm.modify(item, 0)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              一键改价\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "bnt",
                                on: {
                                  click: function ($event) {
                                    return _vm.modify(item, 1)
                                  },
                                },
                              },
                              [_vm._v("订单备注")]
                            ),
                            _vm._v(" "),
                            _vm.where.status === "refunding" &&
                            item.refundStatus === 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "bnt",
                                    on: {
                                      click: function ($event) {
                                        return _vm.modify(item, 2)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              立即退款\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.where.status === "refunding" &&
                            item.refundStatus === 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "bnt",
                                    on: {
                                      click: function ($event) {
                                        return _vm.modify(item, 3)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              拒绝退款\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.where.status === "notShipped" &&
                            item.shippingType !== 2 &&
                            item.refundStatus !== 2
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "bnt",
                                    attrs: {
                                      to:
                                        "/javaMobile/orderDelivery/" +
                                        item.orderId +
                                        "/" +
                                        item.id,
                                    },
                                  },
                                  [_vm._v("去发货\n            ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.where.status === "toBeWrittenOff" &&
                            item.shippingType === 2 &&
                            _vm.isWriteOff &&
                            item.refundStatus === 0 &&
                            item.paid == true
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "bnt",
                                    attrs: {
                                      to: "/javaMobile/orderCancellation",
                                    },
                                  },
                                  [_vm._v("去核销\n            ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  2
                )
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.loading && _vm.list.length === 0
            ? [
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("暂无数据"),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("Loading", { attrs: { loaded: _vm.loaded, loading: _vm.loading } }),
      _vm._v(" "),
      _vm.orderInfo
        ? _c("PriceChange", {
            attrs: {
              change: _vm.change,
              orderInfo: _vm.orderInfo,
              status: _vm.status,
            },
            on: {
              closechange: function ($event) {
                return _vm.changeclose($event)
              },
              getRefuse: _vm.getRefuse,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
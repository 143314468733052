"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var roleApi = _interopRequireWildcard(require("@/api/role.js"));
require("@/utils/directive");
var _systemConfig = require("@/api/systemConfig");
var systemMerchantApi = _interopRequireWildcard(require("@/api/merchant.js"));
var _jsApiLoader = require("@googlemaps/js-api-loader");
var _validate = require("@/utils/validate");
var _vuex = require("vuex");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var loader = new _jsApiLoader.Loader({
  apiKey: "AIzaSyB0KvRZORCJ-XVdwPgqvfYpl70ahSD8KJ8",
  //api的key
  version: "weekly",
  //版本
  libraries: ["places", "drawing"] //插件库
});
var geocoder;
var map;
var marker;
var markers = [];
var infowindow;
var service;
var _default2 = exports.default = {
  // name: "edit"
  components: {},
  props: {
    isCreate: {
      type: Number,
      required: true
    },
    editData: {
      type: Object,
      default: function _default() {
        return {
          rules: []
        };
      }
    }
  },
  data: function data() {
    return {
      addressList: [],
      selectaddressPram: {},
      searchVal: "",
      btnLoading: false,
      markLoading: false,
      loading: false,
      mapLoading: false,
      loadings: false,
      constants: this.$constants,
      dialogVisible: false,
      keyUrl: "",
      pram: {},
      _roles: "",
      roleList: []
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(["storeId"])),
  mounted: function mounted() {},
  methods: _defineProperty(_defineProperty({
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 搜索
    searchMap: function searchMap() {
      var _this2 = this;
      this.addressList = [];
      if (!this.searchVal) {
        this.addressList = [];
        return;
      }
      this.btnLoading = true;
      this.addressList = [];
      systemMerchantApi.googlePlace({
        input: this.searchVal
      }).then(function (res) {
        _this2.addressList = res.predictions;
        _this2.btnLoading = false;
      }).catch(function () {
        _this2.btnLoading = false;
      });
    },
    //查找位置
    onSearch: function onSearch(record) {
      this.dialogVisible = true;
      var _this = this;
      this.pram = Object.assign({}, record);
      this.$nextTick(function () {
        _this.initMap();
      });
    },
    initMap: function initMap() {
      var _this3 = this;
      this.mapLoading = true;
      var that = this;
      var mapOptions = {
        center: {
          lat: 22.602,
          lng: 114.043
        },
        zoom: 14,
        streetViewControl: false
      };
      loader.load().then(function (google) {
        var that = _this3;
        _this3.google = google;
        map = new google.maps.Map(document.getElementById("mapManage"), mapOptions);
        _this3.googleMap = map;
        geocoder = new google.maps.Geocoder();
        // service 地点查询类
        service = new google.maps.places.PlacesService(map);
        infowindow = new google.maps.InfoWindow({
          // 地图信息窗口
          content: ""
        });
        _this3.google.maps.event.addListener(map, "click", function (event) {
          that.clickMap(event.latLng);
        }); // 监听地图点击事件
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function success(position) {
            var pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            if (that.pram.latitude && that.pram.longitude) {
              pos.lat = Number(that.pram.latitude);
              pos.lng = Number(that.pram.longitude);
            }
            map.setCenter(pos);
            marker = new google.maps.Marker({
              position: pos,
              map: map
            });
            if (that.pram.address) {
              var message = that.pram.address + "<br />";
              marker = new google.maps.Marker({
                position: pos,
                map: map,
                title: message,
                zoom: 15
              });
              markers.push(marker);
              var infowindow = new google.maps.InfoWindow({
                content: marker.getTitle(),
                size: new google.maps.Size(50, 50)
              });
              infowindow.open(map, marker);
            }
          }, function failed(error) {
            that.$message.warning("Locating is not enabled, or the network is faulty");
            that.handleLocationError(true, infowindow, map.getCenter());
          }, {
            timeout: 5000
          });
          _this3.mapLoading = false;
        } else {
          // Browser doesn't support Geolocation
          that.handleLocationError(false, infowindow, map.getCenter());
        }
      }).catch(function (e) {
        _this3.mapLoading = false;
      });
      var times = setInterval(function () {
        if (!_this3.mapLoading) {
          clearInterval(times);
        }
        if (loader.errors.length > 0) {
          clearInterval(times);
          _this3.mapLoading = false;
          that.$message.error("Map function is not available, please check the network");
        }
      }, 1000);
    },
    selectAddres: function selectAddres(item) {
      var _this4 = this;
      this.markLoading = true;
      systemMerchantApi.googleChoose({
        placeId: item.place_id,
        key: "AIzaSyB0KvRZORCJ-XVdwPgqvfYpl70ahSD8KJ8"
      }).then(function (res) {
        var sourceData = res.results[0];
        _this4.selectaddressPram.address = item.description;
        _this4.markLoading = false;
        _this4.getAddress(sourceData);
      }).catch(function () {
        _this4.btnLoading = false;
        _this4.markLoading = false;
      });
    },
    getAddress: function getAddress(sourceData) {
      var _this5 = this;
      marker.setMap(null);
      // console.log(sourceData)
      var list = sourceData.address_components;
      this.selectaddressPram.latitude = sourceData.geometry.location.lat;
      this.selectaddressPram.longitude = sourceData.geometry.location.lng;
      this.selectaddressPram.address = sourceData.formatted_address;
      this.selectaddressPram.nation = "";
      this.selectaddressPram.province = "";
      this.selectaddressPram.city = "";
      this.selectaddressPram.postCode = "";
      list.forEach(function (item) {
        if (item.types[0] == "country") {
          _this5.selectaddressPram.nation = item.long_name;
        }
        if (item.types[0] == "administrative_area_level_1") {
          _this5.selectaddressPram.province = item.long_name;
          _this5.selectaddressPram.city = item.long_name;
        }
        if (item.types[0] == "administrative_area_level_2" || item.types[0] == "locality") {
          _this5.selectaddressPram.city = item.long_name;
        }
        if (item.types[0] == "postal_code") {
          _this5.selectaddressPram.postCode = item.long_name;
        }
      });
      this.addressList = [];
      map.setCenter({
        lat: Number(this.selectaddressPram.latitude),
        lng: Number(this.selectaddressPram.longitude)
      });
      var message = this.selectaddressPram.address + "<br />";
      marker = new google.maps.Marker({
        position: {
          lat: Number(this.selectaddressPram.latitude),
          lng: Number(this.selectaddressPram.longitude)
        },
        map: map,
        title: message,
        zoom: 15
      });
      var infowindow = new google.maps.InfoWindow({
        content: marker.getTitle(),
        size: new google.maps.Size(50, 50)
      });
      infowindow.open(map, marker);
      this.addressList = [];
      this.searchVal = "";
    },
    handleLocationError: function handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(browserHasGeolocation ? "Error: The Geolocation service failed." : "Error: Your browser doesn't support geolocation.");
      infoWindow.open(map);
    },
    clickMap: function clickMap(location) {
      var that = this;
      if (geocoder) {
        geocoder.geocode({
          location: location
        }, function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              that.attachSecretMessage(marker, results[0].geometry.location, results[0].formatted_address);
              var sourceData = results[0];
              sourceData.geometry.location.lat = location.lat();
              sourceData.geometry.location.lng = location.lng();
              that.getAddress(sourceData);
            }
          } else {
            alert("Geocoder failed due to: " + status);
          }
        });
      }
    },
    attachSecretMessage: function attachSecretMessage(marker, piont, address) {
      var message = "<b>location:</b>" + piont.lat() + " , " + piont.lng() + "<br />" + "<b>地址:</b>" + address;
      var infowindow = new google.maps.InfoWindow({
        content: message,
        size: new google.maps.Size(50, 50)
      });
      infowindow.open(map, marker);
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      var attr = [];
      this.$modalUpload(function (img) {
        _this.pram.image = img[0].sattDir;
      }, tit, "store");
    }
  }, "modalPicTap", function modalPicTap(tit, image) {
    var _this = this;
    var attr = [];
    this.$modalUpload(function (img) {
      if (tit === "1") {
        _this.pram[image] = img[0].sattDir;
      }
      if (tit === "2") {
        img.map(function (item) {
          attr.push(item.attachment_src);
          _this.formValidate.slider_image.push(item);
        });
      }
    }, tit, "store");
  }), "handleOk", function handleOk() {
    var _this$selectaddressPr = this.selectaddressPram,
      latitude = _this$selectaddressPr.latitude,
      longitude = _this$selectaddressPr.longitude,
      address = _this$selectaddressPr.address;
    if (!address) {
      this.$message.error("Please select address");
      return;
    }
    this.$emit("change", {
      latitude: latitude,
      longitude: longitude,
      address: address
    });
    this.dialogVisible = false;
  })
};
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var subscribeApi = _interopRequireWildcard(require("@/api/subscribe.js"));
var _utils = require("@/utils");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "delivery",
  props: {},
  data: function data() {
    return {
      visible: false,
      loading: false,
      form: {},
      rules: {
        nextOrderDate: [{
          required: true,
          message: "Please choose",
          trigger: ["blur", "change"]
        }],
        cartNum: [{
          required: true,
          message: "Please choose",
          trigger: ["blur", "change"]
        }],
        period: [{
          required: true,
          message: "Please choose",
          trigger: ["blur", "change"]
        }]
      },
      tmpIds: [],
      tmpId: null,
      shopDetail: null
    };
  },
  mounted: function mounted() {},
  computed: {
    periodList: function periodList() {
      var arr = [];
      if (this.form && this.form.areaPeriod) {
        for (var i = 0; i < 6; i++) {
          var obj = {
            name: this.form.areaPeriod * (i + 1) + "week",
            value: this.form.areaPeriod * (i + 1)
          };
          arr.push(obj);
        }
      } else {
        arr = [];
      }
      return arr;
    }
  },
  methods: {
    open: function open(item) {
      this.visible = true;
      this.tmpId = item.tmpId;
      this.getTempDetail();
      // this.form = deepClone(item);
    },
    getTempDetail: function getTempDetail() {
      var _this = this;
      this.loading = true;
      subscribeApi.tmpDetail(this.tmpId).then(function (data) {
        _this.tmpInfo = data;
        _this.form = (0, _utils.deepClone)(data);
      }).finally(function () {
        _this.loading = false;
      });
    },
    // 提交
    handlerSubmit: function handlerSubmit(name) {
      var _this2 = this;
      this.$refs[name].validate(function (valid) {
        if (!valid) return;
        _this2.loading = true;
        var data = Object.assign({}, _this2.form);
        var newArray = ["nextOrderDate", "orderInfoList", "period", "tmpId"];
        var params = {};
        for (var key in data) {
          if (newArray.indexOf(key) > -1) {
            params[key] = data[key];
          }
        }
        params.tmpId = _this2.tmpId;
        subscribeApi.tmpUpdate(params).then(function (res) {
          _this2.$message.success("Update success");
          _this2.visible = false;
          _this2.$refs[name].resetFields();
          _this2.$emit("ok");
          _this2.loading = false;
        }).catch(function () {
          _this2.loading = false;
        });
      });
    },
    handleClose: function handleClose() {
      this.handleCancel("form");
    },
    handleCancel: function handleCancel(name) {
      this.visible = false;
      this.$refs[name].resetFields();
    }
  }
};
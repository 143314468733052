var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        visible: _vm.dialogVisible,
        title: _vm.title,
        "destroy-on-close": "",
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "end",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submission",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("submit")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("cancel"),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
            },
          ],
          ref: "formValidate",
          staticClass: "formValidate mt20",
          attrs: {
            rules: _vm.ruleValidate,
            model: _vm.formValidate,
            "label-position": "top",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "product name", prop: "storeName" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "249",
                  placeholder: "Please enter",
                  disabled: _vm.isDisabled,
                },
                model: {
                  value: _vm.formValidate.storeName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formValidate, "storeName", $$v)
                  },
                  expression: "formValidate.storeName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "price：", prop: "price" } },
            [
              _c("el-input-number", {
                attrs: {
                  min: 0,
                  placeholder: "price",
                  disabled: _vm.isDisabled,
                },
                model: {
                  value: _vm.formValidate.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.formValidate, "price", $$v)
                  },
                  expression: "formValidate.price",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "unit", prop: "unitName" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "Please enter",
                  disabled: _vm.isDisabled,
                },
                model: {
                  value: _vm.formValidate.unitName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formValidate, "unitName", $$v)
                  },
                  expression: "formValidate.unitName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "weight(L)：" } },
            [
              _c("el-input-number", {
                attrs: {
                  min: 0,
                  placeholder: "weight(L)",
                  disabled: _vm.isDisabled,
                },
                model: {
                  value: _vm.formValidate.weight,
                  callback: function ($$v) {
                    _vm.$set(_vm.formValidate, "weight", $$v)
                  },
                  expression: "formValidate.weight",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "detail：", prop: "storeInfo" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "250",
                  rows: 3,
                  placeholder: "detail",
                  disabled: _vm.isDisabled,
                },
                model: {
                  value: _vm.formValidate.storeInfo,
                  callback: function ($$v) {
                    _vm.$set(_vm.formValidate, "storeInfo", $$v)
                  },
                  expression: "formValidate.storeInfo",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "product photo", prop: "image" } },
            [
              _c("div", { staticClass: "remark" }, [
                _c(
                  "div",
                  {
                    staticClass: "upLoadPicBox",
                    attrs: { disabled: _vm.isDisabled },
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("1", "image")
                      },
                    },
                  },
                  [
                    _vm.formValidate.image
                      ? _c("div", { staticClass: "pictrue" }, [
                          _c("img", { attrs: { src: _vm.formValidate.image } }),
                        ])
                      : _c("div", { staticClass: "upLoad" }, [
                          _c("i", {
                            staticClass: "el-icon-camera cameraIconfont",
                          }),
                        ]),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
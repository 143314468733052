var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              attrs: { inline: "", size: "small" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              !_vm.storeId
                ? _c(
                    "el-form-item",
                    { attrs: { label: "plant:" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "plant" },
                          on: { change: _vm.seachList },
                          model: {
                            value: _vm.listPram.storeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.listPram, "storeId", $$v)
                            },
                            expression: "listPram.storeId",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "status:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "status" },
                      on: { change: _vm.seachList },
                      model: {
                        value: _vm.listPram.disposeStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.listPram, "disposeStatus", $$v)
                        },
                        expression: "listPram.disposeStatus",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "pending", value: 0 },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "cancelled", value: 1 },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "processed", value: 2 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleList($event)
                        },
                      },
                    },
                    [_vm._v("search")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetQuery },
                    },
                    [_vm._v("reset")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.listData.list,
                border: "",
                size: "mini",
                "header-cell-style": {
                  fontWeight: "bold",
                  background: "#f8f8f9",
                  color: "#515a6e",
                  height: "40px",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "special request order ID",
                  prop: "orderNo",
                  "min-width": "220",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "plant name",
                  prop: "storeName",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "description",
                  prop: "description",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "special request type",
                  prop: "type",
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type == 0
                          ? _c("span", [_vm._v("Deliver less water-refund")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.type == 1
                          ? _c("span", [
                              _vm._v("Deliver less water-replacement order"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.type == 2
                          ? _c("span", [_vm._v("overtime")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Set up date and time",
                  prop: "createTime",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "status", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _c(
                        "el-tag",
                        {
                          attrs: {
                            effect: "dark",
                            size: "mini",
                            type:
                              scope.row.disposeStatus == 0
                                ? "danger"
                                : scope.row.disposeStatus == 1
                                ? "success"
                                : "warning",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                scope.row.disposeStatus == 0
                                  ? "pending"
                                  : scope.row.disposeStatus == 1
                                  ? "processed"
                                  : "cancelled"
                              ) +
                              "\n        "
                          ),
                        ]
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "operation",
                  "min-width": "200",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.disposeStatus == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOpenEdit(1, scope.row, 1)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.type == 0 || scope.row.type == 2
                                      ? "refund"
                                      : "replacement order"
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.disposeStatus == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOpenEdit(1, scope.row, 2)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.type == 0 || scope.row.type == 2
                                      ? "refuse refund"
                                      : "refuse replacement order"
                                  )
                                ),
                              ]
                            )
                          : _c("a", [_vm._v("--")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "notes for special request",
                  prop: "disposeRemark",
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.disposeRemark || "--") +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.listPram.page,
              "page-sizes": _vm.constants.page.limit,
              layout: _vm.constants.page.layout,
              total: _vm.listData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editDialogConfig.visible,
            title: _vm.title,
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialogConfig, "visible", $event)
            },
          },
        },
        [
          _vm.editDialogConfig.visible
            ? _c("edit", {
                ref: "editForm",
                attrs: {
                  "is-create": _vm.editDialogConfig.isCreate,
                  "edit-data": _vm.editDialogConfig.editData,
                },
                on: { hideEditDialog: _vm.hideEditDialog },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var driverApi = _interopRequireWildcard(require("@/api/driver.js"));
var _vuex = require("vuex");
var systemMerchantApi = _interopRequireWildcard(require("@/api/merchant.js"));
var _permission = require("@/utils/permission");
var _driverEdit = _interopRequireDefault(require("./components/driverEdit.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import edit from "./edit";
// 权限判断函数
var _default = exports.default = {
  components: {
    driverEdit: _driverEdit.default
  },
  data: function data() {
    return {
      constants: this.$constants,
      loading: false,
      listData: {
        list: []
      },
      storeData: [],
      userVisible: false,
      listPram: {
        page: 1,
        limit: this.$constants.page.limit[0],
        name: null,
        deviceNum: null,
        description: null,
        status: null,
        createTime: null,
        updateTime: null
      },
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        // 0=创建，1=编辑
        editData: {}
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(["storeId"])),
  mounted: function mounted() {
    this.handleList();
    this.handleGetAdminList();
    var channel = new BroadcastChannel("music");
    channel.addEventListener("message", function (e) {
      console.log(e);
    });
  },
  methods: {
    checkPermi: _permission.checkPermi,
    handleOpenDel: function handleOpenDel(rowData) {
      var _this = this;
      this.$confirm("Data prior to deletion").then(function () {
        driverApi.delDriver(rowData).then(function (data) {
          _this.$message.success("successful");
          _this.handleList();
        });
      });
    },
    handleAdd: function handleAdd() {
      this.$refs.modalForm.add();
    },
    handleEdit: function handleEdit(record) {
      this.$refs.modalForm.edit(record);
    },
    handleGetAdminList: function handleGetAdminList() {
      var _this2 = this;
      systemMerchantApi.merchantList(this.listPram).then(function (data) {
        _this2.storeData = data.list;
      });
    },
    seachList: function seachList() {
      this.listPram.page = 1;
      this.handleList();
    },
    close: function close() {
      this.userVisible = false;
      this.handleList();
    },
    handleBind: function handleBind(rowData) {
      var _this3 = this;
      this.userVisible = true;
      this.$nextTick(function () {
        _this3.$refs.userList.edit(rowData);
      });
    },
    handleList: function handleList() {
      var _this4 = this;
      this.loading = true;
      driverApi.driverList(_objectSpread(_objectSpread({}, this.listPram), {}, {
        isAllo: false
      })).then(function (data) {
        _this4.listData = data;
        _this4.loading = false;
      });
    },
    handleDetail: function handleDetail(row) {
      this.$router.push({
        path: "/driver/info",
        query: {
          id: row.uid
        }
      });
    },
    hideEditDialog: function hideEditDialog() {
      this.editDialogConfig.visible = false;
      this.handleList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handleList(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handleList(this.listPram);
    },
    resetQuery: function resetQuery() {
      this.listPram = {};
      this.handleList();
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _changeDeliverySingle = _interopRequireDefault(require("./components/changeDeliverySingle"));
var _orderDone = _interopRequireDefault(require("./components/orderDone"));
var _utils = require("@/utils");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    changeDeliverySingle: _changeDeliverySingle.default,
    orderDone: _orderDone.default
  },
  data: function data() {
    return {
      detail: {},
      loading: false,
      orderId: null,
      dialogVisible: false
    };
  },
  mounted: function mounted() {},
  methods: {
    handleArchives: function handleArchives(uid) {
      var targetPath = "/user/archives?uid=" + uid;
      var fullPath = this.$route.fullPath;
      if (targetPath == fullPath) {
        return;
      }
      this.dialogVisible = false;
      this.$router.push({
        path: "/user/archives",
        query: {
          uid: uid
        }
      });
    },
    getSrc: function getSrc(avatar) {
      var subStr = "http";
      if (avatar && avatar.startsWith(subStr)) {
        return avatar;
      } else {
        return "https://img.h2owaterco.com/" + avatar;
      }
    },
    show: function show(orderId) {
      this.dialogVisible = true;
      this.orderId = orderId;
      this.getOrderDetail();
    },
    getTotal: function getTotal(price, quantity) {
      return price * quantity.toFixed(2);
    },
    changeDelivery: function changeDelivery() {
      var detail = (0, _utils.deepClone)(this.detail);
      this.$refs["changeDeliveryForm"].open(detail);
    },
    completeOrder: function completeOrder() {
      var detail = (0, _utils.deepClone)(this.detail);
      this.$refs["orderDone"].open(detail);
    },
    handleOrderDone: function handleOrderDone() {
      this.getOrderDetail();
      this.$emit("update");
    },
    getOrderStatus: function getOrderStatus(t) {
      var obj = {
        0: "pending",
        1: "pending",
        2: "on route",
        3: "complete",
        4: "complete",
        8: "canceled",
        9: "exception"
      };
      return obj[t];
    },
    getRate: function getRate(rate) {
      return Number(rate);
    },
    getOrderDetail: function getOrderDetail() {
      var _this = this;
      this.loading = true;
      (0, _order.orderDetailApi)({
        orderNo: this.orderId
      }).then(function (res) {
        var imgList = res.image ? res.image.split(",") : [];
        var newImgList = imgList.map(function (item) {
          return _this.getSrc(item);
        });
        var driverImgList = res.driverImg ? res.driverImg.split(",") : [];
        var newDriverImgList = driverImgList.map(function (item) {
          return _this.getSrc(item);
        });
        _this.detail = Object.assign({}, res, {
          imgList: newImgList,
          driverImgList: newDriverImgList
        });
        _this.loading = false;
      }).catch(function (error) {
        _this.loading = false;
      });
    }
  }
};
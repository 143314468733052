var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        visible: _vm.visible,
        title: "Pay",
        "before-close": _vm.handleCancel,
        width: "650px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "order_box",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                labelPosition: "top",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Payment method", prop: "payType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.handleTypeChange },
                      model: {
                        value: _vm.form.payType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "payType", $$v)
                        },
                        expression: "form.payType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("Check payment"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("Cash payment"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: 2 } }, [
                        _vm._v("Acah payment"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: 3 } }, [
                        _vm._v("Balance payment"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Payment date", prop: "payTime" } },
                [
                  [
                    _c("el-date-picker", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "date",
                        "value-format": "yyyy-MM-dd hh:mm:ss",
                        format: "yyyy-MM-dd",
                        placeholder: "payment date",
                      },
                      model: {
                        value: _vm.form.payTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "payTime", $$v)
                        },
                        expression: "form.payTime",
                      },
                    }),
                  ],
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Payment amount", prop: "chequePrice" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      placeholder: "Payment amount",
                      readonly: _vm.form.payType == 3,
                    },
                    model: {
                      value: _vm.form.chequePrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "chequePrice", $$v)
                      },
                      expression: "form.chequePrice",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.payType !== 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Check number", prop: "chequeNo" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Check number" },
                        model: {
                          value: _vm.form.chequeNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "chequeNo", $$v)
                          },
                          expression: "form.chequeNo",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Remark", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2 },
                      placeholder: "Please enter",
                    },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.balancePrice
                ? _c("el-form-item", { attrs: { label: "" } }, [
                    _vm._v("\n        Balance："),
                    _c("span", { staticStyle: { color: "#1890ff" } }, [
                      _vm._v("$" + _vm._s(_vm.balancePrice)),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex-row-bet",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("div", { staticStyle: { color: "rgba(0,0,0,.6)" } }, [
            _c("span", { staticStyle: { color: "rgba(0,0,0,.9)" } }, [
              _vm._v("Total:"),
            ]),
            _c("span", { staticStyle: { color: "#1890ff" } }, [
              _vm._v("$" + _vm._s(_vm.totalPrice)),
            ]),
            _vm.isUseBill
              ? _c("span", [
                  _vm._v(",Balance deduction$"),
                  _vm.totalPrice < _vm.balancePrice
                    ? _c("span", [_vm._v(_vm._s(_vm.totalPrice))])
                    : _c("span", [_vm._v(_vm._s(_vm.balancePrice))]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleSubmit("form")
                },
              },
            },
            [_vm._v("Confirm")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var articleApi = _interopRequireWildcard(require("@/api/article.js"));
var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "list",
  props: {
    handle: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      templateRadio: '',
      imgList: [],
      constants: this.$constants,
      listPram: {
        keywords: null,
        cid: null,
        page: 1,
        limit: this.$constants.page.limit[0]
      },
      listData: {
        list: [],
        total: 0
      },
      editDialogConfig: {
        visible: false,
        data: {},
        isEdit: 0 // 0=add 1=edit
      },
      categoryTreeData: [],
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'child',
        expandTrigger: 'hover',
        checkStrictly: true,
        emitPath: false
      }
    };
  },
  mounted: function mounted() {
    this.handlerGetListData(this.listPram);
    this.handlerGetCategoryTreeData();
  },
  methods: {
    getTemplateRow: function getTemplateRow(row) {
      this.$emit('getArticle', row);
    },
    handerSearch: function handerSearch() {
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    handlerGetListData: function handlerGetListData(pram) {
      var _this = this;
      articleApi.ListArticle(pram).then(function (data) {
        _this.listData = data;
        // this.listData.list.map((item) => {
        //   item.imageInput.map(i => {
        //     this.imgList.push(i)
        //   })
        // })
      });
    },
    handlerGetCategoryTreeData: function handlerGetCategoryTreeData() {
      var _this2 = this;
      var _pram = {
        type: this.constants.categoryType[2].value,
        status: 1
      };
      categoryApi.treeCategroy(_pram).then(function (data) {
        _this2.categoryTreeData = selfUtil.addTreeListLabelForCasCard(data);
      });
    },
    handlerHideDialog: function handlerHideDialog() {
      this.handlerGetListData(this.listPram);
      this.editDialogConfig.visible = false;
    },
    handlerDelete: function handlerDelete(rowData) {
      var _this3 = this;
      this.$confirm('确定删除当前数据', '提示').then(function (result) {
        articleApi.DelArticle(rowData).then(function (data) {
          _this3.$message.success('删除数据成功');
          _this3.handlerGetListData(_this3.listPram);
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetListData(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetListData(this.listPram);
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
var _permission = require("@/utils/permission");
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: "shopList",
  components: {},
  props: {
    storeId: {
      type: [Number, String],
      required: true
    }
  },
  data: function data() {
    return {
      props: {
        children: "child",
        label: "name",
        value: "id",
        emitPath: false
      },
      deliveryVisible: false,
      headeNum: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      form: {
        productId: "",
        number: ""
      },
      loading: false,
      rules: {
        number: [{
          required: true,
          message: "Please enter the quantity of storage",
          trigger: ["blur", "change"]
        }],
        productId: [{
          required: true,
          message: "Please select the goods to be stored",
          trigger: ["blur", "change"]
        }]
      },
      tableFrom: {
        page: 1,
        limit: 10,
        cateId: "",
        keywords: ""
        // type: '1'
      },
      productId: "",
      categoryList: [],
      merCateList: [],
      shopList: [],
      objectUrl: process.env.VUE_APP_BASE_API,
      dialogVisible: false
    };
  },
  computed: {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      console.log(this.storeId);
      this.tableFrom.storeId = this.storeId;
      (0, _store.productLstApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        _this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this2 = this;
      (0, _store.storeAddproduct)({
        productId: row.productId,
        storeId: this.storeId,
        type: row.isShow ? 0 : 1
      }).then(function () {
        _this2.getList();
      }).catch(function () {
        row.isShow = !row.isShow;
      });
    }
  }
};